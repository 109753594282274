import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'

//Estilos generales
const fuenteTitulo = {
  name: "Arial",
  size: 10,
  bold: true
};

const fuenteDatos = {
  name:"Arial",
  size: 10
}

const estiloGris = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "CCC2C2C2" },
  bgColor: { argb: "CCC2C2C2" }
};

export async function descargaExcel(sheetName, nombreReporte, entidad, junta, datos, tipoModalidad, tipoListado, tipoParticipante, sistema) {
  //Creación del workbook
  const wb = new ExcelJS.Workbook()
  wb.creator = 'Sistema de Observadores Electorales';
  wb.lastModifiedBy = 'Sistema de Observadores Electorales';
  wb.created = new Date();
  //Creación de nueva hoja
  const sheet = wb.addWorksheet(sheetName);
  //Datos generales del reporte
  sheet.mergeCells("A1:Q1");
  sheet.getCell("A1").value = "Consulta Popular 2021";
  sheet.getCell("A1").font = fuenteTitulo;
  sheet.getCell("A1").alignment = { horizontal: "center"};
  sheet.mergeCells("A2:Q2");
  sheet.getCell("A2").value = nombreReporte;
  sheet.getCell("A2").font = fuenteTitulo;
  sheet.getCell("A2").alignment = { horizontal: "center"};
  var fila;

  sheet.unMergeCells("A1");
  sheet.mergeCells("A1:J1");
  sheet.getCell("A1").value = "Consulta Popular 2021";
  sheet.unMergeCells("A2");
  sheet.mergeCells("A2:J2");
  sheet.getCell("A2").value = nombreReporte;
  const rowEstadoDistrito = sheet.getRow(3);
  if (entidad !== null) {
    if(junta !== null) {
      rowEstadoDistrito.values = [
        "Estado:", entidad, "Distrito Federal:", junta
      ]
    } else {
      rowEstadoDistrito.values = [
        "Estado:", entidad, "Junta Local"
      ]
    } 
  }
  sheet.getCell("A3").font = fuenteTitulo;
  sheet.getCell("A3").fill = estiloGris;
  sheet.getCell("B3").font = fuenteDatos;
  sheet.getCell("C3").font = fuenteTitulo;
  sheet.getCell("C3").fill = estiloGris;
  sheet.getCell("D3").font = fuenteDatos;
  const rowFechaGeneracion = sheet.getRow(4);
  rowFechaGeneracion.values = [
    "Fecha y horario de generación:", `${sistema.fecha} ${sistema.hora}`
  ]
  sheet.getCell("A4").font = fuenteTitulo;
  sheet.getCell("A4").fill = estiloGris;
  sheet.getCell("B4").font = fuenteDatos;

  if (tipoModalidad === 0) {
    if (entidad === null) {
      // nacional-presencial
      const rowTituloDatos = sheet.getRow(5);
      rowTituloDatos.height = 42;
      rowTituloDatos.values = [
        "Entidad", "Cursos impartidos por JL", "Cursos impartidos por JD", "Cursos impartidos por OPL", "Cursos impartidos por Organización", 
        "Total de cursos a impartir", "Solicitudes inscritas en JL", "Solicitudes inscritas en JD", "Solicitudes inscritas en OPL", 
        "Solicitudes inscritas en Organización", "Total de solicitudes inscritas", "Solicitudes capacitadas en JL",
        "Solicitudes capacitadas en JD", "Solicitudes capacitadas en OPL", "Solicitudes capacitadas en Organización",
        "Total de solicitudes capacitadas"
      ];
      rowTituloDatos.font = fuenteTitulo;
      rowTituloDatos.eachCell(function(cell) {
        cell.fill = estiloGris;
        cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
      });
      //Datos del reporte
      fila = 6;
      datos.forEach((e) => {
        const rowDatos = sheet.getRow(fila);
        rowDatos.font = fuenteDatos;
        rowDatos.values = [
          e.entidad, Number(e.impartidoLocal), Number(e.impartidoJunta), Number(e.impartidoOPL), Number(e.impartidoORG),
            Number(e.impartidoTotal), Number(e.inscritoLocal), Number(e.inscritoJunta), Number(e.inscritoOPL), Number(e.inscritoORG),
          e.inscritoTotal, Number(e.capacitadoLocal), Number(e.capacitadoJunta), Number(e.capacitadoOPL), 
            Number(e.capacitadoORG), Number(e.capacitadoTotal)
        ];
        fila++;
      });
      sheet.getColumn(1).width = 35;
      sheet.getColumn(2).width = 15;
      sheet.getColumn(3).width = 15;
      sheet.getColumn(4).width = 15;
      sheet.getColumn(5).width = 15;
      sheet.getColumn(6).width = 15;
      sheet.getColumn(7).width = 15;
      sheet.getColumn(8).width = 15;
      sheet.getColumn(9).width = 15;
      sheet.getColumn(10).width = 15;
      sheet.getColumn(11).width = 15;
      sheet.getColumn(12).width = 15;
      sheet.getColumn(13).width = 15;
      sheet.getColumn(14).width = 15;
      sheet.getColumn(15).width = 15;
      sheet.getColumn(16).width = 15;
    } else {
      if (junta === null) {
        if (tipoListado === 0) {
          // listado de cursos presenciales a nivel entidad
          const rowTituloDatos = sheet.getRow(5);
          rowTituloDatos.height = 42;
          rowTituloDatos.values = [
            "Distrito", "Cursos impartidos por la Junta", "Cursos impartidos por OPL", "Cursos impartidos por Organización", 
            "Total de cursos a impartir", "Solicitudes inscritas en Junta", "Solicitudes inscritas en OPL", 
            "Solicitudes inscritas en Organización", "Total de solicitudes inscritas", "Solicitudes capacitadas en Junta",
            "Solicitudes capacitadas en OPL", "Solicitudes capacitadas en Organización", "Total de solicitudes capacitadas"
          ];
          rowTituloDatos.font = fuenteTitulo;
          rowTituloDatos.eachCell(function(cell) {
            cell.fill = estiloGris;
            cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
          });
          //Datos del reporte
          fila = 6;
          datos.forEach((e) => {
            const rowDatos = sheet.getRow(fila);
            rowDatos.font = fuenteDatos;
            rowDatos.values = [
              e.distrito, Number(e.impartidoJunta), Number(e.impartidoOPL), Number(e.impartidoORG), Number(e.impartidoTotal), 
              Number(e.inscritoJunta), Number(e.inscritoOPL), Number(e.inscritoORG), e.inscritoTotal, Number(e.capacitadoJunta), 
              Number(e.capacitadoOPL), Number(e.capacitadoORG), Number(e.capacitadoTotal)
            ];
            fila++;
          });
          sheet.getColumn(1).width = 35;
          sheet.getColumn(2).width = 15;
          sheet.getColumn(3).width = 15;
          sheet.getColumn(4).width = 15;
          sheet.getColumn(5).width = 15;
          sheet.getColumn(6).width = 15;
          sheet.getColumn(7).width = 15;
          sheet.getColumn(8).width = 15;
          sheet.getColumn(9).width = 15;
          sheet.getColumn(10).width = 15;
          sheet.getColumn(11).width = 15;
          sheet.getColumn(12).width = 15;
          sheet.getColumn(13).width = 15;
        } else {
          if (tipoParticipante === 0) {
            // curso presencial - entidad - participantes inscritos
            const rowTituloDatos = sheet.getRow(5);
            rowTituloDatos.height = 42;
            rowTituloDatos.values = [
              "Nombre", "Curso impartido por", "Fecha de impartición", "Hora de inicio", "Hora de término", 
              "Persona que imparte el curso", "Cargo de la persona que imparte el curso", "Sede del curso",
              "Fecha en que completó el curso", "Entidades a observar"
            ];
            rowTituloDatos.font = fuenteTitulo;
            rowTituloDatos.eachCell(function(cell) {
              cell.fill = estiloGris;
              cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
            });
            //Datos del reporte
            fila = 6;
            datos.forEach((e) => {
              const rowDatos = sheet.getRow(fila);
              rowDatos.font = fuenteDatos;
              rowDatos.values = [
                e.nombreObservador, e.imparteInstitucion, e.fechaImparte, e.horaInicio, e.horaTermino, e.impartePersona,
                e.cargo, e.sede, e.fechaAsistencia, e.entidadesObservar
              ];
              fila++;
            });
            sheet.getColumn(1).width = 40;
            sheet.getColumn(2).width = 15;
            sheet.getColumn(3).width = 15;
            sheet.getColumn(4).width = 15;
            sheet.getColumn(5).width = 15;
            sheet.getColumn(6).width = 40;
            sheet.getColumn(7).width = 25;
            sheet.getColumn(8).width = 20;
            sheet.getColumn(9).width = 15;
            sheet.getColumn(10).width = 45;
          } else {
            // curso presencial - entidad - participantes NO inscritos
            const rowTituloDatos = sheet.getRow(5);
            rowTituloDatos.height = 42;
            rowTituloDatos.values = [
              "Nombre", "Estado de la revisión", "Correo electrónico", "Teléfono"
            ];
            rowTituloDatos.font = fuenteTitulo;
            rowTituloDatos.eachCell(function(cell) {
              cell.fill = estiloGris;
              cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
            });
            //Datos del reporte
            fila = 6;
            datos.forEach((e) => {
              const rowDatos = sheet.getRow(fila);
              rowDatos.font = fuenteDatos;
              rowDatos.values = [
                e.nombreObservador, e.estatus, e.correo, e.telefono
              ];
              fila++;
            });
            sheet.getColumn(1).width = 50;
            sheet.getColumn(2).width = 15;
            sheet.getColumn(3).width = 30;
            sheet.getColumn(4).width = 15;
          }
        }
      } else {
        if (tipoModalidad === 0) {
          if (tipoListado === 0) {
            // listado de cursos presenciales a nivel distrito
            const rowTituloDatos = sheet.getRow(5);
            rowTituloDatos.height = 42;
            rowTituloDatos.values = [
              "Fecha de impartición", "Hora de inicio", "Hora de término", "Curso impartido por", "Persona que imparte el curso",
              "Cargo de la persona que imparte el curso", "Sede del curso", "Mujeres inscritas", "Hombres inscritos", 
              "Total inscritos", "Mujeres asistentes", "Hombres asistentes", "Total asistentes"
            ];
            rowTituloDatos.font = fuenteTitulo;
            rowTituloDatos.eachCell(function(cell) {
              cell.fill = estiloGris;
              cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
            });
            //Datos del reporte
            fila = 6;
            datos.forEach((e) => {
              const rowDatos = sheet.getRow(fila);
              rowDatos.font = fuenteDatos;
              rowDatos.values = [
                e.fechaImparte, e.horaInicio, e.horaTermino, e.imparteInstitucion, e.impartePersona, e.cargo, e.sede, 
                Number(e.inscritoMujeres), Number(e.inscritoHombres), e.inscritoTotal, Number(e.capacitadoMujeres),
                Number(e.capacitadoHombres), Number(e.capacitadoTotal)
              ];
              fila++;
            });
            sheet.getColumn(1).width = 35;
            sheet.getColumn(2).width = 20;
            sheet.getColumn(3).width = 20;
            sheet.getColumn(4).width = 15;
            sheet.getColumn(5).width = 40;
            sheet.getColumn(6).width = 40;
            sheet.getColumn(7).width = 20;
            sheet.getColumn(8).width = 15;
            sheet.getColumn(9).width = 15;
            sheet.getColumn(10).width = 15;
            sheet.getColumn(11).width = 15;
            sheet.getColumn(12).width = 15;
            sheet.getColumn(13).width = 15;
          } else {
            if (tipoParticipante === 0) {
              // curso presencial - distrito - participantes inscritos
              const rowTituloDatos = sheet.getRow(5);
              rowTituloDatos.height = 42;
              rowTituloDatos.values = [
                "Nombre", "Curso impartido por", "Fecha de impartición", "Hora de inicio", "Hora de término", 
                "Persona que imparte el curso", "Cargo de la persona que imparte el curso", "Sede del curso",
                "Fecha en que completó el curso", "Entidades a observar"
              ];
              rowTituloDatos.font = fuenteTitulo;
              rowTituloDatos.eachCell(function(cell) {
                cell.fill = estiloGris;
                cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
              });
              //Datos del reporte
              fila = 6;
              datos.forEach((e) => {
                const rowDatos = sheet.getRow(fila);
                rowDatos.font = fuenteDatos;
                rowDatos.values = [
                  e.nombreObservador, e.imparteInstitucion, e.fechaImparte, e.horaInicio, e.horaTermino, e.impartePersona,
                  e.cargo, e.sede, e.fechaAsistencia, e.entidadesObservar
                ];
                fila++;
              });
              sheet.getColumn(1).width = 40;
              sheet.getColumn(2).width = 15;
              sheet.getColumn(3).width = 15;
              sheet.getColumn(4).width = 15;
              sheet.getColumn(5).width = 15;
              sheet.getColumn(6).width = 40;
              sheet.getColumn(7).width = 25;
              sheet.getColumn(8).width = 20;
              sheet.getColumn(9).width = 15;
              sheet.getColumn(10).width = 45;
            } else {
              // curso presencial - distrito - participantes NO inscritos
              const rowTituloDatos = sheet.getRow(5);
              rowTituloDatos.height = 42;
              rowTituloDatos.values = [
                "Nombre", "Estado de la revisión", "Correo electrónico", "Teléfono"
              ];
              rowTituloDatos.font = fuenteTitulo;
              rowTituloDatos.eachCell(function(cell) {
                cell.fill = estiloGris;
                cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
              });
              //Datos del reporte
              fila = 6;
              datos.forEach((e) => {
                const rowDatos = sheet.getRow(fila);
                rowDatos.font = fuenteDatos;
                rowDatos.values = [
                  e.nombreObservador, e.estatus, e.correo, e.telefono
                ];
                fila++;
              });
              sheet.getColumn(1).width = 50;
              sheet.getColumn(2).width = 15;
              sheet.getColumn(3).width = 30;
              sheet.getColumn(4).width = 15;
            }
          }
        }
      }
    }
  } else {
    if (entidad === null) {
      // nacional-en-linea
      const rowTituloDatos = sheet.getRow(5);
      rowTituloDatos.height = 42;
      rowTituloDatos.values = [
        "Entidad", "Mujeres inscritas", "Hombres inscritos", "Total inscritos", "Mujeres capacitadas", 
        "Hombres capacitados", "Total capacitados"
      ];
      rowTituloDatos.font = fuenteTitulo;
      rowTituloDatos.eachCell(function(cell) {
        cell.fill = estiloGris;
        cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
      });
      //Datos del reporte
      fila = 6;
      datos.forEach((e) => {
        const rowDatos = sheet.getRow(fila);
        rowDatos.font = fuenteDatos;
        rowDatos.values = [
          e.entidad, Number(e.inscritoMujeres), Number(e.inscritoHombres), Number(e.inscritoTotal), Number(e.capacitaMujeres),
            Number(e.capacitaHombres), Number(e.capacitaTotal)
        ];
        fila++;
      });
      sheet.getColumn(1).width = 35;
      sheet.getColumn(2).width = 15;
      sheet.getColumn(3).width = 15;
      sheet.getColumn(4).width = 15;
      sheet.getColumn(5).width = 15;
      sheet.getColumn(6).width = 15;
      sheet.getColumn(7).width = 15;
    } else {
      if (junta === null) {
        // entidad-en-linea
        const rowTituloDatos = sheet.getRow(5);
        rowTituloDatos.height = 42;
        rowTituloDatos.values = [
          "Distrito", "Mujeres inscritas", "Hombres inscritos", "Total inscritos", "Mujeres capacitadas", 
          "Hombres capacitados", "Total capacitados"
        ];
        rowTituloDatos.font = fuenteTitulo;
        rowTituloDatos.eachCell(function(cell) {
          cell.fill = estiloGris;
          cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
        });
        //Datos del reporte
        fila = 6;
        datos.forEach((e) => {
          const rowDatos = sheet.getRow(fila);
          rowDatos.font = fuenteDatos;
          rowDatos.values = [
            e.distrito, Number(e.inscritoMujeres), Number(e.inscritoHombres), Number(e.inscritoTotal), Number(e.capacitaMujeres),
              Number(e.capacitaHombres), Number(e.capacitaTotal)
          ];
          fila++;
        });
        sheet.getColumn(1).width = 35;
        sheet.getColumn(2).width = 15;
        sheet.getColumn(3).width = 15;
        sheet.getColumn(4).width = 15;
        sheet.getColumn(5).width = 15;
        sheet.getColumn(6).width = 15;
        sheet.getColumn(7).width = 15;
      } else {
        // distrito - en línea
        const rowTituloDatos = sheet.getRow(5);
        rowTituloDatos.height = 42;
        rowTituloDatos.values = [
          "Nombre", "Fecha de validación de la solicitud", "Avance del curso en línea", "Última lección que completó", 
          "Fecha en que completó la última lección", "Entidad del último curso tomado", "Fecha en que completó el curso",
          "Entidades a observar"
        ];
        rowTituloDatos.font = fuenteTitulo;
        rowTituloDatos.eachCell(function(cell) {
          cell.fill = estiloGris;
          cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
        });
        //Datos del reporte
        fila = 6;
        datos.forEach((e) => {
          const rowDatos = sheet.getRow(fila);
          rowDatos.font = fuenteDatos;
          rowDatos.values = [
            e.nombreObservador, e.fechaValidacion, e.avance, e.ultimaLeccion, e.fechaLeccion, e.ultimaEntidad, 
            e.fechaTermino, e.entidadesObservar
          ];
          fila++;
        });
        sheet.getColumn(1).width = 40;
        sheet.getColumn(2).width = 15;
        sheet.getColumn(3).width = 20;
        sheet.getColumn(4).width = 50;
        sheet.getColumn(5).width = 15;
        sheet.getColumn(6).width = 20;
        sheet.getColumn(7).width = 15;
        sheet.getColumn(8).width = 30;
      }
    }
  }

  //Creación del excel
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), nombreReporte + ".xlsx");
}