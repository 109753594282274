//import { basePath, apiVersion } from "./config";
import {
  ACCESS_TOKEN,
  ACCESS_TOKEN_CI,
  DETALLE_PROCESO_CI,
  ID_CUESTIONARIOS,
} from "../utils/constanst";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { basePath } from "../api/config";
//Obtiene el token que se almacena en el local storage
export function getAccessTokenApi() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN); //Se verifica que el token exista en el localstorage

  if (!accessToken || accessToken === "null") {
    return null;
  }

  return willExpireToken(accessToken) ? null : accessToken;
}
//Función que vuelve a solicitar el token
export function refreshAccessTokenApi(refreshToken, setUser, history, user) {
  const url = basePath + "/refrescaToken";
  axios
    .post(
      url,
      {
        nombre: user.sub,
        idEstado: user.idEstado,
        idDistrito: user.idDistrito,
        idBitacora: user.idBitacora,
        tiempoToken: user.tiempoToken,
        rol: user.rol,
        puesto: user.puesto,
        mail: user.mail,
        area : user.area,
        idSesion: user.idSesion
      },
      {
        headers: { Authorization: refreshToken },
      }
    )
    .then((res) => {
      if (res.data.status !== 200 || !res.data) {
        logout(setUser, history, user);
      } else {
        const { token } = res.data.entity;
        localStorage.setItem(ACCESS_TOKEN, token);
      }
    })
    .catch((error) => {
      console.log("Ocurrio un error al refrescar el token", error);
      logout(setUser, history, user);
    });
}

//Elimina el token del navegador
export function logout(setUser, history, user) {
  const url = `${basePath}/cierraSesion`;
  const jwt = getAccessTokenApi();
  if (jwt) {
    axios
      .post(
        url,
        {
        usuario: user.sub,
        idBitacora: user.idBitacora
        },
        {
          headers: { Authorization: jwt },
        }
      )
      .finally(() => {
        removeItem();
        setUser(() => {
          return {
            user: null,
            isLoading: false,
          };
        });
        history.push("/admin");
      })
      .catch((error) => {
        console.log("Error....");
        console.log(error);
      });
  } else {
    removeItem();
    setUser(() => {
      return {
        user: null,
        isLoading: false,
      };
    });
    history.push("/admin");
  }
}
//Verifica que no haya expirado el token
function willExpireToken(token) {
  const seconds = 60;
  const metaToken = jwtDecode(token); //decodifica el token
  const { exp } = metaToken; //Se obtiene el tiempo de vida del token
  const now = (Date.now() + seconds) / 1000;
  return now > exp; //verifica que no hay expirado
}

export function removeItem() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem("ESTADO");
  localStorage.removeItem("DISTRITO");
  localStorage.removeItem("ID_DISTRITO");
  localStorage.removeItem("VERSION_USUARIO");
  localStorage.removeItem("INFO_MENU");
  localStorage.removeItem("ID_SISTEMA");
  localStorage.removeItem("ID_ESTADO");
  localStorage.removeItem("ID_MUNICIPIO");
  localStorage.removeItem("AMBITO");
  localStorage.removeItem("ROL_USUARIO");
  localStorage.removeItem("menu");
  localStorage.removeItem("NOMBRE");
}

//////////////////////////////////////////////////////// Ciudadano //////////////////////////////////////////////
export function getTokenCiudadano() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_CI); //Se verifica que el token exista en el localstorage
  if (!accessToken || accessToken === "null") {
    return null;
  }
  return willExpireToken(accessToken) ? null : accessToken;
}
//Función que cierra la sesión del ciudadano
export function logoutCiudadano() {
  const url = `${basePath}/cierraCiudadano`;
  const jwt = getTokenCiudadano();
  if (jwt) {
    const token = jwtDecode(jwt);
    axios
      .post(url, {
        correo: token.sub,
        idBitacora: token.idBitacora,
      })
      .then((res) => {
        cleanCitezen();
        window.location.href = "/"; //Se redirecciona al home
      });
  } else {
    cleanCitezen();
    return true;
  }
}

//Función que vuelve a solicitar el token
export function refreshAccessTokenCi(refreshToken) {
  const url = basePath + "/refrescaToken";
  const token = jwtDecode(refreshToken);
  axios
    .post(url, {
      correo: token.sub,
      idBitacora: token.idBitacora,
      tiempoToken: token.tiempoToken,
      idSesion: token.idSesion,
    })
    .then((res) => {
      if (res.data.status !== 200 || !res.data) {
        cleanCitezen();
        window.location.href = "/"; //Se redirecciona al home
      } else {
        const { token } = res.data.entity;
        localStorage.setItem(ACCESS_TOKEN_CI, token);
      }
    })
    .catch((error) => {
      cleanCitezen();
      window.location.href = "/"; //Se redirecciona al home
    });
}

export function cleanCitezen() {
  localStorage.removeItem(ACCESS_TOKEN_CI);
  localStorage.removeItem(DETALLE_PROCESO_CI);
  localStorage.removeItem(ID_CUESTIONARIOS);
}
