import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'
import moment from "moment";

//Estilos generales
const fuenteTitulo = {
  name: "Arial",
  size: 10,
  bold: true
};

const fuenteDatos = {
  name:"Arial",
  size: 10
}

const estiloGris = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "CCC2C2C2" },
  bgColor: { argb: "CCC2C2C2" }
};

export async function descargaExcelEstadoSolicitudes(sheetName, nombreReporte, datos) {
  //Creación del workbook
  const wb = new ExcelJS.Workbook()
  wb.creator = 'Sistema de Observadores Electorales';
  wb.lastModifiedBy = 'Sistema de Observadores Electorales';
  wb.created = new Date();
  //Creación de nueva hoja
  const sheet = wb.addWorksheet(sheetName);
  //Datos generales del reporte
  sheet.mergeCells("A1:U1");
  sheet.getCell("A1").value = "Consulta Popular 2021";
  sheet.getCell("A1").font = fuenteTitulo;
  sheet.getCell("A1").alignment = { horizontal: "center"};
  sheet.mergeCells("A2:U2");
  sheet.getCell("A2").value = nombreReporte;
  sheet.getCell("A2").font = fuenteTitulo;
  sheet.getCell("A2").alignment = { horizontal: "center"};
  var fila;
  const rowEstadoDistrito = sheet.getRow(3);
  if(datos[0].idDistrito !== 0) {
    rowEstadoDistrito.values = [
      "Estado:", datos[0].nombreEstado, "Distrito Federal:", datos[0].idDistrito+". "+datos[0].nombreDistrito
    ]
  } else {
    rowEstadoDistrito.values = [
      "Estado:", datos[0].nombreEstado, "Junta Local"
    ]
  }
  sheet.getCell("A3").font = fuenteTitulo;
  sheet.getCell("A3").fill = estiloGris;
  sheet.getCell("B3").font = fuenteDatos;
  sheet.getCell("C3").font = fuenteTitulo;
  sheet.getCell("C3").fill = estiloGris;
  sheet.getCell("D3").font = fuenteDatos;
  const rowFechaGeneracion = sheet.getRow(4);
  rowFechaGeneracion.values = [
    "Fecha y horario de generación:", moment(new Date()).format("DD/MM/YYYY HH:mm:ss")//Formato a la fecha
  ]
  sheet.getCell("A4").font = fuenteTitulo;
  sheet.getCell("A4").fill = estiloGris;
  sheet.getCell("B4").font = fuenteDatos;
  //Titulo de los datos del reporte
  const rowTituloDatos = sheet.getRow(5);
  rowTituloDatos.height = 42;
  rowTituloDatos.values = [
    "Nombre del solicitante", "Clave de Elector", "Folio", "Fecha de nacimiento", "Edad",
    "Sexo", "Nivel de estudios", "Estado general de la solicitud", "Tipo de solicitud",
    "Procedencia de la solicitud", "Fecha de recepción de la solicitud",
    "Fecha y hora del último cambio", "Origen de la solicitud", "Estado de la revisión",
    "Estado del curso", "Modalidad del curso", "Fecha en que completó el curso", 
    "Instancia que imparte el curso", "Validación contra otros sistemas", 
    "Motivo de cancelación o denegación / Justificación de cambio", "Fecha de acreditación"
  ];
  rowTituloDatos.font = fuenteTitulo;
  rowTituloDatos.eachCell(function(cell) {
    cell.fill = estiloGris;
    cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
  });
  //Datos del reporte
  fila = 6;
  datos.forEach((e) => {
    let observaciones = "";
    for (let i = 0; i < e.estadoCruce.cruce.length; i++) { //Validación de cruces
      observaciones = observaciones.concat(e.estadoCruce.cruce[i].observacion, ", ");
    }
    observaciones = observaciones.substring(0, observaciones.length-2);
    const rowDatos = sheet.getRow(fila);
    rowDatos.font = fuenteDatos;
    rowDatos.values = [
      e.nombreCompleto, e.claveElector, e.folio, moment(e.fechaNac).format("DD/MM/YYYY"),
      e.edad, e.sexo, e.escolaridad, e.descripcion, e.tipoSolicitud, e.tipoObs,
      moment(e.fechaSolicitud).format("DD/MM/YYYY HH:mm"),
      moment(e.fechaHora).format("DD/MM/YYYY HH:mm"), e.origenSolicitud, e.estadoRevision,
      e.estadoCurso, e.modalidadCurso,
      moment(e.fechaCompCurso).isValid() ? moment(e.fechaCompCurso).format("DD/MM/YYYY") : "-",
      e.institucion, observaciones, e.motivo,
      moment(e.fechaSesionAcreditacion).isValid() ? moment(e.fechaSesionAcreditacion).format("DD/MM/YYYY") : "-",
    ];
    fila++;
  });
  sheet.getColumn(1).width = 30; //Nombre
  sheet.getColumn(2).width = 25; //Clave de elector
  sheet.getColumn(3).width = 13; //Folio
  sheet.getColumn(4).width = 13; //Fecha de nacimiento
  sheet.getColumn(5).width = 6; //Edad
  sheet.getColumn(6).width = 7; //Sexo
  sheet.getColumn(7).width = 15; //Nivel de estudios
  sheet.getColumn(8).width = 22; //Estado general de la solicitud
  sheet.getColumn(9).width = 12; //Tipo de solicitud
  sheet.getColumn(10).width = 15; //Procedencia de la solicitud
  sheet.getColumn(11).width = 20; //Fecha de recepción de la solicitud
  sheet.getColumn(12).width = 20; //Fecha y hora del último cambio
  sheet.getColumn(13).width = 15; //Origen de la solicitud
  sheet.getColumn(14).width = 15; //Estado de la revisión
  sheet.getColumn(15).width = 13; //Estado del curso
  sheet.getColumn(16).width = 15; //Modalidad del curso
  sheet.getColumn(17).width = 16; //Fecha en que completó el curso
  sheet.getColumn(18).width = 20; //Instancia que imparte el curso
  sheet.getColumn(19).width = 50; //Validación contra otros sistemas
  sheet.getColumn(20).width = 50; //Motivo de cancelación o denegación / Justificación de cambio
  sheet.getColumn(21).width = 14; //Fecha de acreditación
  //Creación del excel
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), nombreReporte + ".xlsx");
}
