import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { message, Icon, Select, Radio, Form, Row, Col, Input, Alert, DatePicker, Upload, Button, Checkbox } from "antd";
import { validaApellidos, generaFolio, getBase64, uploadButton, beforeUpload, calulateNewHeight, confirmaObservadorFunction } from "../../../utils/RegisterAdmin/functions";
import { controlRegister, Ast } from "../../../utils/RegisterAdmin/functions";
import { apellidos, nombres, ocupacion, claveElector, seccion, calle, cp, correo, tel, telExt, required, valueUpperCase, txtTexto, soloDigitos, numeroInt } from "../../../utils/RegisterAdmin/rules";
import { getPaises, getOrganizaciones, getJuntas, getEntidades, getMunicipios, getEscolaridades, getByIdRegister, getEntidadesObservar, getFuncionarios, getFechaLimite, getIdObsProcesoAnterior } from "../../../api/registerAdmin";
import { getEdad, getFechaNacimiento, getSexo, age, esJuntaLocal } from "../../Generals/functions";
import { URL_IMAGEN } from "../../../api/config";
import useAuth from "../../../hooks/useAuth";
import moment from 'moment';
import Compressor from "compressorjs";
import "./Form.scss";
import { rol_captura, rol_modifica, rol_consulta, rol_cau } from "../../../utils/RegisterAdmin/roles";
import { ID_DOC_CRED_FRENTE, ID_DOC_CRED_VUELTA, ID_DOC_FOTOGRAFIA, ESTATUS_VALIDADO, OBSERVADOR_EXTRANJERO, FUNCIONARIO_CONSEJERO, placeholderDate, formatDate , MSN_GUARDAR, ORIGEN_JUNTA} from "../../../utils/constanst";

const { Option } = Select;

function FormRegister(props) {
  const { TextArea } = Input;
  const [select, setSelect] = useState([]);
  const [modulo, setModulo] = useState([]);
  const [original, setOriginal] = useState([]);
  const [selectPais, setSelectPais] = useState([]);
  const [selectEdo, setSelectEdo] = useState([]);
  const [selectMun, setSelectMun] = useState([]);
  const [selectEsc, setSelectEsc] = useState([]);
  const [entidades, setEntidades] = useState([]);                             //lista completa con las entidades seleccionadas o no en caso de ser registro
  const [entidadesSeleccionadas, setEntidadesSeleccionadas] = useState([]);   //en caso de modifica o consulta
  const [variasEntidades, setVariasEntidades] = useState(false);              //si fue seleccinada la modalidad de varias entidades
  const { history, proceso, setLoader } = props;
  const { data } = props.location;
  const dateFormat = 'DD/MM/YYYY';
  const [showOrganizaciones, setShowOrganizaciones] = useState(false);
  const [elementosClave, setElementosClave] = useState(false);         // se habilita el cambio de sexo, edad y fecha de nacimiento
  const [imageFront, setImageFrontUrl] = useState({ loading: false }); //Hook para visualizar la imagen frente
  const [imageRound, setImageRoundUrl] = useState({ loading: false }); //Hook para visualizar la imagen vuelta
  const [imagePhoto, setImagePhotoUrl] = useState({ loading: false }); //Hook para visualizar la imagen foto
  const [register, setRegister] = useState({}); //Para Limpiar el input de la imagen
  const radioStyle = { display: "block", height: "30px", lineHeight: "30px" };
  const [disabled, setDisabled] = useState({ input: false, titulo: 1, buttonUpd: false, subTitle: false, checkNum: false, checkMail: false }); //Para habilidar los inputs
  const proObs = [];
  const [datos, setDatos] = useState({});
  const { user: { user, rolUsuario, infoMenu } } = useAuth();
  const { getFieldDecorator } = props.form; //Método para validar los inputs
  const [button, setButton] = useState(false);
  const [buttonConsulta, setButtonConsulta] = useState(false);
  const [verOrigenSolicitud, setVerOrigenSolicitud] = useState(true);
  const [esExtranjero, setEsExtranjero] = useState(false);
  const [direccionJunta, setDireccionJunta] = useState();
  const [municipioJunta, setMunicipioJunta] = useState();
  const [entidadPantalla, setEntidadPantalla] = useState();
  const [fechaLimite, setFechaLimite] = useState();
  const [datosCP, setDatosCP] = useState({});
  const [ratifica, setRatifica] = useState(false);

  useEffect(() => {
  if (proceso.distrito !== undefined && proceso.distrito !== null) {
    // bloquear el botón de Editar en caso de no contar con los roles requeridos
    if (rol_modifica.includes(rolUsuario)) {
      setButtonConsulta(false);
    } else {
      setButtonConsulta(true);
    } 
    if (esJuntaLocal(rolUsuario, proceso.distrito.idDistrito)) {
      setButtonConsulta(true);
    }
    // consultar la fecha límite del registro
    getFechaLimite().then((res) => {
      if (res.data !== undefined && res.data !== null) {
        setFechaLimite(res.data);
      } else {
        setFechaLimite(moment().format(dateFormat));
      }      
    });
    if (rol_cau.includes(rolUsuario)) {
      setButton(true);
    }
    switch (
      history.location.pathname //Valida los permisos que tienen el usuario
    ) {
      case "/admin/solicitudes/registro":
        if (!rol_captura.includes(rolUsuario)) {
          history.push("/admin/solicitudes");
        } else {
          setModulo(1);
        }
        break;
      case "/admin/solicitudes/modifica":
        if (!rol_modifica.includes(rolUsuario)) {
          history.push("/admin/solicitudes");
        } else {
          if (data !== undefined) {
            setDisabled({...disabled, input: false, titulo: 3 });
            setModulo(3);
          } else {
            history.push("/admin/solicitudes");
          }
        }
        break;
      case "/admin/solicitudes/consulta":
        if (!rol_consulta.includes(rolUsuario)) {
          history.push("/admin/solicitudes");
        } else {
          if (data !== undefined) {
            setDisabled({ ...disabled, input: true, checkNum: true, checkMail: true, titulo: 2, });
            setModulo(2);
          } else {
            history.push("/admin/solicitudes");
          }
        }
        break;
      default:
        break;
    }
    getRegister();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceso]);

  //Función que verifica si es una solicitud nueva o solo se modificara
  function getRegister() {
    getOrganizaciones(proceso, infoMenu.listaDetalles).then((res) => {
      setSelect(res.data);
    });
    getEscolaridades().then((res) => {
      setSelectEsc(res.data);
    });
    getJuntas(proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
      if (res.data.domicilios !== undefined && res.data.domicilios.length > 0) {
        let direccion = `${res.data.domicilios[0].nombreSede} ${res.data.domicilios[0].idDistritoFederal} - ${res.data.domicilios[0].direccion}`;
        setDireccionJunta(direccion);
        setMunicipioJunta(res.data.domicilios[0].nombreMunicipio);
      }
    }); 
    // getPresidenteConsejero aquí traer los datos del presidente!!
    getFuncionarios(proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
      if (res.data.integrantes !== undefined) {
        for (let i = 0; i < res.data.integrantes.length; i++) {
          if (res.data.integrantes[i].idPuesto === FUNCIONARIO_CONSEJERO) {
            let nombre = res.data.integrantes[i].nombreIntegrante.trim();
            let aPaterno = res.data.integrantes[i].primerApellidoIntegrante ? res.data.integrantes[i].primerApellidoIntegrante.trim() : "";
            let aMaterno = res.data.integrantes[i].segundoApellidoIntegrante ? res.data.integrantes[i].segundoApellidoIntegrante.trim() : "";
            setDatos({ ...datos, nombreFuncionario: `${nombre} ${aPaterno} ${aMaterno}` });
          }
        }
      }
    }); 

    if (data !== undefined) {
      getByIdRegister(data.idObservador).then((res) => {
        setOriginal(res.data.observador);
        if (res.data.observador.tipoEntidad !== 0) {
          setVariasEntidades(true);
        }
        setElementosClave(true);
        var momentFecha = moment(res.data.observador.fechaNacimiento);
        props.form.setFieldsValue({
          residencia: res.data.observador.tipoObservador,
          sexo: res.data.observador.sexo,
          fechaNacimiento: momentFecha,
          // ----------------------- FECHA DE RECEPCIÓN/SOLICITUD --------------------------------------------
          fechaRecepcion: res.data.observador.fechaSolicitud && moment(res.data.observador.fechaSolicitud),
          edad: res.data.observador.edad.toString(),
          tipoSolicitud: res.data.observador.tipoSolicitud,
          idOrganizacion:
            res.data.observador.obsOrganizacion.length > 0
              ? res.data.observador.obsOrganizacion[0].organizaciones.idOrganizacion.toString()
              : "",
          modalidad: res.data.observador.modalidad,
          tipoEntidad: res.data.observador.tipoEntidad,
          origenSolicitud: res.data.observador.origenSolicitud,
          idProcesoElectoral: res.data.observador.idProcesoElectoral,
          idDetalleProceso: res.data.observador.idDetalleProceso,
          proObservar: res.data.observador.idDetalleProceso.toString(),
          aPaterno: res.data.observador.apellidoPaterno,
          aMaterno: res.data.observador.apellidoMaterno,
          nombre: res.data.observador.nombre,
          escolaridad: res.data.observador.cEscolaridades.idEscolaridad.toString(),
          ocupacion: res.data.observador.ocupacion,
          claveElector: res.data.observador.claveElector,
          seccion: res.data.observador.seccion 
            ? res.data.observador.seccion.toString()
            : "",
          calle: res.data.observador.calle
            ? res.data.observador.calle 
            : "",
          noExterior: res.data.observador.numeroExterno,
          noInterior: res.data.observador.numeroInterno,
          codigoPostal: res.data.observador.codigoPostal
            ? res.data.observador.codigoPostal.toString()
            : "",
          entidad: res.data.observador.idEstado
            ? res.data.observador.idEstado.toString()
            : "",
          mail:
            res.data.observador.obsCuenta.length > 0
              ? res.data.observador.obsCuenta[0].datosCuenta.correoElectronico
              : "",
          phone: res.data.observador.telefono
            ? res.data.observador.telefono.toString()
            : "",
          cel: res.data.observador.celular
            ? res.data.observador.celular.toString()
            : "",
          junta: res.data.observador.direccionJunta,
        });
        if (res.data.observador.codigoPostal && res.data.observador.codigoPostal.toString().length < 5) {
          var faltanteCP = 5 - res.data.observador.codigoPostal.toString().length;
          var finalCP = res.data.observador.codigoPostal.toString();
          for (let i = 0; i < faltanteCP; i++) {
            finalCP = "0" + finalCP;
          }
          props.form.setFieldsValue({
            codigoPostal: finalCP,
          });
        }

        if (res.data.observador.tipoObservador === OBSERVADOR_EXTRANJERO) {
          const idEntidadExtranjero = res.data.observador.extranjero.idEstado;
          getEntidades().then((res) => {
            setSelectEdo(res.data);
            for (let i = 0; i < res.data.length; i++) {
              if (idEntidadExtranjero === res.data[i].id.idEstado) {
                setEntidadPantalla(res.data[i].nombreEstado);
                break;
              }
            }
          });
          const idPais = res.data.observador.extranjero.idPais;
          setEsExtranjero(true);
          getPaises().then((res) => {
            setSelectPais(res.data);
            if (res.data !== undefined && res.data !== null && res.data.length > 0) {
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].idPais === idPais) {
                  props.form.setFieldsValue({
                    paisTxt: res.data[i].pais,
                  });
                  break;
                }
              }
            }
          });
          props.form.setFieldsValue({
            lugarEmision: res.data.observador.extranjero.emicionIne,
            paisReside: idPais.toString(),
            entidadNacimiento: res.data.observador.extranjero.idEstado.toString(),
            edoProvReg: res.data.observador.extranjero.estado,
            ciudadExt: res.data.observador.extranjero.ciudad,
          });
        } else {
          const idEntidadNacional = res.data.observador.idEstado;
          getEntidades().then((res) => {
            setSelectEdo(res.data);
            for (let i = 0; i < res.data.length; i++) {
              if (idEntidadNacional === res.data[i].id.idEstado) {
                setEntidadPantalla(res.data[i].nombreEstado);
                break;
              }
            }
          });
          if (res.data.observador.seccion.toString().length < 4) {
            var faltante = 4 - res.data.observador.seccion.toString().length;
            var final = res.data.observador.seccion.toString();
            for (let i = 0; i < faltante; i++) {
              final = "0" + final;
            }
            props.form.setFieldsValue({
              seccion: final,
            });
          }
          getMunicipios(res.data.observador.idEstado).then((res) => {
            if(res.data !== undefined && res.data !== null) {
              setSelectMun(res.data);
            }
          });
          props.form.setFieldsValue({
            municipio: res.data.observador.idMunicipio.toString(),
          });
          props.form.setFieldsValue({
            localidad: res.data.observador.idLocalidad,
          });
          let tipoSol = props.form.getFieldValue("tipoSolicitud");
          if (tipoSol === 1) {   
            setShowOrganizaciones(true);
            props.form.setFieldsValue({
              organizacion: res.data.observador.obsOrganizacion.length > 0 
              ? res.data.observador.obsOrganizacion[0].organizaciones.idOrganizacion.toString()
              : "",
            });
          }
        }

        for (let i = 0; i < res.data.observador.documentosObs.length; i++) {
          switch (
            res.data.observador.documentosObs[i].cTiposDocumento.idTipoDocumento
          ) {
            case ID_DOC_CRED_FRENTE:
              setImageFrontUrl({
                imageUrl:
                  "data:image/png;base64," +
                  res.data.observador.documentosObs[i].urlImagen,
              });
            break;
            case ID_DOC_CRED_VUELTA:
              setImageRoundUrl({
                imageUrl:
                  "data:image/png;base64," +
                  res.data.observador.documentosObs[i].urlImagen,
              });
            break;
            case ID_DOC_FOTOGRAFIA:
              setImagePhotoUrl({
                imageUrl:
                  "data:image/png;base64," +
                  res.data.observador.documentosObs[i].urlImagen,
              });
            break;
            default:
              break;
          }
        }
        setEntidades(res.data.observador.entidadesObservar);
        var entidadSeleccionada = [];
        for (let i = 0; i < res.data.observador.entidadesObservar.length; i++) {
          if (res.data.observador.entidadesObservar[i].checked) {
            entidadSeleccionada.push(res.data.observador.entidadesObservar[i].value);
          }
        }
        // Consulta y modifica
        setEntidadesSeleccionadas(entidadSeleccionada);
        props.form.setFieldsValue({
          entidadesSeleccionadas: entidadSeleccionada,
        });
        if (res.data.observador.origenSolicitud !== 2 && res.data.observador.origenSolicitud !== 4) {
          setVerOrigenSolicitud(false);
        }
        if (data.acreditado === true || data.declinado === true) {
          setButtonConsulta(true);
        }
      });
    } else {
      getEntidades().then((res) => {
        setSelectEdo(res.data);
      });
      if (esJuntaLocal(rolUsuario, proceso.distrito.idDistrito)) {
        history.push("/admin/solicitudes");
      }
      // Registro
      if ((entidades === undefined || entidades.length === 0)) {
        var entidadSeleccionada = [];
        getEntidadesObservar().then((res) => {
          setEntidades(res.data);
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].checked) {
              entidadSeleccionada.push(res.data[i].value);
            }
          }
        });
        setEntidadesSeleccionadas(entidadSeleccionada);
      }
      props.form.setFieldsValue({
        residencia: 0,
        origenSolicitud: 4,
      });
    }
  }
  //Fin de la Función que verifica si es una nueva solicitud o solo se modifica/consulta

  /////////////////////////////   Se obtienen los datos del formulario   ///////////////////////////////////////////////////////////
  const addRegister = (event) => {
    event.preventDefault();   
    props.form.validateFields((err, values) => {  
      if (!err) {
        if (values.seccion === "0000" && values.residencia === 0) {
          message.info( "Para poder continuar se requiere ingresar la sección electoral, favor de validar." );
        } else {
          if (values.codigoPostal !== undefined && values.codigoPostal !== null && values.codigoPostal !== "" && Number(values.codigoPostal) === 0) {
            message.info( "Para poder continuar se requiere ingresar un código postal válido." );
          } else {
            if (validaApellidos(values.aPaterno, values.aMaterno)) {
              if (
                imageFront.imageUrl !== undefined &&
                imageRound.imageUrl !== undefined &&
                imagePhoto.imageUrl !== undefined
              ) {
                // Se fija la modalidad en presencial para la Consulta Popular
                //values.modalidad = 2;
                if (
                  (values.mail === undefined || values.mail === "") &&
                  values.modalidad === 1
                ) {
                  message.info( "Para poder elegir la modalidad en línea para la capacitación, es necesario ingresar un correo electrónico válido." );
                } else {
                  if (proceso !== undefined && proceso.distrito !== undefined) {
                    values.idProcesoElectoral = proceso.proceso.idProcesoElectoral;
                    values.idDetalleProceso = proceso.proceso.idDetalleProceso;
                    values.idEstadoJunta = proceso.estado.idEstado;
                    values.idJunta = proceso.distrito.idDistrito;
                    for (let i = 0; i < infoMenu.listaDetalles.length; i++) {
                      if (
                        values.idDetalleProceso ===
                        infoMenu.listaDetalles[i].idDetalleProceso
                      ) {
                        values.folio = generaFolio(infoMenu.listaDetalles[i]);
                        values.nombreProceso =
                          infoMenu.listaDetalles[i].nombreProceso;
                        break;
                      }
                    }
                  } 
                  values.usuario = user.sub;
                  if (datos.idEntidad !==undefined && datos.idEntidad !== null & datos.entidad !== undefined && datos.entidad !== null) {
                    values.idEntidad = datos.idEntidad;
                    values.entidad = datos.entidad;
                  } else {
                    for (let i = 0; i < selectEdo.length; i++) {
                      if (selectEdo[i].id.idEstado === Number(values.entidad)) {
                        values.idEntidad = values.entidad;
                        values.entidad = selectEdo[i].nombreEstado;
                        break;
                      }
                    }
                  }
                  if (datos.idMunicipio !==undefined && datos.idMunicipio !== null & datos.municipio !== undefined && datos.municipio !== null) {
                    values.idMunicipio = datos.idMunicipio;
                    values.municipio = datos.municipio;
                  } else {
                    for (let i = 0; i < selectMun.length; i++) {
                      if (selectMun[i].idMunicipio === Number(values.municipio)) {
                        values.idMunicipio = values.municipio;
                        values.municipio = selectMun[i].municipio;
                        break;
                      }
                    } 
                  }
                  // INCORPORAMOS LOS DATOS QUE REQUIERE EL PDF DE ACREDITACIÓN
                  values.presidenteJunta = datos.nombreFuncionario;
                  if (values.organizacion !== undefined && values.organizacion !== null) {
                    for (let i = 0; i < select.length; i++) {
                      if (select[i].idOrganizacion === Number(values.organizacion)) {
                        let nombre = select[i].nombreRepre;
                        let aPaterno = select[i].apellidoPaternoRepre ? select[i].apellidoPaternoRepre:"";
                        let aMaterno = select[i].apellidoMaternoRepre ? select[i].apellidoMaternoRepre:"";
                        values.organizacion = select[i].organizacion;
                        values.representanteOrg = `${nombre} ${aPaterno} ${aMaterno}`;
                        values.correoOrg = select[i].correoElectronico;
                        break;
                      }
                    }
                  }
                  //values.procesoElectoral = proceso.proceso.nombreProceso.slice(proceso.proceso.nombreProceso.length - 9, proceso.proceso.nombreProceso.length);
                  values.procesoElectoral = proceso.proceso.nombreProceso;
                  values.municipioJunta = municipioJunta;
                  values.entidadJunta = proceso.estado.nombreEstado;
                  for (let i = 0; i < selectEsc.length; i++) {
                    if (selectEsc[i].idEscolaridad === Number(values.escolaridad)) {
                      values.estudios = selectEsc[i].escolaridad;
                      break;
                    }
                  }
                  if (direccionJunta !== undefined) {
                    values.junta = direccionJunta;
                  }
                  if (datos.organizacion !== undefined) {
                    values.idOrganizacion = datos.idOrganizacion;
                    values.organizacion = datos.organizacion;
                  }
                  if (modulo === 3) {
                    values.idObservador = original.idObservador;
                  }
                  values.status = ESTATUS_VALIDADO;
                  if (values.tipoEntidad === 1 && (entidadesSeleccionadas === undefined || entidadesSeleccionadas.length === 0)) {
                    message.info("Favor de elegir la entidad o entidades a observar.");
                  } else {
                    values.entidadesSeleccionadas = entidadesSeleccionadas;
                    if (values.aPaterno !== undefined && values.aPaterno !== null) {
                      values.aPaterno = values.aPaterno.trim();
                    }
                    if (values.aMaterno !== undefined && values.aMaterno !== null) {
                      values.aMaterno = values.aMaterno.trim();
                    }
                    values.nombre = values.nombre.trim();
                    original.extranjero = null;
                    setButton(true);

                    if (original.origenSolicitud === 2) {
                      values.origenSolicitud = 4;
                    }
                    // console.log(JSON.stringify(values));
                    // console.log(values);
                    controlRegister( values, register.foto, register.frente, register.vuelta, modulo, history, setButton, original, setLoader );                  
                  }
                }
              } else {
                message.info("Favor de adjuntar los documentos requeridos.");
              }
            } else {
              message.info("Al menos uno de los dos apellido es obligatorio.");
            }
          } 
        }
      } else {
        message.info("Los datos con ( * ) son requeridos.");
      }
    });
  };
  
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

   //Función para comprimir la imagen
   const callbackFunction = (fileObj, name) => {
    let anchuraNueva = 1170;
    let result = new Promise((resolve, reject) => {
      let img = new Image();
      img.src = window.URL.createObjectURL(fileObj);
      img.onload = () => {
        //calcular nueva altura sin perder la relación de aspecto
        const newHeight = calulateNewHeight(
          img.width,
          img.height,
          anchuraNueva
        );
        new Compressor(fileObj, {
          width: anchuraNueva,
          height: newHeight,
          quality: 0.68,
          success(result) {
            resolve(result);
          },
          error(err) {
            console.log(err);
            reject(err);
          },
        });
      };
    });
    result
      .then((value) => {
        //let nombreArchivo = value.name.substring(0,value.name.lastIndexOf("."));
        let extNombre = value.name.substring(value.name.lastIndexOf(".")+1,value.name.length);
        //Se obtien el archivo
        switch (name) {
          case 1:
            setRegister({ ...register, frente: new File([value], `frente.${extNombre}`) }); 
            setButton(false);
            break;
          case 2:
            setRegister({ ...register, vuelta: new File([value], `vuelta.${extNombre}`) });
            setButton(false);
            break;
          case 3:
            setRegister({ ...register, foto: new File([value], `foto.${extNombre}`) });
            setButton(false);
            break;
          default:
            break;
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("Ocurrió un error al adjuntar el archivo, favor de volver a intentar.");
      });
  };

  //Se carga el input de la imagen [FRENTE]
  const handleChangeFront = (info) => {
    if (info.file.status === "uploading") {
      setButton(true);
      setImageFrontUrl({ loading: true });
      return;
    }
    if (info.file.status === "error") {
      if (rol_cau.includes(rolUsuario)) {
        setButton(true);
      } else {
        if (rol_cau.includes(rolUsuario)) {
          setButton(true);
        } else {
          setButton(false);
        } 
      }      
      setImageFrontUrl({ loading: false });
      message.error("Ocurrió un error al adjuntar el archivo, favor de volver a intentar.");
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) =>
        setImageFrontUrl({
          imageUrl,
          loading: false,
        })
      );
      if (rol_cau.includes(rolUsuario)) {
        setButton(true);
      } 
      callbackFunction(info.file.originFileObj, 1); 
    }
  };
  //Se carga el input de la imagen [VUELTA]
  const handleChangeRound = (info) => {
    if (info.file.status === "uploading") {
      setButton(true);
      setImageRoundUrl({ loading: true });
      return;
    }
    if (info.file.status === "error") {
      if (rol_cau.includes(rolUsuario)) {
        setButton(true);
      } else {
        setButton(false);
      } 
      setImageRoundUrl({ loading: false });
      message.error("Ocurrió un error al adjuntar el archivo, favor de volver a intentar.");
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) =>
        setImageRoundUrl({
          imageUrl,
          loading: false,
        })
      );
      if (rol_cau.includes(rolUsuario)) {
        setButton(true);
      }
      callbackFunction(info.file.originFileObj, 2);  
    }
  };
  //Se carga el input de la imagen [FOTO]
  const handleChangePhoto = (info) => {
    if (info.file.status === "uploading") {
      setButton(true);
      setImagePhotoUrl({ loading: true });
      return;
    }
    if (info.file.status === "error") {
      if (rol_cau.includes(rolUsuario)) {
        setButton(true);
      } else {
        setButton(false);
      } 
      setImagePhotoUrl({ loading: false });
      message.error("Ocurrió un error al adjuntar el archivo, favor de volver a intentar.");
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) =>
        setImagePhotoUrl({
          imageUrl,
          loading: false,
        })
      );
      if (rol_cau.includes(rolUsuario)) {
        setButton(true);
      }
      callbackFunction(info.file.originFileObj, 3);  
    }
  };

  const onChangeResidencia = (e) => {
    if (e.target.value === 0) {
      setEsExtranjero(false); 
    } else {
      setEsExtranjero(true); 
      getPaises().then((res) => {
        setSelectPais(res.data);
      });
    }
  };

  const onChangeTipoSolicitud = (e) => {
    if (e.target.value === 0) {
      setShowOrganizaciones(false); // se oculta el componente
    } else {
      setShowOrganizaciones(true); // se muestra el componente
    }
  };

  const onChangeTipoEntidad = (e) => {
    if (e.target.value === 1) {
      setVariasEntidades(true);
    } else {
      setVariasEntidades(false);
    }
  };

  function onChangeGroup(checkedValues) {
    var entidadSeleccionada = [];
    for (let i = 0; i < checkedValues.length; i++) {
      entidadSeleccionada.push(checkedValues[i]);
    }
    setEntidadesSeleccionadas(entidadSeleccionada);
  }

  const EntidadesObservar = () => (
    <div id="entidadesObservarReq">
      <Form.Item>
        {getFieldDecorator("entidadesSeleccionadas", { rules: required })(
          <Checkbox.Group options={entidades} disabled={disabled.input} type="checkbox" defaultValue={entidadesSeleccionadas} onChange={onChangeGroup} />
        )}
        </Form.Item>
    </div>
  );

  const ResultsOrganizaciones = () => (
    <div id="organizaciones">
      {!disabled.input && <Ast />} Organización
      <Form.Item>
        {getFieldDecorator("organizacion", { rules: required })(
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Selecciona"
            disabled={disabled.input}
            style={{ width: 450 }}
            onSelect={(value, e) => getCadenaOrganizacion(value, e)}
          >
            {select.map((item) => (
              <Option key={item.idOrganizacion}>{item.organizacion}</Option>
            ))}
          </Select>
        )}
      </Form.Item>
    </div>
  );

  const ResultsSolicitudRecibida = () => {
    return (
      <div>
        {!disabled.input && <Ast />} Solicitud recibida por
        <Form.Item>
          {getFieldDecorator("origenSolicitud", { rules: required })(
            <Radio.Group disabled={disabled.input}>
              <Radio value={4}>Junta</Radio>
              {/*<Radio value={2}>OPL</Radio>*/}
            </Radio.Group>
          )}
        </Form.Item>
      </div>
    );
  };

  const SolicitudRecibidaPublico = () => {
    return (
      <div style={{ marginBottom: "20px" }}>
        {!disabled.input && <Ast />}Solicitud recibida por el Portal Público
      </div>
    );
  };

  const ProcesoObservar = () => {
    if (modulo === 2) {
      // Lista que contiene los procesos electorales a observar
      if (infoMenu.listaDetalles !== undefined) {
        for (let i = 0; i < infoMenu.listaDetalles.length; i++) {
          proObs.push(
            <Option key={infoMenu.listaDetalles[i].idDetalleProceso}>
              {infoMenu.listaDetalles[i].descripcion}
            </Option>
          );
        }
      }
      return (
        <div>
          <Form.Item>
            {getFieldDecorator(
              "proObservar",
              {}
            )(
              <Select
                placeholder="Selecciona"
                style={{ width: 315 }}
                disabled={disabled.input}
              >
                {proObs}
              </Select>
            )}
          </Form.Item>
        </div>
      );
    } else {
      if (proceso !== undefined && proceso !== null && proceso.proceso !== undefined) {
        return (
          <div>
            <Form.Item>
              <Input
                style={{ width: 315, borderColor: "#FFF" }}
                readOnly
                value={proceso.proceso.descripcion}
              ></Input>
            </Form.Item>
          </div>
        );
      } else {
        // Lista que contiene los procesos electorales a observar
        if (infoMenu.listaDetalles !== undefined) {
          for (let i = 0; i < infoMenu.listaDetalles.length; i++) {
            proObs.push(
              <Option key={infoMenu.listaDetalles[i].idDetalleProceso}>
                {infoMenu.listaDetalles[i].descripcion}
              </Option>
            );
          }
        }
        return (
          <div>
            <Form.Item>
              {getFieldDecorator(
                "proObservar",
                {}
              )(
                <Select
                  placeholder="Selecciona"
                  style={{ width: 315 }}
                  disabled={disabled.input}
                >
                  {proObs}
                </Select>
              )}
            </Form.Item>
          </div>
        );
      }
    }
  };

  const onChangeSinNum = (e) => {
    if (e.target.checked) {
      setDisabled({...disabled, checkNum: true });
      props.form.setFieldsValue({ noExterior: "" });
      props.form.setFieldsValue({ noInterior: "" });
    } else {
      setDisabled({...disabled, checkNum: false });
    }
  };

  const onChangeClave = (e) => {
    if (e.target.value.length === 18) {
      setLoader({ loading: true, message: MSN_GUARDAR });
      //Validamos si la clave de elector viene del proceso 16, detalle 108
      getIdObsProcesoAnterior(e.target.value.toUpperCase()).then((res) => {
        var idObservador = res.data.toString();
        if(idObservador !== undefined && idObservador !== null && idObservador.length > 0) {
          //Cargamos la info del observador copiando parcialmente el código de getRegister
          getByIdRegister(idObservador).then((res) => {
            setOriginal(res.data.observador);
            if (res.data.observador.tipoEntidad !== 0) {
              setVariasEntidades(true);
            }
            setElementosClave(true);
            var momentFecha = moment(res.data.observador.fechaNacimiento);
            props.form.setFieldsValue({
              residencia: res.data.observador.tipoObservador,
              sexo: res.data.observador.sexo,
              fechaNacimiento: momentFecha,
              // ----------------------- FECHA DE RECEPCIÓN/SOLICITUD --------------------------------------------
              fechaRecepcion: res.data.observador.fechaSolicitud && moment(res.data.observador.fechaSolicitud),
              edad: res.data.observador.edad.toString(),
              tipoSolicitud: res.data.observador.tipoSolicitud,
              idOrganizacion:
                res.data.observador.obsOrganizacion.length > 0
                  ? res.data.observador.obsOrganizacion[0].organizaciones.idOrganizacion.toString()
                  : "",
              modalidad: res.data.observador.modalidad,
              tipoEntidad: res.data.observador.tipoEntidad,
              origenSolicitud: res.data.observador.origenSolicitud,
              idProcesoElectoral: res.data.observador.idProcesoElectoral,
              idDetalleProceso: res.data.observador.idDetalleProceso,
              proObservar: res.data.observador.idDetalleProceso.toString(),
              aPaterno: res.data.observador.apellidoPaterno,
              aMaterno: res.data.observador.apellidoMaterno,
              nombre: res.data.observador.nombre,
              escolaridad: res.data.observador.cEscolaridades.idEscolaridad.toString(),
              ocupacion: res.data.observador.ocupacion,
              claveElector: res.data.observador.claveElector,
              seccion: res.data.observador.seccion 
                ? res.data.observador.seccion.toString()
                : "",
              calle: res.data.observador.calle
                ? res.data.observador.calle 
                : "",
              noExterior: res.data.observador.numeroExterno,
              noInterior: res.data.observador.numeroInterno,
              codigoPostal: res.data.observador.codigoPostal
                ? res.data.observador.codigoPostal.toString()
                : "",
              entidad: res.data.observador.idEstado
                ? res.data.observador.idEstado.toString()
                : "",
              mail:
                res.data.observador.obsCuenta.length > 0
                  ? res.data.observador.obsCuenta[0].datosCuenta.correoElectronico
                  : "",
              phone: res.data.observador.telefono
                ? res.data.observador.telefono.toString()
                : "",
              cel: res.data.observador.celular
                ? res.data.observador.celular.toString()
                : "",
              junta: res.data.observador.direccionJunta,
            });
            if (res.data.observador.codigoPostal && res.data.observador.codigoPostal.toString().length < 5) {
              var faltanteCP = 5 - res.data.observador.codigoPostal.toString().length;
              var finalCP = res.data.observador.codigoPostal.toString();
              for (let i = 0; i < faltanteCP; i++) {
                finalCP = "0" + finalCP;
              }
              props.form.setFieldsValue({
                codigoPostal: finalCP,
              });
            }
            if (res.data.observador.tipoObservador === OBSERVADOR_EXTRANJERO) {
              const idEntidadExtranjero = res.data.observador.extranjero.idEstado;
              getEntidades().then((res) => {
                setSelectEdo(res.data);
                for (let i = 0; i < res.data.length; i++) {
                  if (idEntidadExtranjero === res.data[i].id.idEstado) {
                    setEntidadPantalla(res.data[i].nombreEstado);
                    break;
                  }
                }
              });
              const idPais = res.data.observador.extranjero.idPais;
              setEsExtranjero(true);
              getPaises().then((res) => {
                setSelectPais(res.data);
                if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].idPais === idPais) {
                      props.form.setFieldsValue({
                        paisTxt: res.data[i].pais,
                      });
                      break;
                    }
                  }
                }
              });
              props.form.setFieldsValue({
                lugarEmision: res.data.observador.extranjero.emicionIne,
                paisReside: idPais.toString(),
                entidadNacimiento: res.data.observador.extranjero.idEstado.toString(),
                edoProvReg: res.data.observador.extranjero.estado,
                ciudadExt: res.data.observador.extranjero.ciudad,
              });
            } else {
              setEsExtranjero(false);
              const idEntidadNacional = res.data.observador.idEstado;
              getEntidades().then((res) => {
                setSelectEdo(res.data);
                for (let i = 0; i < res.data.length; i++) {
                  if (idEntidadNacional === res.data[i].id.idEstado) {
                    setEntidadPantalla(res.data[i].nombreEstado);
                    break;
                  }
                }
              });
              if (res.data.observador.seccion.toString().length < 4) {
                var faltante = 4 - res.data.observador.seccion.toString().length;
                var final = res.data.observador.seccion.toString();
                for (let i = 0; i < faltante; i++) {
                  final = "0" + final;
                }
                props.form.setFieldsValue({
                  seccion: final,
                });
              }
              getMunicipios(res.data.observador.idEstado).then((res) => {
                if(res.data !== undefined && res.data !== null) {
                  setSelectMun(res.data);
                }
              });
              props.form.setFieldsValue({
                municipio: res.data.observador.idMunicipio.toString(),
              });
              props.form.setFieldsValue({
                localidad: res.data.observador.idLocalidad,
              });
              let tipoSol = props.form.getFieldValue("tipoSolicitud");
              if (tipoSol === 1) {   
                setShowOrganizaciones(true);
                props.form.setFieldsValue({
                  organizacion: res.data.observador.obsOrganizacion.length > 0 
                  ? res.data.observador.obsOrganizacion[0].organizaciones.idOrganizacion.toString()
                  : "",
                });
              } else {
                setShowOrganizaciones(false);
              }
            }
            for (let i = 0; i < res.data.observador.documentosObs.length; i++) {
              switch (
                res.data.observador.documentosObs[i].cTiposDocumento.idTipoDocumento
              ) {
                case ID_DOC_CRED_FRENTE:
                  setImageFrontUrl({
                    imageUrl:
                      "data:image/png;base64," +
                      res.data.observador.documentosObs[i].urlImagen,
                  });
                break;
                case ID_DOC_CRED_VUELTA:
                  setImageRoundUrl({
                    imageUrl:
                      "data:image/png;base64," +
                      res.data.observador.documentosObs[i].urlImagen,
                  });
                break;
                case ID_DOC_FOTOGRAFIA:
                  setImagePhotoUrl({
                    imageUrl:
                      "data:image/png;base64," +
                      res.data.observador.documentosObs[i].urlImagen,
                  });
                break;
                default:
                  break;
              }
            }
            setEntidades(res.data.observador.entidadesObservar);
            var entidadSeleccionada = [];
            for (let i = 0; i < res.data.observador.entidadesObservar.length; i++) {
              if (res.data.observador.entidadesObservar[i].checked) {
                entidadSeleccionada.push(res.data.observador.entidadesObservar[i].value);
              }
            }
            // Consulta y modifica
            setEntidadesSeleccionadas(entidadSeleccionada);
            props.form.setFieldsValue({
              entidadesSeleccionadas: entidadSeleccionada,
            });
            if (res.data.observador.origenSolicitud !== 2 && res.data.observador.origenSolicitud !== 4) {
              setVerOrigenSolicitud(false);
            }
          });
          //Deshabilitamos todos los inputs como consulta 
          setDisabled({ ...disabled, input: true, checkNum: true, checkMail: true, titulo: 4, });
          setRatifica(true);
        }
        setLoader({ loading: false, message: "" });
      }).catch((error) => {
        console.log(error);
        setLoader({ loading: false, message: "" });
      });
      setElementosClave(true);
      let sexoCiudadano = getSexo(e.target.value);
      let fechaCiudadano = getFechaNacimiento(e.target.value);
      let edadCiudadano = getEdad(fechaCiudadano);
      if (fechaCiudadano.isValid() && !isNaN(edadCiudadano)) {
        props.form.setFieldsValue({
          sexo: sexoCiudadano,
          fechaNacimiento: fechaCiudadano,
          edad: edadCiudadano.toString(),
        });
      } else {
        message.info("Confirme que la fecha de nacimiento cuente con el formato AA/MM/DD dentro de la clave de elector.");
      }
    }
  };

  const onChangeFecha = (e) => {
    let edadCiudadano = getEdad(e);
      props.form.setFieldsValue({
        edad: edadCiudadano.toString(),
      });
  }

  const onChangeEntidad = (e) => {
    getMunicipios(e).then((res) => {
      if(res.data !== undefined && res.data !== null) {
        setSelectMun(res.data);
      }
    });
    for (let i = 0; i < selectEdo.length; i++) {
      if (Number(e) === selectEdo[i].id.idEstado) {
        setEntidadPantalla(selectEdo[i].nombreEstado);
        break;
      }
    }
  };

  const onChangeEntidadNacimiento = (e) => {
    for (let i = 0; i < selectEdo.length; i++) {
      if (Number(e) === selectEdo[i].id.idEstado) {
        setEntidadPantalla(selectEdo[i].nombreEstado);
        break;
      }
    }
  };

  const onChangePais = (e) => {
    for (let i = 0; i < selectPais.length; i++) {
      if (selectPais[i].idPais === Number(e)) {
        props.form.setFieldsValue({
          paisTxt: selectPais[i].pais,
        });
        break;
      }
    }
  };

  const onChangeSinMail = (e) => {
    if (e.target.checked) {
      setDisabled({...disabled, checkMail: true });
      props.form.setFieldsValue({ mail: "" });
    } else {
      setDisabled({...disabled, checkMail: false });
    }
  };

  const ButtonsPage = () => {
    if (disabled.titulo === 2) {
      return (
        <div>
          <Col span={24} style={{ paddingTop: "20px", paddingRight: "20px" }}>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                disabled={buttonConsulta}
                type="primary"
                onClick={() => {
                  setDisabled({
                    ...disabled,
                    input: false,
                    checkNum: false,
                    checkMail: false,
                    titulo: 3,
                  });
                  setModulo(3);
                  window.scrollTo(0, 0);
                }}
              >
                Editar
              </Button>
            </Form.Item>
          </Col>
        </div>
      );
    } else {
      if (disabled.titulo === 3 || disabled.titulo === 1) {
        return (
          <div>
            <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
              <Form.Item style={{ textAlign: "right" }}>
                <Button type="default">
                  <Link to={"/admin/solicitudes"}>Cancelar</Link>
                </Button>
              </Form.Item>
            </Col>
            <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
              <Form.Item style={{ textAlign: "left" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={button}
                  onClick={() => {}}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </div>
        );
      } else {
        if (ratifica) {
          return (
            <div>
              <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                <Form.Item style={{ textAlign: "right" }}>
                  <Button type="default">
                    <Link to={"/admin/solicitudes"}>Cancelar</Link>
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                <Form.Item style={{ textAlign: "left" }}>
                  <Button
                    type="primary"
                    /* htmlType="submit" */
                    disabled={button}
                    onClick={confirmaObservador}
                    style={{width: 260}}
                  >
                    Confirmar datos del Observador
                  </Button>
                </Form.Item>
              </Col>
            </div>
          );
        } else {
          return(<div></div>);          
        }
      }
    }
  };

  const confirmaObservador = (event) => {
    event.preventDefault();
    setButton(true);
    datosCP.idObservador = original.idObservador;
    datosCP.idProcesoAnterior = original.idProcesoElectoral;
    datosCP.idDetalleAnterior = original.idDetalleProceso;
    datosCP.idProcesoActual = proceso.proceso.idProcesoElectoral;
    datosCP.idDetalleActual = proceso.proceso.idDetalleProceso;
    datosCP.documentosObs = original.documentosObs;
    datosCP.origenSolicitud = ORIGEN_JUNTA;
    datosCP.estadoJunta = proceso.estado.idEstado;
    datosCP.distritoJunta = proceso.distrito.idDistrito;
    setDatosCP(datosCP);
    console.log("entró a ratificar");
    console.log(datosCP);
    confirmaObservadorFunction(datosCP, proceso.proceso.idProcesoElectoral, history, setButton, setLoader);
  };
  
  const getCadenaEntidad = (value, event) => {
    setDatos({ ...datos, entidad: event.props.children, idEntidad: value });
  };
  const getCadenaMunicipio = (value, event) => {
    setDatos({ ...datos, municipio: event.props.children, idMunicipio: value });
  };
  const getCadenaOrganizacion = (value, event) => {
    setDatos({
      ...datos,
      organizacion: event.props.children,
      idOrganizacion: value,
    });
  };

  function EscolaridadNacional() {
    return (
    <div id="escolaridadNacional">
        <Row>
          <Col span={12} style={{ paddingRight: "20px" }}>
            {!disabled.input && <Ast />} Escolaridad
            <Form.Item>
              {getFieldDecorator("escolaridad", { rules: required })(
                <Select placeholder="Selecciona" disabled={disabled.input}>
                  {selectEsc.map((item) => (
                    <Option key={item.idEscolaridad}>{item.escolaridad}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12} style={{ paddingRight: "20px" }}>
            Ocupación
            <Form.Item>
              {getFieldDecorator("ocupacion", { rules: ocupacion,
              getValueFromEvent: (event) => {
                return txtTexto.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input disabled={disabled.input} maxLength={40} />
              )}
            </Form.Item>
          </Col>
        </Row>
    </div>
    );
  };

  function SeccionNacional() {
    return (
    <div id="seccionNacional">
      <Col span={8} style={{ paddingRight: "20px" }}>
        {!disabled.input && <Ast />} Sección electoral
        <Form.Item>
          {getFieldDecorator("seccion", { rules: seccion,
          getValueFromEvent: (event) => {
            return soloDigitos.test(event.target.value)
              ? event.target.value
              : (event.target.value).slice(0,-1);
            }, 
          })(
          <Input disabled={disabled.input} style={{ width: 75 }} maxLength={4} />
          )}
        </Form.Item>
      </Col>
    </div>
    );
  };

  function EntidadNacional() {
    return (
    <div id="entidadNacional">
      <Col span={3} style={{ paddingRight: "20px" }}>
        {!disabled.input && <Ast />} Código postal
            <Form.Item>
              {getFieldDecorator("codigoPostal", { rules: cp, 
              getValueFromEvent: (event) => {
                return soloDigitos.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input disabled={disabled.input} maxLength={5} />
              )}
            </Form.Item>
      </Col>
      <Col span={6} style={{ paddingRight: "20px" }}>
        {!disabled.input && <Ast />} Entidad
        <Form.Item>
          {getFieldDecorator("entidad", { rules: required })(
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              disabled={disabled.input}
              placeholder="Selecciona"
              onSelect={(value, e) => getCadenaEntidad(value, e)}
              onChange={onChangeEntidad}
            >
              {selectEdo.map((item) => (
                <Option key={item.id.idEstado}>{item.nombreEstado}</Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
    </div>
    );
  };

  function DirNacional() {
    return (
    <div id="dirNacional">
      <Row>
        <Col span={12} style={{ paddingRight: "20px" }}>
          {!disabled.input && <Ast />} Municipio
          <Form.Item>
            {getFieldDecorator("municipio", { rules: required })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={disabled.input}
                placeholder="Selecciona"
                onSelect={(value, e) => getCadenaMunicipio(value, e)}
              >
                {selectMun.map((item) => (
                  <Option key={item.idMunicipio}>{item.municipio}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12} style={{ paddingRight: "20px" }}>
          {!disabled.input && <Ast />} Colonia
          <Form.Item>
            {getFieldDecorator("localidad", { rules: calle })(
              <Input disabled={disabled.input} maxLength={40} />
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
    );
  };

  function TelefonoNacional() {
    return (
    <div id="telefonoNacional">
      <Row>
          <Col span={3} style={{ paddingRight: "20px" }}>
            Teléfono
            <Form.Item>
              {getFieldDecorator("phone", { rules: tel,
              getValueFromEvent: (event) => {
                return soloDigitos.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input disabled={disabled.input} maxLength={10} />
              )}
            </Form.Item>
          </Col>
          <Col span={3} style={{ paddingRight: "20px" }}>
            Celular
            <Form.Item>
              {getFieldDecorator("cel", { rules: tel,
              getValueFromEvent: (event) => {
                return soloDigitos.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input disabled={disabled.input} maxLength={10} />
              )}
            </Form.Item>
          </Col>
      </Row>
    </div>
    );
  };

  function ResidenteExtranjero() {
    return (
    <div id="residenteEnExtranjero">
      <Row>
        <h3>Residentes en el extranjero</h3>
          <Col span={24} style={{ marginTop: "10px" }}>
            {!disabled.input && <Ast />} Lugar donde se emitió la credencial para votar
            <Form.Item>
              {getFieldDecorator("lugarEmision", { rules: required })(
                <Radio.Group disabled={disabled.input}>
                  <Radio value={0}>México</Radio>
                  <Radio value={1}>Extranjero</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            {!disabled.input && <Ast />} País de residencia
            <Form.Item>
              {getFieldDecorator("paisReside", { rules: required })(
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={disabled.input}
                  placeholder="Selecciona"
                  onChange={onChangePais}
                >
                  {selectPais.map((item) => (
                    <Option key={item.idPais}>{item.pais}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
      </Row>
    </div>
    );
  };
  
  function LugarNacimiento() {
    return (
    <div id="lugarNacimiento">
      <Col span={16} style={{ paddingRight: "20px" }}>
        {!disabled.input && <Ast />} Entidad/Lugar de nacimiento (Entidad del domicilio o referencia de la credencial de elector)
          <Form.Item>
            {getFieldDecorator("entidadNacimiento", { rules: required })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
                }
                disabled={disabled.input}
                placeholder="Selecciona"
                style={{ width: 315 }}
                onChange={onChangeEntidadNacimiento}
              >
                {selectEdo.map((item) => (
                  <Option key={item.id.idEstado}>{item.nombreEstado}</Option>
                ))}
              </Select>
              )}
          </Form.Item>
      </Col>
    </div>
    );
  };

  function EscolaridadExtranjero() {
    return (
    <div id="escolaridadExtranjero">
        <Row>
          <Col span={8} style={{ paddingRight: "20px" }}>
            {!disabled.input && <Ast />} Escolaridad
            <Form.Item>
              {getFieldDecorator("escolaridad", { rules: required })(
                <Select placeholder="Selecciona" disabled={disabled.input}>
                  {selectEsc.map((item) => (
                    <Option key={item.idEscolaridad}>{item.escolaridad}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8} style={{ paddingRight: "20px" }}>
            Ocupación
            <Form.Item>
              {getFieldDecorator("ocupacion", { rules: ocupacion,
              getValueFromEvent: (event) => {
                return txtTexto.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input disabled={disabled.input} maxLength={40} />
              )}
            </Form.Item>
          </Col>
        </Row>
    </div>
    );
  };

  function DirExtranjero() {
    return (
    <div id="dirExtranjero">
      <Row>
        <Col span={8} style={{ paddingRight: "20px" }}>
          Estado/Provincia/Región
          <Form.Item>
            {getFieldDecorator("edoProvReg")( <Input disabled={disabled.input} maxLength={40} /> )}
          </Form.Item>
        </Col>
        <Col span={8} style={{ paddingRight: "20px" }}>
          Ciudad
          <Form.Item>
            {getFieldDecorator("ciudadExt")( <Input disabled={disabled.input} maxLength={40} /> )}
          </Form.Item>
        </Col>
        <Col span={8} style={{ paddingRight: "20px" }}>
          País
          <Form.Item>
          {getFieldDecorator("paisTxt")( <TextArea style={{ width: 315, borderColor: "#FFF" }} readOnly autoSize /> )}
          </Form.Item>
        </Col>
      </Row>
    </div>
    );
  };

  function TelefonoExtranjero() {
    return (
    <div id="telefonoExtranjero">
      <Row>
          <Col span={3} style={{ paddingRight: "20px" }}>
            Teléfono
            <Form.Item>
              {getFieldDecorator("phone", { rules: telExt,
              getValueFromEvent: (event) => {
                return soloDigitos.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input disabled={disabled.input} maxLength={15} />
              )}
            </Form.Item>
          </Col>
          <Col span={3} style={{ paddingRight: "20px" }}>
            Celular
            <Form.Item>
              {getFieldDecorator("cel", { rules: telExt,
              getValueFromEvent: (event) => {
                return soloDigitos.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input disabled={disabled.input} maxLength={15} />
              )}
            </Form.Item>
          </Col>
      </Row>
    </div>
    );
  };

  function CodExtranjero() {
    return (
      <div>
        <Col span={3} style={{ paddingRight: "20px" }}>
        Código postal
            <Form.Item>
              {getFieldDecorator("codigoPostal", { 
              getValueFromEvent: (event) => {
                return soloDigitos.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input disabled={disabled.input} maxLength={5} />
              )}
            </Form.Item>
        </Col>
      </div>
    );
  };

  function CalleExtranjero() {
    return (
      <div>
        <Col span={6} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            {(!disabled.input && !esExtranjero) && <Ast />} Calle
            <Form.Item>
              {getFieldDecorator("calle")(
                <Input disabled={disabled.input} maxLength={50} />
              )}
            </Form.Item>
        </Col>
      </div>
    );
  };

  function CalleNacional() {
    return (
      <div>
        <Col span={6} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            {(!disabled.input && !esExtranjero) && <Ast />} Calle
            <Form.Item>
              {getFieldDecorator("calle", { rules: calle })(
                <Input disabled={disabled.input} maxLength={50} />
              )}
            </Form.Item>
        </Col>
      </div>
    );
  };

  function disabledDateRegister(current) {
    return current > moment(fechaLimite, dateFormat);
  }

  ////////////////////////////////////////////// --- FORMULARIO --- //////////////////////////////////////////////////////////////
  return (
    <div style={{ marginLeft: "20px" }}>
      <Link to={"/admin/solicitudes"} className="back">
        <Icon type="play-circle" theme="filled" rotate="180" /> Volver a
        "Solicitudes"
      </Link>
      <div style={{ marginTop: "30px" }}>
        {modulo === 2 && <h1>Ver solicitud</h1>}
        {modulo === 1 && (
          <>
            <h1>Nueva Solicitud</h1>
            <small>
              Los datos con ( <Ast /> ) son requeridos
            </small>
            <Alert message={<p style={{ marginBottom: "0px" }}>
              Para ratificar solicitudes del PEF2021 solo es necesario capturar la clave de elector, la información se mostrará precargada.</p>}
              type="info" showIcon />
          </>
        )}
        {modulo === 3 && (
          <>
            <h1>Editar solicitud</h1>
            <small>
              Los datos con ( <Ast /> ) son requeridos
            </small>
          </>
        )}
      </div>
      <Form onSubmit={addRegister}>
        <Row style={{ marginTop: "30px" }} />
      <Row>
        <h3>Lugar de residencia</h3>
          <Col span={24} style={{ marginTop: "10px" }}>
            <Form.Item>
              {getFieldDecorator("residencia", { rules: required })(
                <Radio.Group disabled={disabled.input} onChange={onChangeResidencia}>
                  <Radio value={0}>Territorio mexicano</Radio>
                  <Radio value={1}>Extranjero</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
      </Row>
      {esExtranjero ? ResidenteExtranjero() : null}
        <Row>
          <h3>Datos de la solicitud</h3>
          <Col span={24} style={{ marginTop: "10px" }}>
            {!disabled.input && <Ast />} Tipo de Solicitud
            <Form.Item style={{ marginBottom: "10px" }}>
              {getFieldDecorator("tipoSolicitud", { rules: required })(
                <Radio.Group
                  disabled={disabled.input}
                  onChange={onChangeTipoSolicitud}
                >
                  <Radio value={0}>Individual</Radio>
                  <Radio value={1}>Miembro de organización</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Col span={24}>
              {showOrganizaciones ? <ResultsOrganizaciones /> : null}
            </Col>
            <Col span={24}>
              {verOrigenSolicitud ? <ResultsSolicitudRecibida /> : <SolicitudRecibidaPublico />}
            </Col>
            <Col span={24}>
              {/* ----------------------- Se implementa la Fecha de recepción de la solicitud -----------------------------------*/}
              {!disabled.input && <Ast />} Fecha de recepción de la solicitud
              <Form.Item>
                {getFieldDecorator("fechaRecepcion", {
                  rules: [{ required: true, message: "Dato Requerido" }],
                })(
                  <DatePicker              
                    placeholder={placeholderDate}
                    format={formatDate}
                    disabled={disabled.input}
                    disabledDate={disabledDateRegister}
                  />
                )}
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <Row>
          <h3>Consulta Popular a observar</h3>
          <Col span={12}>{<ProcesoObservar />}</Col>
        </Row>
        <Row>
          <h3>Datos personales</h3>
          <Col span={24} style={{ marginBottom: "20px", paddingRight: "20px" }}>
            {!disabled.input && <Alert
                          message={<p style={{ marginBottom: "0px" }}>
                            Al menos uno de los apellidos es obligatorio</p>}
                          type="info"
                          showIcon
                        />}
          </Col>
          <Col span={8} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            Apellido paterno
            <Form.Item>
              {getFieldDecorator("aPaterno", { rules: apellidos,
              getValueFromEvent: (event) => {
                return txtTexto.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input disabled={disabled.input} maxLength={40} />
              )}
            </Form.Item>
          </Col>
          <Col span={8} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            Apellido materno
            <Form.Item>
              {getFieldDecorator("aMaterno", { rules: apellidos,
              getValueFromEvent: (event) => {
                return txtTexto.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input disabled={disabled.input} maxLength={40} />
              )}
            </Form.Item>
          </Col>
          <Col span={8} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            {!disabled.input && <Ast />} Nombre(s)
            <Form.Item>
              {getFieldDecorator("nombre", { rules: nombres,
              getValueFromEvent: (event) => {
                return txtTexto.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input disabled={disabled.input} maxLength={50} />
              )}
            </Form.Item>
          </Col>
        </Row>
        {!esExtranjero ? EscolaridadNacional() : null}
        <Row>
          <Col span={8} style={{ paddingRight: "20px" }}>
            {!disabled.input && <Ast />} Clave de elector
            <Form.Item>
              {getFieldDecorator("claveElector", { rules: claveElector, getValueFromEvent: valueUpperCase, })(
                <Input disabled={disabled.input} maxLength={18} onChange={onChangeClave} />
              )}
            </Form.Item>
          </Col>
          {!esExtranjero ? SeccionNacional() : LugarNacimiento()}
        </Row>
        <Row>
          <Col span={8} style={{ paddingRight: "20px" }}>
            {!disabled.input && <Ast />}Sexo
            <Form.Item>
              {getFieldDecorator("sexo", { rules: required })(
                <Radio.Group disabled={disabled.input || !elementosClave}>
                  <Radio value={"M"}>Mujer</Radio>
                  <Radio value={"H"}>Hombre</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={8} style={{ paddingRight: "20px" }}>
            {!disabled.input && <Ast />}Fecha de nacimiento
            <Form.Item>
              {getFieldDecorator("fechaNacimiento", { rules: required })(
                <DatePicker disabled={disabled.input || !elementosClave} format={dateFormat} onChange={onChangeFecha}/>
              )}
            </Form.Item>
          </Col>
          <Col span={8} style={{ paddingRight: "20px" }}>
            {!disabled.input && <Ast />}Edad
            <Form.Item>
              {getFieldDecorator("edad", { rules: [
                {required:true, message:"Dato requerido."},
                {validator: age},
              ], 
                getValueFromEvent: (event) => {
                return soloDigitos.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
                }, })(
                <Input disabled={disabled.input || !elementosClave} maxLength={3} />
              )}
            </Form.Item>
          </Col>
        </Row>
        {esExtranjero ? EscolaridadExtranjero() : null}
        <Row>
          <h3>Domicilio del solicitante</h3>
          {esExtranjero ? CalleExtranjero() : CalleNacional()}
          <Col
            span={6}
            style={{
              paddingRight: "20px",
              paddingLeft: "20px",
              paddingTop: "30px",
            }}
          >
            <Form.Item>
              {getFieldDecorator("sinNumero", {
                valuePropName: "checked",
                initialValue: false,
              })(
                <Checkbox disabled={disabled.input} onChange={onChangeSinNum}>
                  Sin número
                </Checkbox>
              )}
            </Form.Item>
          </Col>
          <Col span={6} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            No. exterior
            <Form.Item>
              {getFieldDecorator("noExterior", { 
              getValueFromEvent: (event) => {
                return numeroInt.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input maxLength={20} disabled={disabled.checkNum} />
              )}
            </Form.Item>
          </Col>
          <Col span={6} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            No. interior
            <Form.Item>
              {getFieldDecorator("noInterior", { 
              getValueFromEvent: (event) => {
                return numeroInt.test(event.target.value)
                  ? event.target.value
                  : (event.target.value).slice(0,-1);
              }, })(
                <Input maxLength={20} disabled={disabled.checkNum} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {!esExtranjero ? EntidadNacional() : CodExtranjero()}
        </Row>
        {!esExtranjero ? DirNacional() : DirExtranjero()}
        <Row>
          <h3>Datos de contacto</h3>
          <Col span={12} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            Correo electrónico
            <Form.Item>
              {getFieldDecorator("mail", { rules: correo })(
                <Input disabled={disabled.checkMail} maxLength={100} style={{ textTransform: "lowercase"}} />
              )}
            </Form.Item>
          </Col>
          <Col
            span={12}
            style={{
              paddingRight: "20px",
              paddingLeft: "20px",
              paddingTop: "30px",
            }}
          >
            <Form.Item>
              {getFieldDecorator("sinMail", {
                valuePropName: "checked",
                initialValue: false,
              })(
                <Checkbox disabled={disabled.input} onChange={onChangeSinMail}>
                  Sin correo electrónico
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>
        {!esExtranjero ? TelefonoNacional() : TelefonoExtranjero()}
        <Row>
          <h3>{!disabled.input && <Ast />} Modalidad para la capacitación</h3>
          <Col span={24} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            {/*<Alert message={<p style={{ marginBottom: "0px" }}> Para la Consulta Popular podrás tomar el curso de manera presencial o de manera virtual a través de alguna herramienta o plataforma (Zoom, Google Meet, Microsoft Teams o algún otro).</p>} type="info" showIcon />*/}
            {<Form.Item style={{ marginBottom: "0px" }}>
              {getFieldDecorator("modalidad", { rules: required })(
                <Radio.Group
                  id="radioMod"
                  disabled={disabled.input}
                >
                  <Radio
                    value={1}
                    style={radioStyle}
                    disabled={disabled.checkMail}
                  >
                    En línea (a través del portal público de observadores).
                  </Radio>
                  <Radio value={2} style={radioStyle}>
                    Presencial o virtual a través de alguna herramienta o plataforma
                    (Zoom, Google Meet, Microsoft Teams o algún otro).
                  </Radio>
                </Radio.Group>
              )}
                </Form.Item>}
          </Col>
        </Row>
        <Row style={{ marginTop: "30px" }}>
          <h3>Entidades a observar</h3>
          <Col span={24} style={{ marginTop: "10px" }}>
            {!disabled.input && <Ast />}¿En qué entidad o entidades desea el ciudadano observar la consulta popular?
            <Form.Item style={{ marginBottom: "10px" }}>
              {getFieldDecorator("tipoEntidad", { rules: required })(
                <Radio.Group
                  disabled={disabled.input}
                  onChange={onChangeTipoEntidad}
                >
                  <Radio value={0}>
                    Solo la entidad de la credencial
                    <div className="textRadio">
                      (La credencial corresponde a {entidadPantalla}).
                    </div>
                  </Radio>
                  <Radio value={1} style={{ marginLeft: 50 }}>
                    En una o más entidades distintas
                    <div className="textRadio">
                      <p></p>
                    </div>
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Col span={24}>
              {variasEntidades ? <EntidadesObservar /> : null}
            </Col>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "20px",
            marginRight: "20px",
            border: "1px solid #999",
          }}
        >
          <h3 style={{ marginTop: "20px", marginLeft: "20px" }}>
            Documentos adjuntos
          </h3>
          <Col
            span={24}
            style={{
              paddingTop: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingBottom: "20px",
            }}
          >
            {!disabled.input && <Ast />} Credencial de elector
          </Col>
          <Col span={24} style={{ marginBottom: "20px", paddingRight: "20px", paddingLeft: "20px" }}>
            {!disabled.input && <Alert
                          message={<p style={{ marginBottom: "0px" }}>
                            Frente y vuelta. Máximo 2 archivos en formato .jpeg o png que no excedan los 5MB por archivo.</p>}
                          type="info"
                          showIcon
                        />}
          </Col>
          <Row>
            <Col
              span={12}
              style={{
                paddingTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
              }}
            >
              Frente (anverso)
              <Form.Item style={{ marginBottom: "0px" }}>
                <Upload
                  name="frente"
                  listType="picture-card"
                  className="avatar-uploader"
                  action={URL_IMAGEN}
                  beforeUpload={beforeUpload}
                  onChange={handleChangeFront}
                  showUploadList={false}
                  disabled={disabled.input}
                  accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                >
                  {imageFront.imageUrl ? (
                    <img
                      src={imageFront.imageUrl}
                      alt="frente"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton(imageFront.loading)
                  )}
                </Upload>
              </Form.Item>
              {!disabled.input &&  <div style={{ marginBottom: 15 }}>
                <Icon type="info-circle" style={{ color: "#d5007f" }} />{" "}
                <small>Pulsa sobre una imagen para reemplazarla</small>
              </div>}
            </Col> 
            <Col
              span={12}
              style={{
                paddingTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
              }}
            >
              Vuelta (reverso)
              <Form.Item style={{ marginBottom: "0px" }}>
                <Upload
                  name="vuelta"
                  listType="picture-card"
                  className="avatar-uploader"
                  action={URL_IMAGEN}
                  beforeUpload={beforeUpload}
                  onChange={handleChangeRound}
                  showUploadList={false}
                  disabled={disabled.input}
                  accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                >
                  {imageRound.imageUrl ? (
                    <img
                      src={imageRound.imageUrl}
                      alt="vuelta"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton(imageRound.loading)
                  )}
                </Upload>
              </Form.Item>
              {!disabled.input &&  <div style={{ marginBottom: 15 }}>
                <Icon type="info-circle" style={{ color: "#d5007f" }} />{" "}
                <small>Pulsa sobre una imagen para reemplazarla</small>
              </div>}
            </Col>
          </Row>
          <Row>
            <Col
              span={12}
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              {!disabled.input && <Ast />} Fotografía
              <Form.Item style={{ marginBottom: "0px" }}>
                <Upload
                  name="photo"
                  listType="picture-card"
                  className="avatar-uploader"
                  action={URL_IMAGEN}
                  beforeUpload={beforeUpload}
                  onChange={handleChangePhoto}
                  showUploadList={false}
                  disabled={disabled.input}
                  accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                >
                  {imagePhoto.imageUrl ? (
                    <img
                      src={imagePhoto.imageUrl}
                      alt="foto"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton(imagePhoto.loading)
                  )}
                </Upload>
              </Form.Item>
              {!disabled.input &&  <div style={{ marginBottom: 15 }}>
                <Icon type="info-circle" style={{ color: "#d5007f" }} />{" "}
                <small>Pulsa sobre una imagen para reemplazarla</small>
              </div>}
            </Col>
          </Row>
        </Row>
        <Row>{<ButtonsPage />}</Row>
      </Form>
    </div>
  );
}
const RequestsForm = Form.create()(FormRegister);
export default withRouter(RequestsForm);
