import React, { useEffect } from "react";
import { Col, Row, Icon } from "antd";
import "../../CourseOnline/Courses.scss";
import { saveAs } from 'file-saver';

/**
 * Leccion 2
 * path: /ciudadano/curso-leccion2
 */
export default function Lesson2(props) {
  const row = 18;

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
  }, []);

  function download() {
    /* let a = document.createElement("a"); //Create <a>
    a.href = `data:application/vnd.ms-powerpoint, "./materiales_cp/L2.pptx"`; //Image Base64 Goes here
    a.download = `2. Desarrollo de la Consulta Popular.pptx`; //File name Here
    a.click(); //Downloaded file */
    saveAs(process.env.PUBLIC_URL + "/materiales_cp/desarolloConsulta.pptx", "2. Desarrollo de la Consulta Popular.pptx");
  }

  return (
    <>
      <Row gutter={24} className="material">
        <Col span={row} xxl={row} xl={row} lg={row} md={row} sm={24} xs={24}>
            <h1>Lección 2. Desarrollo de la Consulta Popular</h1>
            <hr></hr>
            <div className="texto_parrafo">
                <p>
                    En esta lección te describimos los actos previos a la Jornada de la Consulta Popular, los que se llevan a cabo durante y los posteriores a esta.
                </p>
                <p>
                    Podrás Identificar qué son las Mesas Receptoras de la Consulta Popular, cuál es su conformación, ubicación e integración; la documentación y el material que se ocupará el día de la Jornada de la Consulta Popular; cómo se realizará el conteo de las opiniones y el registro de resultados; y cuál es la forma de integrar el paquete y de publicar los resultados.
                </p>
                <p>
                    Consulta el siguiente material en el cual te describimos a detalle cada una de las etapas de la Consulta Popular.
                </p>
                <div className="matTitle">Material de apoyo</div>
                <dl className="matApoyo" style={{marginBottom: "10px"}}>Presentación: 2. Desarrollo de la Consulta Popular</dl>
                <button
                    className="btnDescargar material"
                    style={{ marginLeft: "0px", marginBottom: "25px" }}
                    onClick={(e) => download()}
                >
                  <Icon type="download" />
                  Descargar material
                </button>
                <p>
                  ¡Muy bien! Has terminado las dos lecciones de tu curso sobre la Consulta Popular 2021, ahora con la finalidad de reforzar tus conocimientos te invitamos a ver el siguiente video.
                  {/*¡Muy bien! Has terminado las dos lecciones de tu curso sobre la Consulta Popular 2021.*/}
                </p>
                <div className="matTitle">Material de apoyo</div>
                <dl className="matApoyo"><a rel="noopener noreferrer" style={{textDecorationLine: "underline"}} target="_blank" href={"https://youtu.be/V1TuNfO12c0"}>Vídeo: La Consulta Popular 2021</a></dl>
                <p>
                  Ahora ya estás listo/a para contestar tu cuestionario y poner a prueba tus conocimientos, te deseamos mucho éxito.
                </p>
            </div>
        </Col>
      </Row>
    </>
  );
}
