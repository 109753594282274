import React, { useState, useEffect } from "react";
import ContentConvocation from "../../../components/User/Public/SectionConvocation/ContentConvocation";
import { getConvocationPub } from "../../../api/convocation";
import SignIn from "../../../components/User/Public/SignIn";
import { Link } from "react-router-dom";
import { Icon } from "antd";

export default function Convocation(props) {
  const [ine, setIne] = useState([]);
  //const [opl, setOpl] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const {
  //   ciudadano: { ciudadano },
  // } = useAuth();

  useEffect(() => {
    getConvocationPub(true, 19, 142)
      .then((response) => {
        setIne(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="interioresPortal">
        <SignIn />
        <Link to={"/"} className="back">
          <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
        </Link>
        <h1 style={{ marginTop: 30 }}>Convocatoria</h1>
        {ine.length > 0 && (
          <>
            {/* <h2>{ine[0].proceso}</h2> */}
            <h2>Consulta Popular 2021</h2>
            <p>Nacional</p>
          </>
        )}
        <ContentConvocation ine={ine} />
      </div>
    </>
  );
}
