import React from "react";
import { Layout, Row, Col } from "antd";
import "./Footer.scss";
import logoINE from "../../../assets/img/logoINE_bco.svg";

const FooterPublic = (props) => {
  const { Footer } = Layout;
  return (
    <Footer className="footerP">
      <Row>
        <Col xl={12} lg={12} md={12} sm={24}>
          <div className="INElogofooter">
            <img src={logoINE} alt="Logo" />
          </div>
          <div>
            <center>
              <h3>INE</h3>
              <p>© Derechos Reservados,<br /> Instituto Nacional Electoral, México.</p>
              <p>Compatibilidad óptima con Google Chrome</p>
            </center>
          </div>
        </Col>
        <Col xl={6} lg={6} md={0} sm={0} xs={0}>
          <center>
            <h3>Oficinas Centrales:</h3>
            <p>Viaducto Tlalpan No. 100 Col. Arenal Tepepan,<br /> Alcaldía Tlalpan, C.P. 14610, Ciudad de México.</p>
          </center>
        </Col>
        <Col xl={6} lg={6} md={12} xs={24}>
          <center>
            <h3>Llámanos:</h3>
            <p>
              Desde cualquier parte del país sin costo: <b>800 433 2000</b>.<br />
              Desde Estados Unidos sin costo: <b>1 (866) 986 8306</b>. <br />
              Desde otros países por cobrar: <b>+52 (55) 5481 9897</b>.
            </p>
          </center>
        </Col>
      </Row>
      <Row>
      <div className="footer__version">
        <span style={{ color: "#333333" }}>{`11.9.7 Rev.37 19/08/2021 13:10`}</span> &nbsp;&nbsp;&nbsp;&nbsp; 11.9.7 / {new Date().getFullYear()}
      </div>
      </Row>
      {/* <Row>
        <Col span={8}>
          <img src={logoINE} alt="Logo" />
          <span>INEtel 800 433 2000 <br />© INE México 2020</span>
          <a
            href="https://cau.ine.mx/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CAU
          </a>
        </Col>
        <Col span={8}>
          Dirección Ejecutiva de Organización Electoral<br />
          <span>Compatibilidad óptima con Google Chrome</span>
        </Col>
        <Col span={8}>
          <span style={{ color: "#333333" }}>{`11.0.0 Rev.29 15/09/2020 14:32`}</span> &nbsp;&nbsp;&nbsp;&nbsp; 11.0 / {new Date().getFullYear()}
        </Col>
      </Row> */}
    </Footer>
  );
};

export default FooterPublic;
