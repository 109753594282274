import React from "react";
import { Col } from "antd";
import "./Portal.scss";
import { Link } from "react-router-dom";

const SectionPortal = ({idEstadoGralSol}) => {
  let band = false;
  if(idEstadoGralSol && (idEstadoGralSol === 3 || idEstadoGralSol === 5)) {
    band = true;
  }
  return (
    <div className="sectionMenu">
      <p className="titulo">
        Preguntas frecuentes sobre la solicitud de acreditación
      </p>
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <div>
          <div className="numero">1</div>
          <p>
            <b>¿En qué entidad o entidades puedo observar?</b>
          </p>
          <p>
            Puedes participar como observador en cualquier entidad que desees,
            sin importar la entidad donde vivas.
          </p>
        </div>
      </Col>
{/*       <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
        <div>
          <div className="numero">2</div>
          <p>
            <b>¿Cómo me puedo registrar si soy miembro de una organización?</b>
          </p>
          <p>
            Tienes que solicitar a la organización a la que perteneces el código
            de verificación que se le proporciono al registrarse ante el INE, el
            cual tendrás que ingresar al momento de realizar tu registro.
          </p>
        </div>
      </Col> */}
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <div>
          <div className="numero">2</div>
          <p>
            <b>¿Qué debo hacer si no deseo continuar con el proceso?</b>
          </p>
          <p>
            Debes declinar tu solicitud de acreditación. Puedes realizarlo en
            línea. Sólo sigue los pasos que se te indican a continuación:
          </p>
          <p>
            <Link to={"/ciudadano/declinar"} className="link" disabled={band}>
              Declina tu solicitud de acreditación
            </Link>
          </p>
        </div>
      </Col>
    </div>
  );
};

// <div className="sectionMenu">
//   <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
//     <Link to={"/portal/convocatoria"}>
//       <div className="card-menu">
//         <h3>Convocatorias</h3>
//         <ImgConvocatoria />
//         <p>Revisa la invitación para participar</p>
//       </div>
//     </Link>
//   </Col>
//   <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
//     <Link to={"/portal/requisitos"}>
//       <div className="card-menu">
//         <h3>Requisitos</h3>
//         <ImgRequisitos />
//         <p>Conoce que necesitas para ser Observador/a Electoral</p>
//       </div>
//     </Link>
//   </Col>
//   {/* <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
//     <Link to={"/portal/materiales"}>
//       <div className="card-menu">
//         <h3>Material de capacitación</h3>
//         <ImgMaterial />
//         <p>Consulta el material de apoyo para tu formación</p>
//       </div>
//     </Link>
//   </Col> */}
// </div>

export default SectionPortal;
