/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, Button, Modal, Icon } from "antd";
import { basePath } from "../../../api/config";
import axios from "axios";

const Signup = (props) => {
  const { getFieldDecorator } = props.form;
  const { setVisibleRegister, visibleRegister } = props;
  let email = "";
  const [ botonDeshabilitado, setBotonDeshabilitado ] = useState(false);

  useEffect(() => {
    if (!visibleRegister) {
      props.form.resetFields();
      setBotonDeshabilitado(false);
    }
  }, [props.form, visibleRegister]);

  //Funciones para comparar y validar que los dos correos son iguales
  const compareToFirstEmail = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue("email")) {
      callback("¡Los correos no coinciden!");
    } else {
      callback();
    }
  };

  const validateNextEmail = (rule, value, callback) => {
    const { form } = props;
    if (value) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };
  //Funciones para comparar y validar que las dos contraseñas son iguales
  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue("password")) {
      callback("¡Las contraseñas no coinciden!");
    } else {
      callback();
    }
  };

  const validateNextPassword = (rule, value, callback) => {
    const { form } = props;
    if (value) {
      form.validateFields(["confirmPass"], { force: true });
    }
    callback();
  };

  function error(mensaje) {
    Modal.error({
      title: "Creación de cuenta",
      content: (
        <div>
          <p>{mensaje}</p>
        </div>
      ),
      okText: "Entendido",
    });
  }
  function errorExistenteActivo(mensaje) {
    Modal.error({
      title: "Creación de cuenta",
      content: (
        <div>
          <p>{mensaje}</p>
          <p>Recupera tu constraseña</p>
          <a href="/portal/recovery">¿Olvidaste tu contraseña?</a>
        </div>
      ),
      okText: "Entendido",
    });
  }

  function errorExistenteNoActivo(mensaje) {
    Modal.error({
      title: "Creación de cuenta",
      content: (
        <div>
          <p>Cuenta ya registrada</p>
          <p>
            <a href="#!" onClick={resend}>
              Reenviar correo de confirmación
            </a>
          </p>
          <br />
          <p>
          Te recomendamos revisar la <strong>bandeja de entrada, spam o correo no deseado</strong> desde tu correo electrónico.
          </p>
        </div>
      ),
      okText: "Entendido",
    });
  }

  function sucess() {
    Modal.success({
      title: "Creación de cuenta",
      icon: false,
      okButtonProps: { style: { display: "block", width: "100%" } },
      content: (
        <div>
          <br />
          <span style={{ textAlign: "center" }}>
            <Icon
              type="check-circle"
              style={{
                color: "#E149A4",
                fontSize: "30px",
                textAlign: "center",
                display: "block",
              }}
            />
          </span>
          <br />
          <p>Enviamos un correo para validar tu cuenta.</p>
          <p>
            ¿No te ha llegado el correo?
            <a href="#!" onClick={resend}>
              Reenviar correo.
            </a>
          </p>
          <br />
          <p>
          Te recomendamos revisar la <strong>bandeja de entrada, spam o correo no deseado</strong> desde tu correo electrónico.
          </p>
        </div>
      ),
      okText: "Entendido",
    });
  }

  function sucessEmail() {
    email = "";
    Modal.success({
      title: "Reenvio de correo",
      content: (
        <div>
          <span style={{ textAlign: "center" }}>
            <Icon type="check-circle" />
          </span>
          <p>Enviamos un correo para validar tu cuenta</p>
          <br />
          <p>
          Te recomendamos revisar la <strong>bandeja de entrada, spam o correo no deseado</strong> desde tu correo electrónico.
          </p>
        </div>
      ),
      okText: "Ok",
    });
  }

  //Función de reenvio del correo
  const resend = (e) => {
    e.preventDefault();
    if (email !== "") {
      const url = `${basePath}/resendEmail`;
      axios.post(url, { correo: email }).then((response) => {
        const { code } = response.data;
        if (code === 400 || code === 500) {
          //Modal con el error
          const mensaje = response.data.causa;
          error(mensaje);
          email = "";
        } else {
          //const mensaje = response.data.message;
          sucessEmail();
          email = "";
        }
      });
    }
  };

  //Función para procesar los datos del registro.
  const register = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setBotonDeshabilitado(true);
        const url = `${basePath}/registrarCiudadano`;
        axios.post(url, values).then((response) => {
          const { code } = response.data;
          if (code === 400 || code === 500) {
            //Modal con el error
            const mensaje = response.data.causa;
            const status = response.data.status;
            if (status === "Cuenta Registrada Activa") {
              errorExistenteActivo(mensaje);
            } else if (status === "Cuenta Registrada No Activa") {
              email = mensaje;
              errorExistenteNoActivo();
            } else {
              error(mensaje);
            }
            setVisibleRegister(false);
          } else {
            const respuesta = response.data.cuenta.correoElectronico;
            email = respuesta;
            setVisibleRegister(false);
            sucess();
          }
        });
      }
    });
  };

  return (
    <>
      <Form onSubmit={register}>
        <Form.Item label="Correo electrónico" hasFeedback>
          {getFieldDecorator("email", {
            rules: [
              {
                type: "email",
                message: "El correo no es válido.",
              },
              { required: true, message: "Dato requerido." },
              {
                validator: validateNextEmail,
              },
            ],
          })(<Input autoComplete="off" placeholder="nombre@dominio.com" style={{ textTransform: "lowercase"}} />)}
        </Form.Item>
        <Form.Item label="Confirma tu correo electrónico" hasFeedback>
          {getFieldDecorator("confirm", {
            rules: [
              {
                type: "email",
                message: "El correo no es válido.",
              },
              { required: true, message: "Dato requerido" },
              {
                validator: compareToFirstEmail,
              },
            ],
          })(<Input autoComplete="off" placeholder="Confirmar correo" style={{ textTransform: "lowercase"}} />)}
        </Form.Item>
        <Form.Item label="Contraseña" hasFeedback>
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "Dato Requerido" },
              { validator: validateNextPassword },
              {
                message: "Al menos 6 caracteres y un número",
                pattern: /^(?=.*\d)[0-9a-zA-Z]{6,}$/,
              },
            ],
          })(
            <Input.Password placeholder="Al menos 6 caracteres y un número" />
          )}
        </Form.Item>
        <Form.Item label="Confirma tu contraseña" hasFeedback>
          {getFieldDecorator("confirmPass", {
            rules: [
              { required: true, message: "Dato Requerido" },
              { validator: compareToFirstPassword },
            ],
          })(<Input.Password placeholder="Confirmar contraseña" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("acuerdo", {
            rules: [
              {
                required: true,
                message: "Debes aceptar el aviso de privacidad!",
              },
            ],
            valuePropName: "checked",
          })(
            <Checkbox>
              Acepto el{" "}
              <a
                href="https://www.ine.mx/wp-content/uploads/2020/10/deoe-observadores-web-avisoprivacidadintegral.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                aviso de privacidad
              </a>
              {" "}y que sean enviadas notificaciones al correo electrónico que he proporcionado
            </Checkbox>
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }} disabled={botonDeshabilitado}>
            Regístrate
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const registerForm = Form.create()(Signup);
export default registerForm;
