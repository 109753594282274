import React from "react";
import { Icon, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { saveAs } from 'file-saver'

const descargaGuia = () => {
  saveAs(process.env.PUBLIC_URL + "/materiales_cp/GOCP2021.pdf", "Guía Observadores Consulta Popular 2021.pdf");
}

const descargaLeccGeneral = () => {
  saveAs(process.env.PUBLIC_URL + "/materiales_cp/OCP2021.pptx", "Material para capacitar a observadoras y observadores de la Consulta Popular 2021.");
}

const ContentMaterials = () => {
  return (
    /*<div style={{padding: "20px"}}>*/
    <div className="interioresPortal">
      <Link to={"/"} className="back">
        <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
      </Link>
      <Row>
        <h1 style={{marginBottom: "5px"}}>Materiales de Capacitación</h1>
        <hr></hr>
      </Row>
      <Row style={{ marginTop:"40px" }}>
        <Col>
          <p>
            Aquí encontrarás los materiales de capacitación necesarios para la Consulta Popular.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop:"40px" }}>
        <Col>
          <h2>Guía temática para capacitar a las y los observadores de la Consulta Popular.</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <button onClick={descargaGuia}>
            <Icon type="download" /> Guía temática para capacitar a las y los observadores de la Consulta Popular
          </button>
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col>
          <h2>Material para capacitar a observadoras y observadores de la Consulta Popular 2021.</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <button onClick={descargaLeccGeneral}>
            <Icon type="download" /> Material para capacitar a observadoras y observadores de la Consulta Popular 2021
          </button>
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col>
          <h2>Vídeo: Conoce en que consiste la Consulta Popular del próximo 1 de agosto.</h2>
        </Col>
      </Row>
      <Row>
        <Col>
        <dl className="matApoyo"><a rel="noopener noreferrer" style={{textDecorationLine: "underline"}} target="_blank" href={"https://youtu.be/V1TuNfO12c0"}>Vídeo: La Consulta Popular 2021</a></dl>
        </Col>
      </Row>
    </div>
  );
};

export default ContentMaterials;
