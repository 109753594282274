import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form, Row, Col, message, DatePicker, Button, Alert, Icon, Input, Checkbox, Tooltip, Upload } from "antd";
import useAuth from "../../../hooks/useAuth";
import moment from 'moment';
import { rol_captura, rol_cau } from "../../../utils/Accreditation/roles";
import { required, inputs } from "../../../utils/Accreditation/rules";
import { Ast, beforeUpload, getBase64, calulateNewHeight, uploadButton, sendAccreditation } from "../../../utils/Accreditation/functions";
import { ID_PUESTO_PRESIDENTE, ID_PUESTO_SECRETARIO } from "../../../utils/constanst";
import { getFirmas, getSolicitudesAcreditables } from "../../../api/accreditation";
import TableAcreditables from "./TableAcreditables";
import { URL_IMAGEN } from "../../../api/config";
import Compressor from "compressorjs";
import "./Accreditation.scss";
import { descargaExcel } from "./xls";

function Accreditation(props) {
    const { proceso, setReload, setLoader, setSaved } = props;
    const { user: { user, rolUsuario } } = useAuth();
    const dateFormat = 'DD/MM/YYYY';
    const hourFormat = 'HH:mm';
    const { getFieldDecorator } = props.form;
    const [disabled, setDisabled] = useState(false);
    const [button, setButton] = useState(false);
    const [sistema, setSistema] = useState({ fecha: "DD/MM/YYYY", hora: "HH:mm" });
    const [imagePre, setImagePreUrl] = useState({ loading: false });
    const [imageSe, setImageSeUrl] = useState({ loading: false });
    const [register, setRegister] = useState({});
    const [data, setData] = useState([]); // Lista con la información de las solicitudes por acreditar
    const [juntaFirma, setJuntaFirma] = useState({ presidente: "Vocal Ejecutivo/a de la Junta Ejecutiva Distrital", secretario: "Vocal Secretario/a de la Junta Ejecutiva Distrital" });

    useEffect(() => {
        if (proceso.distrito !== undefined && proceso.distrito !== null) {
            if (!rol_captura.includes(rolUsuario)) {
                setReload(true);
            }
            // Validamos si existen las firmas de ésta junta ejecutiva
            getFirmasJunta();
            // verificamos si el texto es correcto
            if (proceso.distrito.idDistrito === 0) {
                setJuntaFirma({ presidente: "Vocal Ejecutivo/a de la Junta Ejecutiva Local", secretario: "Vocal Secretario/a de la Junta Ejecutiva Local" });
            }
        }   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proceso]);

    function getFirmasJunta() {
        getFirmas(proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
            if (res.data.firmasJuntaEjecutiva !== undefined && res.data.firmasJuntaEjecutiva !== null) {
                for (let i = 0; i < res.data.firmasJuntaEjecutiva.length; i++) {
                    if (res.data.firmasJuntaEjecutiva[i].urlImagen !== null) {
                        switch ( res.data.firmasJuntaEjecutiva[i].idPuesto ) {
                            case ID_PUESTO_PRESIDENTE:
                              setImagePreUrl({
                                imageUrl:
                                  "data:image/png;base64," +
                                  res.data.firmasJuntaEjecutiva[i].urlImagen,
                              });
                            break;
                            case ID_PUESTO_SECRETARIO:
                              setImageSeUrl({
                                imageUrl:
                                  "data:image/png;base64," +
                                  res.data.firmasJuntaEjecutiva[i].urlImagen,
                              });
                            break;
                            default:
                              break;
                        }
                    }
                }
            }
        });
    }

    const addChanges = (event) => {
        event.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                let sinMail = 0;
                const datos = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i].seleccionado) {
                        if (!(data[i].correo !== undefined && data[i].correo !== null)) {
                            sinMail = sinMail + 1;
                        }
                        datos.push(data[i]);
                    }
                }
                if (datos.length > 0) {
                    values.usuario = user.sub;
                    values.idProcesoElectoral = proceso.proceso.idProcesoElectoral;
                    values.idDetalleProceso = proceso.proceso.idDetalleProceso;
                    values.idEstadoDistrito = proceso.estado.idEstado;
                    values.idDistritoFederal = proceso.distrito.idDistrito;
                    values.fechaConsulta = moment(`${sistema.fecha} ${sistema.hora}`, 'DD/MM/YYYY HH:mm');
                    values.sinCorreo = sinMail;
                    values.totalAcreditadas = datos.length;
                    values.listaAcreditados = datos;

                    if (!rol_cau.includes(rolUsuario)) {
                        if (imagePre.imageUrl !== undefined && imageSe.imageUrl !== undefined) {
                            sendAccreditation(values, register.presidente, register.secretario, setLoader, setReload, setSaved);
                        } else {
                            message.info("Para acreditar las solicitudes, se requiere cargar los archivos de firmas.");
                        }                        
                    }                  
                } else {
                    message.info("Se requiere seleccionar al menos una solicitud para continuar la acreditación.");
                }
            } else {
                message.info("Los datos con ( * ) son requeridos.");
            }
        });
    };

    function consultaListado() {
        props.form.validateFields((err, values) => {
            if (values.todasSolicitudes) {
                props.form.setFieldsValue({ fechaInicial: null });
                props.form.setFieldsValue({ fechaFinal: null });
                getSolicitudesAcreditables(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
                    proceso.estado.idEstado, proceso.distrito.idDistrito, null, null, values.todasSolicitudes).then((res) => {
                        if (res.data.solicitudesAcreditables !== null && res.data.solicitudesAcreditables.length > 0) {
                            const dataSource = [];
                            res.data.solicitudesAcreditables.map((reg, index) => {
                                return dataSource.push({
                                key: index,
                                indice: index + 1,
                                ...reg,
                                });
                            }); 
                            setData(dataSource);
                            setSistema({ fecha: moment().format(dateFormat), hora: moment().format(hourFormat) });
                            setDisabled(true);
                        } else {
                            message.info("No existen solicitudes pendientes por acreditar.");
                        }                     
                });
            } else {
                if (values.fechaInicial !== undefined && values.fechaFinal !== undefined) {
                    if (values.fechaInicial > values.fechaFinal) {
                        message.info( "La fecha inicial tiene que ser menor al fecha final del curso." );
                    } else {
                        getSolicitudesAcreditables(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
                            proceso.estado.idEstado, proceso.distrito.idDistrito, values.fechaInicial.format(dateFormat), values.fechaFinal.format(dateFormat), values.todasSolicitudes).then((res) => {
                                if (res.data.solicitudesAcreditables !== null && res.data.solicitudesAcreditables.length > 0) {
                                    const dataSource = [];
                                    res.data.solicitudesAcreditables.map((reg, index) => {
                                        return dataSource.push({
                                        key: index,
                                        indice: index + 1,
                                        ...reg,
                                        });
                                    }); 
                                    setData(dataSource);
                                    setSistema({ fecha: moment().format(dateFormat), hora: moment().format(hourFormat) });
                                    setDisabled(true);
                                } else {
                                    message.info("No existen solicitudes pendientes por acreditar dentro de las fechas seleccionadas.");
                                }                     
                        });
                    }
                } else {
                    message.info("Es necesario indicar las fechas para realizar la consulta.");               
                }
            }
        });
    }

    const downloadExcel = () => {
        descargaExcel("Solicitudes", "Reporte de Solicitudes por Acreditar", data, proceso, sistema);
    }

    //Función para comprimir la imagen
    const callbackFunction = (fileObj, name) => {
    let anchuraNueva = 1170;
    let result = new Promise((resolve, reject) => {
      let img = new Image();
      img.src = window.URL.createObjectURL(fileObj);
      img.onload = () => {
        //calcular nueva altura sin perder la relación de aspecto
        const newHeight = calulateNewHeight(
          img.width,
          img.height,
          anchuraNueva
        );
        new Compressor(fileObj, {
          width: anchuraNueva,
          height: newHeight,
          quality: 0.68,
          success(result) {
            resolve(result);
          },
          error(err) {
            console.log(err);
            reject(err);
          },
        });
      };
    });
    result
      .then((value) => {
        //Se obtien el archivo
        switch (name) {
          case ID_PUESTO_PRESIDENTE:
            setRegister({ ...register, presidente: new File([value], value.name) }); 
            setButton(false);
            break;
          case ID_PUESTO_SECRETARIO:
            setRegister({ ...register, secretario: new File([value], value.name) });
            setButton(false);
            break;
          default:
            break;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    };

    //Se carga el input de la imagen [PRESIDENTE]
    const handleChangePre = (info) => {
        if (info.file.status === "uploading") {
        setButton(true);
        setImagePreUrl({ loading: true });
        return;
        }
        if (info.file.status === "error") {
            setButton(false);    
            setImagePreUrl({ loading: false });
            message.error("Ocurrió un error al adjuntar el archivo, favor de volver a intentar.");
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imageUrl) =>
                setImagePreUrl({
                imageUrl,
                loading: false,
                })
            );
            setButton(true);
            callbackFunction(info.file.originFileObj, ID_PUESTO_PRESIDENTE); 
        }
    };

    //Se carga el input de la imagen [SECRETARIO]
    const handleChangeSe = (info) => {
        if (info.file.status === "uploading") {
        setButton(true);
        setImageSeUrl({ loading: true });
        return;
        }
        if (info.file.status === "error") {
            setButton(false);    
            setImageSeUrl({ loading: false });
            message.error("Ocurrió un error al adjuntar el archivo, favor de volver a intentar.");
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imageUrl) =>
            setImageSeUrl({ imageUrl, loading: false, }));
            setButton(true);
            callbackFunction(info.file.originFileObj, ID_PUESTO_SECRETARIO); 
        }
    };
    
    ////////////////////////////////////// ---- FORMULARIO ---- //////////////////////////////////////////////////////////////

    function DatosAcreditacion() {
        return (
        <div>
            <Row className="alertSpace">
                <Col span={24}>
                    <Alert message={<p style={{ marginBottom: "0px" }}>
                        Información consultada el {sistema.fecha} a las {sistema.hora} hrs.
                    </p>} type="info" showIcon />
                </Col>
            </Row>
            <Row className="rowSpace2">
                <Col span={24}>
                    <strong>Descarga el listado: </strong>
                    <Button onClick={() => downloadExcel()} className="btn_descarga_link" type="link" >
                        <Icon type="file-excel" />Excel
                    </Button>
                </Col>
            </Row>
            <Row className="rowSpace">
                <Col span={24}>
                    <h3>Lista de solicitudes sin acreditar</h3>
                </Col>
                <Col span={24}>
                    <TableAcreditables           
                        data={data}
                        className="-striped -highlight"
                        locale={{ emptyText: "Sin registros" }}
                        pagination={{ pageSize: 10 }}
                    />
                </Col>
            </Row>
            <Row className="rowSpace3">
                <Col span={24}>
                    <h3>Datos de la sesión</h3>
                </Col>
            </Row>
            <Row>
                <Col span={12} style={{ paddingRight: "20px" }}>
                <Ast /> Número de sesión
                    <Tooltip title="Formato 00/ORD/MM-AAAA">
                    <Form.Item>
                        {getFieldDecorator("numSesion", { rules: inputs })(
                            <Input maxLength={20} />
                         )}
                    </Form.Item>
                    </Tooltip>
                </Col>
                <Col span={12} style={{ paddingRight: "20px" }}>
                <Ast /> Número de acuerdo
                    <Form.Item>
                        {getFieldDecorator("numAcuerdo", { rules: inputs })(
                        <Input maxLength={30} /> )}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                <Ast /> Fecha de la sesión
                    <Form.Item>
                        {getFieldDecorator("fechaSesion", { rules: required })(
                            <DatePicker format={dateFormat} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Ast /> <strong>Fecha de acreditación de las solicitudes</strong>
                    <Form.Item>
                        {getFieldDecorator("fechaAcreditacion", { rules: required })(
                            <DatePicker format={dateFormat} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row className="rowSpace3">
                <Col span={24}>
                    <h3>Firma de los documentos</h3>
                </Col>
                <Col span={24}>
                    <Alert message={<p style={{ marginBottom: "0px" }}>
                        Archivos en formato jpeg o png que no excedan los 5MB por archivo.
                    </p>} type="info" showIcon />
                </Col>
            </Row>
            <Row className="alertSpace">
                <Col span={12}>
                    <Ast /> {juntaFirma.presidente}
                    <Form.Item style={{ marginBottom: "0px" }}>
                        <Upload
                        name="presidente"
                        listType="picture-card"
                        className="avatar-uploader"
                        action={URL_IMAGEN}
                        beforeUpload={beforeUpload}
                        onChange={handleChangePre}
                        showUploadList={false}
                        accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                        >
                        {imagePre.imageUrl ? (
                            <img
                            src={imagePre.imageUrl}
                            alt="presidente"
                            style={{ width: "100%" }}
                            />
                        ) : (
                            uploadButton(imagePre.loading)
                        )}
                        </Upload>
                    </Form.Item>
                    {!disabled.input &&  <div style={{ marginBottom: 15 }}>
                        <Icon type="info-circle" style={{ color: "#d5007f" }} />{" "}
                        <small>Pulsa sobre una imagen para reemplazarla</small>
                    </div>}
                </Col>
                <Col span={12}>
                    <Ast /> {juntaFirma.secretario}
                    <Form.Item style={{ marginBottom: "0px" }}>
                        <Upload
                        name="secretario"
                        listType="picture-card"
                        className="avatar-uploader"
                        action={URL_IMAGEN}
                        beforeUpload={beforeUpload}
                        onChange={handleChangeSe}
                        showUploadList={false}
                        accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                        >
                        {imageSe.imageUrl ? (
                            <img
                            src={imageSe.imageUrl}
                            alt="secretario"
                            style={{ width: "100%" }}
                            />
                        ) : (
                            uploadButton(imageSe.loading)
                        )}
                        </Upload>
                    </Form.Item>
                    {!disabled.input &&  <div style={{ marginBottom: 15 }}>
                        <Icon type="info-circle" style={{ color: "#d5007f" }} />{" "}
                        <small>Pulsa sobre una imagen para reemplazarla</small>
                    </div>}
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ paddingTop: "20px" }}>
                    <Form.Item style={{ textAlign: "center" }}>
                        <Button type="primary" htmlType="submit" disabled={button} style={{ width: 300 }} onClick={() => {}} >
                            Acreditar solicitudes seleccionadas
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </div>
        );  
    };

    return (
        <div>
            <Form onSubmit={addChanges}>
                <Row>
                    <Col span={24}>
                        <h3>Mostrar solicitudes con asistencia a curso</h3>
                    </Col>
                </Row>
                <Row className="rowSpace">
                    <Col span={4} style={{ paddingRight: "20px" }}>
                        <Ast /> Desde
                        <Form.Item>
                            {getFieldDecorator("fechaInicial")(
                                <DatePicker format={dateFormat} disabled={disabled} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ paddingRight: "20px" }}>
                        <Ast /> Hasta
                        <Form.Item>
                            {getFieldDecorator("fechaFinal")(
                                <DatePicker format={dateFormat} disabled={disabled} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={5} style={{ paddingRight: "20px" }}>
                        <Form.Item>
                            {getFieldDecorator("todasSolicitudes", { valuePropName: "checked", initialValue: false, })(
                                <Checkbox style={{marginTop:"20px"}}>
                                Todas las solicitudes
                                </Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="rowSpace">
                    <Col span={4}>
                        <Button onClick={() => consultaListado()} disabled={disabled} style={{ width: 200 }} >
                            Generar listado</Button>
                    </Col>
                </Row>
                { disabled ? DatosAcreditacion() : null }
            </Form>                   
        </div>
    );
}
const AccreditationForm = Form.create()(Accreditation);
export default withRouter(AccreditationForm);