import React, { useEffect } from "react";
import { Col, Row } from "antd";
import "../../CourseOnline/Courses.scss";

/**
 * Pantalla 
 * Componentes que muestra la pantalla de encuesta de valoración del curso 
 */
export default function Encuesta(props) {
  const row = 18;

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
  }, []);

  return (
    <>
      <Row gutter={24} className="material">
        <Col span={row} xxl={row} xl={row} lg={row} md={row} sm={24} xs={24}>
          <div className="texto_parrafo">
            <h1>Cuestionario de valoración del curso</h1>
            <p>
              ¡Te felicitamos por haber concluido tu capacitación y te deseamos el mejor de los éxitos 
              en el desempeño de tus funciones!
            </p>
            <p>
              Antes de finalizar queremos conocer tu opinión del curso, con el objetivo de realizar los 
              cambios necesarios que nos lleven a una mejora progresiva en los siguientes cursos en línea.
            </p>
            <p>
              ¡Gracias por tu colaboración!
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
}
