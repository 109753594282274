import React from "react";
import { Modal, message, Icon } from "antd";
import { saveRequest, guardarOCP} from "../../api/requestPub";
import moment from "moment";
import { MSN_GUARDAR } from "../constanst";

//Función que muestra el alert de confirmación
export function ModalConfirm(data, file, history, setDisabled, setLoader) {
  const { confirm } = Modal;
  confirm({
    title: null,
    content: content(),
    icon: null,
    keyboard: false,
    okText: "Enviar a revisión",
    cancelText: "Revisar datos",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
      save(data, file, history, setDisabled, setLoader); //Se envia la información
    },
    onCancel() {
      setDisabled({ btnSave: false });
    },
  });
}
//Función que envia la información
function save(data, file, history, setDisabled, setLoader) {
  //Se mete la información del formulario en formData para enviarlo con los archivos
  const formData = new FormData();
  formData.append("credAnverso", file.credAnverso);
  formData.append("credReverso", file.credReverso);
  formData.append("foto", file.foto);
  formData.append(
    "solicitud",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  saveRequest(formData)
    .then((res) => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          title: "Estado solicitud",
          content: <p>{res.data.message}</p>,
          okText: "Aceptar",
          keyboard: false,
          onOk() {
            history.push("/ciudadano/inicio"); //Redirecciona a la pantalla principal
          },
        });
      } else {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.warning({
          title: "Atención",
          content: <p>{res.data.causa}</p>,
          keyboard: false,
          onOk() {
            setDisabled({ btnSave: false });
          },
        });
      }
    })
    .catch((err) => {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.warning({
        title: "Error",
        content: "Ocurrió un error, intentelo más tarde.",
        keyboard: false,
        onOk() {
          setDisabled({ btnSave: false });
        },
      });
    });
}

// Envio de informacion Ratificacion
//Función que envia la información
function saveRatificacion(datos, history, setDisabled, setLoader) {
  guardarOCP(datos).then((res) => {
    if (res.data.code === 200) {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.success({
        title: "Estado solicitud",
        content: <p>{res.data.message}</p>,
        okText: "Aceptar",
        keyboard: false,
        onOk() {
          history.push("/ciudadano/inicio"); //Redirecciona a la pantalla principal
        },
      });
    } else {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.warning({
        title: "Atención",
        content: <p>{res.data.causa}</p>,
        keyboard: false,
        onOk() {
          setDisabled({ btnSave: false });
        },
      });
    }
  }).catch((err) => {
    setLoader({ loading: false, message: "" }); //Se desactiva el loader
    Modal.warning({
      title: "Error",
      content: "Ocurrió un error, intentelo más tarde.",
      keyboard: false,
      onOk() {
        setDisabled({ btnSave: false });
      },
    });
  });
}

//Contenido del alert de confirmación
const content = () => {
  return (
    <div>
      <p>
        Tus datos capturados serán revisados, el resultado se te notificará
        mediante el portal y por correo electrónico.
      </p>
    </div>
  );
};

//Contenido del alert de confirmación
const contentRatificacion = () => {
  return (
    <div>
      <p>
      Estás a punto de enviar tus datos de ratificación como Observador/a para la Consulta Popular.
      </p>
    </div>
  );
};

//Función que valida el tipo de imagen y tamaño.
export function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Solo archivos en formato JPG/PNG!");
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M || isLt2M === 0) {
    message.error("¡Peso máximo de 5MB!");
    return false;
  }
  const nameFile = validateName(file.name);
  if (!nameFile) {
    message.error(
      "El nombre de archivo " +
        file.name +
        " es inválido. El nombre del documento no debe contener caracteres especiales."
    );
    return false;
  }
  return isJpgOrPng && isLt2M && nameFile;
}
//Función que valida el nombre de las imagenes
function validateName(e) {
  let patron = /^[0-9a-zA-Z_-\s]+.[jpg/JPG/jpeg/JPEG/png/PNG]+$/;
  if (e.match(patron)) {
    return true;
  } else {
    return false;
  }
}

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const uploadButton = (loading) => (
  <>
    <Icon type={loading ? "loading" : "plus"} />
    <div className="ant-upload-text">Cargar</div>
  </>
);

export const Ast = () => {
  return <span style={{ color: "#f5222d" }}>*</span>;
};

export function valueUpperCase(e, previo) {
  const reg = /^[a-zA-Z0-9]*$/i;
  // console.log(reg.test(e.target.value));
  // console.log(previo,"--");
  // return reg.test(e.target.value) ? e.target.value.toUpperCase() : previo;

  return reg.test(e.target.value) ? e.target.value.toUpperCase() : previo;
}

export function lastName(apellido, apellido2) {
  if (apellido || apellido2) {
    return true;
  }
  return false;
}

export function generaFolio(procesoElectoral) {
  let folio = "CP";
/*   if (procesoElectoral.ambitoDetalle === "L") {
    folio = folio + "L";
  } else {
    folio = folio + "F";
  }
  if (procesoElectoral.tipoProceso === "E") {
    folio = folio.slice(0, -1) + "E";
  }
  folio = folio + procesoElectoral.anio.toString().substring(2, 4); */
  return folio;
}
export const REG_CODIGO = /^[a-zA-Z0-9]*$/i;

export function adress(adress) {
  if (adress) {
    let junta = adress.split("|");
    return junta[2];
  }
  return "";
}

export function disabledDate(current) {
  return current && current > moment().endOf("day") && moment().endOf("year");
}

//Función que muestra el alert de confirmación
export function ModalPreRegister(datos, history, setDisabled, setLoader) {
  const { confirm } = Modal;
  confirm({
    title: null,
    content: contentRatificacion(),
    icon: null,
    keyboard: false,
    okText: "Enviar",
    cancelText: "Revisar datos",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
      saveRatificacion(datos, history, setDisabled, setLoader); //Se envia la información
    },
    onCancel() {
      setDisabled({ btnSave: false });
    },
  });
}