import { Button, Col, Icon, Row } from "antd";
import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import introImg from "../../../../assets/img/CourseOnline/cursoIntro.png";
import useAuth from "../../../../hooks/useAuth";

function CourseDescription(props) {
    //const { history, setLoader } = props;
    const { constancia } = props.location;
    //Se obtiene la información del ciudadano
    const {
        ciudadano: { ciudadano },
    } = useAuth();

    useEffect(() => {
        if(ciudadano) {
            window.scrollTo(0, 0); // para posicionar el scroll al inicio
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div className="interioresPortal">
            <Link to={"/ciudadano/inicio"} className="back">
                <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
            </Link>
            <h1 style={{ marginTop: 30, marginBottom: 5 }}>Capacitación en línea</h1>
            <hr />
            <Row gutter={24} className="texto_parrafo" style={{ marginTop:"40px" }}>
                <Col span={18} xxl={18} xl={18} lg={18} md={18} sm={24} xs={24}>
                    <img src={introImg} alt="img" className="imagen" style={{marginLeft: "0px", marginBottom:"25px"}} />
                    
                    <h2>Curso virtual: La observación en la Consulta Popular</h2>
                    <p>
                        En esta capacitación podrás conocer el marco general sobre la
                        Consulta Popular.
                    </p>
                    {/* <p>
                        Podrás realizar las actividades desde tu computadora, laptop,
                        tableta o celular y avanzar a tu propio ritmo, tomar descansos y
                        retomar el curso en la lección donde te quedaste.
                    </p> */}
                    <p>
                        El curso de capacitación es un requisito que forma parte del
                        procedimiento para poder obtener tu acreditación como observadora
                        u observador de la Consulta Popular.
                    </p>
                </Col>
            </Row>
            <Row gutter={24} className="texto_parrafo" style={{ marginTop:"40px" }}>
                <Col span={18} xxl={18} xl={18} lg={18} md={18} sm={24} xs={24}>
                    <h2>Modo de evaluación</h2>
                    <p>
                        Al finalizar el curso habrá una actividad de autoevaluación, 
                        la cual deberás contestar para obtener tu constancia de capacitación. 
                        Podrás responder las veces que consideres necesarias pues está 
                        diseñada para reforzar tu aprendizaje.
                    </p>
                    <p>
                        Deberás obtener una calificación mínima de 7 para obtener tu constancia.
                    </p>
                </Col>
            </Row>
            <Row style={{ marginTop:"40px" }}>
                <Link to={{ pathname: "/ciudadano/capacitacionCP/curso", constancia: constancia }}>
                    <Button>
                        Ir al curso
                    </Button>
                </Link>
            </Row>
        </div>
    );

}

export default withRouter(CourseDescription);