import React from "react";
import { Tabs, Row } from "antd";

const TabsNor = () => {
  const { TabPane } = Tabs;
  function callback(key) {}
  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="LGIPE" key="1">
        <Row>
          <h4>Artículo 8</h4>
          <p>
            1. Es derecho exclusivo de los ciudadanos participar como
            observadores de los actos de preparación y desarrollo de los
            procesos electorales federales y locales, así como en las consultas
            populares y demás formas de participación ciudadana que se realicen
            de conformidad con la legislación correspondiente, en la forma y
            términos que determine el Consejo General, y en los términos
            previstos en esta Ley.
          </p>
          <h4>Artículo 68</h4>
          <p>
            e) Acreditar a los ciudadanos mexicanos, o a la agrupación a la que
            pertenezcan, que hayan presentado su solicitud ante el presidente
            del propio consejo local para participar como observadores durante
           la consulta popular, conforme al inciso c) del párrafo 1 del
            artículo 217 de esta Ley.
          </p>
          <h4>Artículo 70</h4>
          <p>
            c) Recibir las solicitudes de acreditación que presenten los
            ciudadanos mexicanos o las agrupaciones a las que pertenezcan, para
            participar como observadores durante la consulta popular.
          </p>
          <h4>Artículo 79</h4>
          <p>
            g) Acreditar a los ciudadanos mexicanos, o a la organización a la
            que pertenezcan, que hayan presentado su solicitud ante el
            presidente del propio consejo distrital para participar como
            observadores durante la consulta popular, conforme al inciso c) del
            párrafo 1 del artículo 217 de esta Ley;
          </p>
          <h4>Artículo 217</h4>
          <p>
            1. Los ciudadanos que deseen ejercitar su derecho como observadores
            electorales deberán sujetarse a las bases siguientes:
          </p>
          <ul>
            <li>
              a) Podrán participar sólo cuando hayan obtenido oportunamente su
              acreditación ante la autoridad electoral;
            </li>
            <li>
              b) Los ciudadanos que pretendan actuar como observadores deberán
              señalar en el escrito de solicitud los datos de identificación
              personal anexando fotocopia de su credencial para votar, y la
              manifestación expresa de que se conducirán conforme a los
              principios de imparcialidad, objetividad, certeza y legalidad y
              sin vínculos a partido u organización política alguna;
            </li>
            <li>
              c) La solicitud de registro para participar como observadores
              electorales, podrá presentarse en forma personal o a través de la
              organización a la que pertenezcan, ante el presidente del consejo
              local o distrital correspondiente a su domicilio, a partir del
              inicio de la consulta popular y hasta el 23 de julio del año de la
              elección. Los presidentes de los consejos locales y distritales,
              según el caso, darán cuenta de las solicitudes a los propios
              consejos, para su aprobación, en la siguiente sesión que celebren.
              La resolución que se emita deberá ser notificada a los
              solicitantes. El Consejo General y los Organismos Públicos Locales
              garantizarán este derecho y resolverán cualquier planteamiento que
              pudiera presentarse por parte de los ciudadanos o las
              organizaciones interesadas;
            </li>
            <li>
              d) Sólo se otorgará la acreditación a quien cumpla, además de los
              que señale la autoridad electoral, los siguientes requisitos;
            </li>
            <ul>
              <li>
                I. Ser ciudadano mexicano en pleno goce de sus derechos civiles
                y políticos;
              </li>
              <li>
                II. No ser, ni haber sido miembro de dirigencias nacionales,
                estatales o municipales de organización o de partido político
                alguno en los tres años anteriores a la elección;
              </li>
              <li>
                III. No ser, ni haber sido candidato a puesto de elección
                popular en los tres años anteriores a la elección, y
              </li>
              <li>
                IV. Asistir a los cursos de capacitación, preparación o
                información que impartan el Instituto y los Organismos Públicos
                Locales o las propias organizaciones a las que pertenezcan los
                observadores electorales bajo los lineamientos y contenidos que
                dicten las autoridades competentes del Instituto, las que podrán
                supervisar dichos cursos. La falta de supervisión no imputable a
                la organización respectiva no será causa para que se niegue la
                acreditación;
              </li>
            </ul>
            <li>e) Los observadores se abstendrán de:</li>
            <ul>
              <li>
                I. Sustituir u obstaculizar a las autoridades electorales en el
                ejercicio de sus funciones, e interferir en el desarrollo de las
                mismas;
              </li>
              <li>
                II. Hacer proselitismo de cualquier tipo o manifestarse en favor
                de partido o candidato alguno;{" "}
              </li>
              <li>
                III. Externar cualquier expresión de ofensa, difamación o
                calumnia en contra de las instituciones, autoridades
                electorales, partidos políticos o candidatos, y
              </li>
              <li>
                IV. Declarar el triunfo de partido político o candidato alguno;
              </li>
            </ul>
            <li>
              f) La observación podrá realizarse en cualquier ámbito territorial
              de la República Mexicana;
            </li>
            <li>
              g) Los ciudadanos acreditados como observadores electorales podrán
              solicitar, ante la junta local y Organismos Públicos Locales que
              correspondan, la información electoral que requieran para el mejor
              desarrollo de sus actividades. Dicha información será
              proporcionada siempre que no sea reservada o confidencial en los
              términos fijados por la ley y que existan las posibilidades
              materiales y técnicas para su entrega;
            </li>
            <li>
              h) En los contenidos de la capacitación que el Instituto imparta a
              los funcionarios de las mesas directivas de casilla, debe preverse
              la explicación relativa a la presencia de los observadores
              electorales, así como los derechos y obligaciones inherentes a su
              actuación;
            </li>
            <li>
              i) Los observadores electorales podrán presentarse el día de la
              consulta popular con sus acreditaciones e identificaciones en una
              o varias casillas, así como en el local de los Consejos
              correspondientes, pudiendo observar los siguientes actos;
            </li>
            <ul>
              <li>I. Instalación de la casilla;</li>
              <li>II. Desarrollo de la votación;</li>
              <li>III. Escrutinio y cómputo de la votación en la casilla;</li>
              <li>
                IV. Fijación de resultados de la votación en el exterior de la
                casilla;
              </li>
              <li>V. Clausura de la casilla;</li>
              <li>
                VI. Lectura en voz alta de los resultados en el consejo
                distrital, y
              </li>
              <li>VII. Recepción de escritos de incidencias y protesta;</li>
            </ul>
            <li>
              j) Los observadores podrán presentar, ante la autoridad electoral,
              informe de sus actividades en los términos y tiempos que para tal
              efecto determine el Consejo General. En ningún caso, los informes,
              juicios, opiniones o conclusiones de los observadores tendrán
              efectos jurídicos sobre la consulta popular y sus resultados.
            </li>
          </ul>
          <p>
            2. Las organizaciones a las que pertenezcan los observadores
            electorales, a más tardar treinta días después de la jornada
            electoral, deberán declarar el origen, monto y aplicación del
            financiamiento que obtengan para el desarrollo de sus actividades
            relacionadas directamente con la observación electoral que realicen,
            mediante informe que presenten al Consejo General.{" "}
          </p>
        </Row>
      </TabPane>
      <TabPane tab="RE" key="2">
        <Row>
          <h4>Artículo 186</h4>
          <p>
            1 El Instituto y los OPL emitirán en la sesión inicial del proceso
            electoral, una convocatoria en la que se difundirán los requisitos
            para obtener la acreditación para la observación electoral, tomando
            en consideración los modelos que forman parte de este Reglamento
            (Anexo 6.6).
          </p>
          <p>
            2 El Instituto y el OPL proporcionarán los mecanismos necesarios
            para que la ciudadanía, con independencia de su lugar de residencia,
            obtenga la acreditación para la observación electoral, a través de
            las modalidades que el Instituto determine, el cual podrá incluir el
            uso de herramientas informáticas y tecnológicas.
          </p>
          <p>
            3 Quienes se encuentren acreditados como observadores electorales
            tendrán derecho a realizar las actividades de observación de los
            actos de carácter público de preparación y desarrollo de los
            procesos electorales federales y locales, tanto ordinarios como
            extraordinarios, así como de las consultas populares, incluyendo los
            que se lleven a cabo durante la consulta popular y sesiones de los
            órganos electorales del Instituto y de los OPL, en términos de lo
            establecido en la LGIPE y este Reglamento.
          </p>
          <p>
            4 La observación electoral podrá realizarse en cualquier ámbito
            territorial de la República Mexicana para el caso de las elecciones
            federales.
          </p>
          <p>
            5 La ciudadanía mexicana podrá participar en la observación
            electoral en términos de lo previsto en la LGIPE y este Reglamento,
            solo cuando hayan obtenido oportunamente su acreditación ante el
            Instituto; la cual surtirá efectos para el proceso federal y los
            concurrentes.
          </p>
          <p>
            6 En las elecciones locales, la ciudadana o el ciudadano deberá
            tomar el curso referente a esa entidad a fin de conocer las
            modificaciones sustantivas de la elección local que pretenda
            observar.
          </p>
          <h4>Artículo 189</h4>
          <p>
            1 La solicitud para obtener la acreditación para la observación del
            desarrollo de las actividades de los procesos electorales federales
            y locales, ordinarios y extraordinarios, se presentará
            preferentemente a través de las herramientas informáticas y
            tecnológicas que el Instituto implemente o, en su defecto, ante la
            presidencia del consejo local o distrital del Instituto o ante el
            órgano correspondiente del OPL.
          </p>
          <p>
            {" "}
            2 En elección federal o concurrente, los consejos locales y
            distritales deberán recibir las solicitudes de acreditación de la
            ciudadanía interesada en actuar como observadora electoral, sin
            menoscabo de donde se ubique el domicilio del solicitante o el de la
            organización a la que pertenezca; y deberán darle cauce, en términos
            de lo dispuesto por el artículo 217, numeral 1, incisos a), b), c) y
            d) de la LGIPE.
          </p>
          <p>
            3 En elecciones locales, las juntas locales y distritales ejecutivas
            sin Consulta Popular deberán recibir solicitudes de acreditación de
            la ciudadanía que desee participar en la observación electoral en
            entidades con Consulta Popular, y deberán darle cauce, en términos
            de lo dispuesto por el artículo 217, numeral 1, incisos a), b), c),
            y d) de la LGIPE; a fin de que el Consejo Local o Distrital
            correspondiente someta a consideración la aprobación de las
            solicitudes.
          </p>
          <p>
            4 Para el caso de las elecciones extraordinarias, la participación
            de las juntas ejecutivas o consejos se llevará a cabo de conformidad
            con los párrafos 2 y 3 del presente artículo, según corresponda.
          </p>
          <p>
            5 El Instituto, podrá recibir solicitudes de registro de la
            ciudadanía mexicana residente en el extranjero, interesada en
            participar en la observación electoral, y darles cauce en los
            términos dispuestos por el artículo 217, numeral 1, incisos a), b),
            c) y d) de la LGIPE.
          </p>
          <p>
            6 En elecciones concurrentes y locales, los OPL, podrán recibir
            solicitudes de registro de la ciudadanía mexicana residente en el
            extranjero, interesada en participar en la observación electoral, y
            darles cauce en los términos dispuestos por el artículo 217, numeral
            1, incisos a), b), c) y d) de la LGIPE.
          </p>
          <p>
            Las juntas ejecutivas y consejos del Instituto, así como los OPL
            deberán garantizar el debido resguardo de la información
            confidencial que reciban para el trámite de las solicitudes de
            acreditación, conforme a la normativa de la materia que se encuentre
            vigente.
          </p>
          <h4>Artículo 191</h4>
          <p>
            1 La vocalía de organización electoral de las juntas ejecutivas del
            Instituto, será responsable de procesar las solicitudes ciudadanas
            para su revisión.
          </p>
          <p>
            2 Para el caso de los OPL, los órganos directivos designarán a los
            funcionarios encargados de procesar las solicitudes que le entregue
            la ciudadanía y las organizaciones, con el objeto de verificar el
            cumplimiento de los requisitos necesarios para su acreditación.
          </p>
          <h4>Artículo 194</h4>
          <p>
            1 Los cursos de capacitación son responsabilidad del Instituto, de
            los OPL o de las propias organizaciones a las que pertenezcan los
            observadores electorales, en términos de lo previsto en el artículo
            217, numeral 1, inciso d), fracción IV de la LGIPE, y serán
            impartidos por funcionarios de la autoridad correspondiente.
          </p>
          <p>
            2 En el caso del Instituto, las vocalías de capacitación electoral y
            educación cívica de las juntas ejecutivas, tendrán a su cargo la
            impartición de los cursos de Capacitación, preparación o
            información.
          </p>
          <p>
            3 Para el caso de los OPL, los órganos directivos designarán a los
            funcionarios encargados de impartir los cursos. Asimismo, formarán
            un expediente por cada solicitante a efecto de remitirlo a la
            presidencia del consejo local del Instituto que corresponda, dentro
            de los tres días siguientes a la conclusión del curso, para que el
            consejo local resuelva sobre su acreditación.
          </p>
          <p>
            4 En elecciones extraordinarias, la remisión de los expedientes de
            cada una de las solicitudes a la presidencia del consejo local del
            Instituto que corresponda, se hará dentro de las veinticuatro horas
            siguientes, contadas a partir de la impartición del curso de
            capacitación, para que el consejo local resuelva lo conducente.
          </p>
        </Row>
      </TabPane>
    </Tabs>
  );
};

export default TabsNor;
