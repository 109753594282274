import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Input, Modal } from "antd";
import { rol_captura } from "../../../utils/Console/roles";
import { inputs, txtTexto, nombres, txtNombres } from "../../../utils/Console/rules";
import useAuth from "../../../hooks/useAuth";
import { withRouter } from "react-router-dom";
import "./Console.scss";
import {
  createJobCruce,
  getListaJobs,
  createJobCruceFinalInf,
  getResumen,
} from "../../../api/cruces";
//import {generarSolicitudes} from '../../../api/others';
import {enviarCorreosRatificacion} from '../../../api/others';
import Table from "../../../components/Admin/Console/Table";
import { MSN_GUARDAR } from "../../../utils/constanst";

function Console(props) {
  const [reload, setReload] = useState(false);
  const [listaJobs, setListaJobs] = useState({
    list: [],
    loading: false,
  });
  const [rolModulo, setRolModulo] = useState({
    captura: false,
  });
  const {
    user: { rolUsuario, infoMenu },
  } = useAuth();
  const {
    proceso: { proceso } , setLoader
  } = props; // , setLoader
  const { getFieldDecorator } = props.form; //Método para validar los inputs
  const [resumen, setResumen] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    //Valida los permisos que tienen el usuario
    let captura = false;
    //Devuelve un true si cuenta con los permisos
    captura = rol_captura.includes(rolUsuario);
    setRolModulo(() => ({ captura })); //Se almacenan en el hook
    setListaJobs({ loading: true }); //Se habilita el componente de loading
    getListaJobs()
      .then((res) => {
        const dataSource = res.data;
        setListaJobs({ list: dataSource, loading: false }); //Se almacenan en el hook
      })
      .catch((error) => {
        console.log(error);
      });
    setReload(false);
    //La función useEffect se ejecutará cada vez que una de estas variables cambie su valor - U
  }, [reload, rolUsuario, proceso, infoMenu.listaDetalles]);

  useEffect(() => {
    getResumen()
      .then((res) => {
        setResumen(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refresh]);

  //Función que crea una tarea de quartz
  const createJob = (event) => {
    event.preventDefault();
    if (proceso !== undefined && proceso !== null) {
      Modal.confirm({
        title: "Cruces de información",
        content: "¿Deseas programar esta tarea?",
        okText: "Crear",
        cancelText: "Cancelar",
        onOk() {
          sendJob(
            props.form.getFieldValue("nameJob"),
            props.form.getFieldValue("exprCron")
          );
        },
      });
    } else {
      Modal.error({
        content: "Selecciona una Consulta Popular.",
      });
    }
  };
  function sendJob(nameJob, exprCron) {
    createJobCruce(nameJob, exprCron).then((res) => {
      if (res.data.code === 200) {
        Modal.success({
          content: res.data.message,
          okText: "Aceptar",
          onOk() {
            setReload(true); //Para volver a carga la tabla
          },
        });
      } else {
        Modal.error({
          content: res.data.message,
        });
      }
    });
  }

  const createJobCruceFinal = (event) => {
    event.preventDefault();
    if (proceso !== undefined && proceso !== null) {
      Modal.confirm({
        title: "Cruces de información",
        content: "¿Deseas programar el cruce final de información?",
        okText: "Crear",
        cancelText: "Cancelar",
        onOk() {
          sendJobCruceFinal(
            props.form.getFieldValue("nameJobFinal"),
            props.form.getFieldValue("exprCronFinal")
          );
        },
      });
    } else {
      Modal.error({
        content: "Selecciona una Consulta Popular.",
      });
    }
  };

  function sendJobCruceFinal(nameJob, exprCron) {
    createJobCruceFinalInf(nameJob, exprCron).then((res) => {
      if (res.data.code === 200) {
        Modal.success({
          content: res.data.message,
          okText: "Aceptar",
          onOk() {
            setReload(true); //Para volver a carga la tabla
          },
        });
      } else {
        Modal.error({
          content: res.data.message,
        });
      }
    });
  }

  /*function generar() {
    setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
    generarLinks().then((res) => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: res.data.message,
          okText: "Aceptar",
          onOk() {
          },
        });
      } else {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: res.data.message,
        });
      }
    }).catch((error) => {
      console.log(error);
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: "Proceso finalizado, favor de validar. ",
        });
    });;
  }*/

  /*function generarRatificacion() {
    setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
    generarSolicitudes().then((res) => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: res.data.message,
          okText: "Aceptar",
          onOk() {
          },
        });
      } else {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: res.data.message,
        });
      }
    }).catch((error) => {
      console.log(error);
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: "Proceso finalizado, favor de validar. ",
        });
    });;
  }*/

  /*function enviarNotificaciones() {
    setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
    enviarCorreos().then((res) => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: res.data.message,
          okText: "Aceptar",
          onOk() {
          },
        });
      } else {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: res.data.message,
        });
      }
    }).catch((error) => {
      console.log(error);
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: "Proceso finalizado, favor de validar. ",
        });
    });;
  }*/

  function enviarNotificacionesRatificacion() {
    setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
    enviarCorreosRatificacion().then((res) => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: res.data.message,
          okText: "Aceptar",
          onOk() {
          },
        });
      } else {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: res.data.message,
        });
      }
    }).catch((error) => {
      console.log(error);
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          content: "Hubo un problema con el consumo del servicio web.",
        });
    });;
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <h1 className="center">Consola de operaci&oacute;n</h1>
        </Col>
      </Row>
      {/*<Row>
        <h3>Generación de Urls Públicas</h3>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => generar()}
          >
            Generar Links
          </Button>
          <br/>
        </Col>
        <Col span={24}></Col>
      </Row>*/}
      {/*<Row style={{marginBottom: "25px"}}>
        <h3>Generación de constancias de Ratificación</h3>
        <Col span={12}>
        <Button
            type="primary"
            htmlType="submit"
            onClick={() => generarRatificacion()} style={{width:"400px"}}>
            Generar Ratificaciones
          </Button>
        </Col>
      </Row>*/}
      {/*<Row style={{marginBottom:20}}>
        <h3>Notificaciones - Curso en línea</h3>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => enviarNotificaciones()}
          >
            Enviar correos
          </Button>
          <br/>
        </Col>
        <Col span={12}></Col>
      </Row>*/}
      <Row style={{marginBottom:20}}>
        <h3>Notificación para participar - Ratificación</h3>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => enviarNotificacionesRatificacion()}
          >
            Enviar correos
          </Button>
          <br/>
        </Col>
        <Col span={12}></Col>
      </Row>
      <Row>
        <h3>Resumen de cruces</h3>
      </Row>
      <Row>
        <Col span={1} />
        <Col span={8}>
          Total de solicitudes: {resumen.totalSolicitudes} <br />
          Registros nuevos por cruzar: {resumen.nuevos} <br />
          Registros cruzando: {resumen.procesando} <br />
          Registros cruzados (cruce inicial): {resumen.cruceIni} <br />
          Registros cruzados (cruce final): {resumen.cruceFinal} <br />
        </Col>
        <Col span={8}>
          Total de claves de elector en cargas: {resumen.totalCargas} <br />
          Claves de elector de Candidatos: {resumen.candidatos} <br />
          Claves de elector de Representantes: {resumen.representantes} <br />
          Claves de elector de Funcionarios: {resumen.funcionarios} <br />
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            onClick={(e) => {
              if (refresh) {
                setRefresh(false);
              } else {
                setRefresh(true);
              }
            }}
          >
            Actualizar
          </Button>
        </Col>
      </Row>
      <Row className="row20px">
        <Col span={24}>
          <h2>Cruces iniciales de informaci&oacute;n (Candidatos)</h2>
        </Col>
      </Row>
      {rolModulo.captura && (
        <>
          <Form onSubmit={createJob}>
            <div>
              <Row gutter={20}>
                <Col span={5}>
                  <Form.Item>
                    <div>Nombre del job</div>
                    {getFieldDecorator("nameJob", {rules: nombres, getValueFromEvent: (event) => {
                      return txtNombres.test(event.target.value)
                        ? event.target.value
                        : (event.target.value).slice(0,-1);
                    },})(
                      <Input placeholder="Nombre del job" required={true} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item>
                    <div>Expresi&oacute;n cron</div>
                    {getFieldDecorator("exprCron", {rules: inputs, getValueFromEvent: (event) => {
                        return txtTexto.test(event.target.value)
                          ? event.target.value
                          : (event.target.value).slice(0,-1);
                      },})(
                      <Input
                        placeholder="Expresi&oacute;n Cron"
                        required={true}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="row25px"
                    >
                      Programar cruces
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </>
      )}
      <Row>
        <h2>
          Cruce final de información (Candidatos, Representantes y Funcionarios)
        </h2>
      </Row>
      {rolModulo.captura && (
        <>
          <Form onSubmit={createJobCruceFinal}>
            <div>
              <Row gutter={20}>
                <Col span={5}>
                  <Form.Item>
                    <div>Nombre del job</div>
                    {getFieldDecorator("nameJobFinal", {rules: nombres, getValueFromEvent: (event) => {
                      return txtNombres.test(event.target.value)
                        ? event.target.value
                        : (event.target.value).slice(0,-1);
                    },})(
                      <Input placeholder="Nombre del job" required={true} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item>
                    <div>Expresi&oacute;n cron</div>
                    {getFieldDecorator("exprCronFinal", {rules: inputs, getValueFromEvent: (event) => {
                      return txtTexto.test(event.target.value)
                        ? event.target.value
                        : (event.target.value).slice(0,-1);
                    },})(
                      <Input
                        placeholder="Expresi&oacute;n Cron"
                        required={true}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="row25pxwidth200"
                    >
                      Programar cruce final
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </>
      )}
      <Row>
        <h3>Lista de jobs</h3>
        <Table
          data={listaJobs.list}
          setReload={setReload}
          loading={listaJobs.loading}
        />
      </Row>
    </div>
  );
}

export default withRouter(Form.create()(Console));
