import React, { useState, useEffect } from "react";
import { Row, Col, Radio, Alert } from "antd";
import { withRouter } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { rol_captura, rol_consulta, rol_modifica, rol_descarga, rol_borrar } from "../../../utils/Accreditation/roles";
import { Ast } from "../../../utils/Accreditation/functions";
import { getCantidadSolicitudes, getSolicitudesAcreditadas } from "../../../api/accreditation";
import TableRequest from "../../../components/Admin/Accreditation/Table";
import FormAcreditacion from "../../../components/Admin/Accreditation/Form";
import { esJuntaLocal } from "../../../components/Generals/functions";
import "./Accreditation.scss";

function Accreditation(props) {
  const [rolModulo, setRolModulo] = useState({ captura: false, modifica: false, consulta: false, descarga: false, borrar: false, });
  const { user: { rolUsuario } } = useAuth();
  const { proceso, setLoader } = props;
  const [reload, setReload] = useState(false);
  const [saved, setSaved] = useState(false);
  const [verAlert, setVerAlert] = useState(false);
  const [tipoListado, setTipoListado] = useState(0);
  const [total, setTotal] = useState(0);
  const [requests, setRequests] = useState({ list: [], loading: false });

  useEffect(() => {
    if (proceso.distrito !== undefined && proceso.distrito !== null) {
      //Valida los permisos que tienen el usuario
      let captura = false;
      let modifica = false;
      let consulta = false;
      let descarga = false;
      let borrar = false;
      captura = rol_captura.includes(rolUsuario);
      modifica = rol_modifica.includes(rolUsuario);
      consulta = rol_consulta.includes(rolUsuario);
      descarga = rol_descarga.includes(rolUsuario);
      borrar = rol_borrar.includes(rolUsuario);

      if (esJuntaLocal(rolUsuario, proceso.distrito.idDistrito)) {
        captura = false;
        modifica = false;
        borrar = false;
      }

      setRolModulo(() => ({ captura, modifica, consulta, descarga, borrar }));
    }
  }, [rolUsuario, proceso]);

  useEffect(() => {
    if (proceso.distrito !== undefined && proceso.distrito !== null) {
      if (saved) {
        setVerAlert(true);
        setSaved(false);
      }
      getCantidadSolicitudes(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
        proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
          setTotal(res.data);
      });
      getSolicitudesAcreditadas(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
        proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
          const dataSource = [];
          if (res.data.solicitudesAcreditadas !== null && res.data.solicitudesAcreditadas.length > 0) {
            res.data.solicitudesAcreditadas.map((reg, index) => {
              return dataSource.push({ key: index, indice: index + 1, ...reg, });
            });
          }
          setRequests({ list: dataSource, loading: false });          
      });
    }
    setTipoListado(0);
    setReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceso, reload]);

  const onChangeRadio = (e) => {
    setTipoListado(e.target.value);
  };

  function ListadoSolicitudes() {
    return (
    <div>
        <Row className="rowListRequests">
            <Col span={24}>
                <h3>Lista de Solicitudes acreditadas</h3>
            </Col>
        </Row>
        <Row className="rowTableRequests">
            <Col span={24}>
                <div>
                    <TableRequest
                    data={requests.list}              
                    rolModulo={rolModulo}
                    loading={requests.loading}
                    setReload={setReload}
                    />
                </div>
            </Col>
        </Row>
    </div>
    );
  };

  function Formulario() {
    return (
    <div>
        <Row className="rowListRequests">
            <Col span={24}>
                <div>
                    <FormAcreditacion
                    proceso={proceso}
                    setReload={setReload}
                    setLoader={setLoader}
                    setSaved={setSaved}
                    />
                </div>
            </Col>
        </Row>
    </div>
    );
  };
  
  return (
    <div>
      <Row>
        <h1>Acreditación de solicitudes</h1>
        <Col span={24}>
          <small>Los datos con ( <Ast /> ) son requeridos</small>
        </Col>
        <Col span={24} className="rowListRequests">
          {verAlert && <Alert message={<p style={{ marginBottom: "0px" }}>
          Las solicitudes fueron acreditadas correctamente. </p>} type="success" showIcon />}
        </Col>
        <Col span={24}>
          <div className="cardSummary">
            <p className="pShortStyle">
              <span className="quantity">{total}</span> solicitudes pendientes de acreditar (validadas y con asistencias a curso).
            </p>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop:"10px"}}>
                <Alert type="info" showIcon
                    message="Recuerde que la ciudadanía que acredite, podrá descargar sus documentos desde el Portal de Observadores y Observadoras a través de la cuenta que registraron."
                />
            </Row>
      <Row className="rowListRequests">
          <Col span={3}>
            <Ast /> Mostrar solicitudes
          </Col>
      </Row>
      <Row>
          <Col span={12} className="colSpace">
            <Radio.Group value={tipoListado} onChange={onChangeRadio}>
                <Radio value={0}>Acreditadas</Radio>
                <Radio value={1} disabled={!rolModulo.captura}>Sin acreditar</Radio>
            </Radio.Group>
          </Col>
      </Row>
      {tipoListado === 0 ? ListadoSolicitudes() : Formulario()}
    </div>
  );
}

export default withRouter(Accreditation);