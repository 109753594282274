export const rol_captura_estatus = [
  /*
  "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
  "OBSERVADORES11.ADMIN.CAPTURA.OC",
  "OBSERVADORES11.CAU.OC", //Cau
  "OBSERVADORES11.CAPTURA.JL",
  "OBSERVADORES.CAPTURA.OPLE.JL",
  "OBSERVADORES11.CAPTURA.JD",
  */
];

export const rol_consulta_estatus = [
  "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
  "OBSERVADORES11.ADMIN.CAPTURA.OC",
  "OBSERVADORES11.CONSEJERO.OC",
  "OBSERVADORES11.CONSULTA.OC",
  "OBSERVADORES11.CAU.OC", //Cau
  "OBSERVADORES11.CAPTURA.JL",
  "OBSERVADORES11.CONSULTA.JL",
  "OBSERVADORES.CAPTURA.OPLE.JL",
  "OBSERVADORES.CONSULTA.OPLE.JL",
  "OBSERVADORES11.CAPTURA.JD",
  "OBSERVADORES11.CONSULTA.JD",
];

export const rol_cau_estatus = [
  "OBSERVADORES11.CAU.OC", //Cau
];

export const rol_captura_jl_estatus = [
  /*
  "OBSERVADORES11.CAPTURA.JL",
  "OBSERVADORES.CAPTURA.OPLE.JL",
  */
];
