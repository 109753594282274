import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'
import moment from "moment";

//Estilos generales
const fuenteTitulo = {
  name: "Arial",
  size: 11,
  color: { argb: "FFFFFFFF" },
  bold: true
};

const fuenteDatos = {
  name:"Arial",
  size: 10
}

const fuenteDatosLeft = {
  name:"Arial",
  size: 10,
  horizontal: 'left'
}

const estiloGris = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FFCCCCC0" },
  // bgColor: { argb: "40CCCCCC" }
};

const estiloNegro = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "00000000" },
  bgColor: { argb: "00000000" }
};

export async function descargaExcelTransparencia(datos) {
  const nombreReporte = "1b LGT_Art_74_Fr_I_inciso-a";
  //Creación del workbook
  const wb = new ExcelJS.Workbook();
  wb.creator = 'Sistema de Observadores Electorales ';
  wb.lastModifiedBy = 'Sistema de Observadores Electorales';
  wb.created = new Date();
  //Creación de nueva hoja
  const sheet = wb.addWorksheet("Reporte de Formatos");
  //Datos generales del reporte
  sheet.mergeCells("A1:C1");
  sheet.getCell("A1").value = "TÍTULO";
  sheet.getCell("A1").font = fuenteTitulo;
  sheet.getCell("A1").fill = estiloNegro;
  sheet.getCell("A1").alignment = { horizontal: "center"};
  sheet.mergeCells("D1:F1");
  sheet.getCell("D1").value = "NOMBRE CORTO";
  sheet.getCell("D1").font = fuenteTitulo;
  sheet.getCell("D1").fill = estiloNegro;
  sheet.getCell("D1").alignment = { horizontal: "center"};
  sheet.mergeCells("G1:I1");
  sheet.getCell("G1").value = "DESCRIPCIÓN";
  sheet.getCell("G1").font = fuenteTitulo;
  sheet.getCell("G1").fill = estiloNegro;
  sheet.getCell("G1").alignment = { horizontal: "center"};
  sheet.mergeCells("J1:Q1");
  sheet.getCell("J1").fill = estiloNegro;
  ///////////////////////////////////////////////////////////////////////
  sheet.mergeCells("A2:C2");
  sheet.getCell("A2").value = "Asociaciones civiles y/o ciudadanos que realicen actividades de observación electoral";
  sheet.getCell("A2").font = fuenteDatos;
  sheet.getCell("A2").fill = estiloGris;
  sheet.mergeCells("D2:F2");
  sheet.getCell("D2").value = nombreReporte;
  sheet.getCell("D2").font = fuenteDatos;
  sheet.getCell("D2").fill = estiloGris;
  sheet.mergeCells("G2:Q2");
  sheet.getCell("G2").value = "Asociaciones civiles y/o ciudadanos que realicen actividades de observación electoral"
    + "con registro ante autoridad electoral INE y en su caso OPLE";
  sheet.getCell("G2").font = fuenteDatosLeft;
  sheet.getCell("G2").fill = estiloGris;
  //////////////////////////////////////////////////////////////////////
  sheet.mergeCells("A3:Q3");
  sheet.getCell("A3").value = "Tabla Campos";
  sheet.getCell("A3").font = fuenteTitulo;
  sheet.getCell("A3").fill = estiloNegro;
  sheet.getCell("A3").alignment = { horizontal: "center"};
  //////////////////////////////////////////////////////////////////////
  //Titulo de los datos del reporte
  const rowTituloDatos = sheet.getRow(4);
  rowTituloDatos.height = 38;
  rowTituloDatos.values = [
    "Ejercicio", "Fecha de inicio del periodo que se informa", "Fecha de término del periodo que se informa",
    "Nombre(s)", "Primer apellido", "Segundo apellido", "Razón social, en su caso",
    "Lugar donde se realizan actividades de observación (catálogo)", "Fecha de obtención de la acreditación",
    "Hipervínculo formato de solicitud de acreditación",
    "Área(s) responsable(s) que genera(n), posee(n), publica(n) y actualizan la información",
    "Fecha de validación", "Fecha de actualización", "Nota", "Año", "Periodo de Carga", "Nota URL"
  ];
  rowTituloDatos.font = fuenteDatos;
  rowTituloDatos.eachCell(function(cell) {
    cell.fill = estiloGris;
    cell.alignment = { horizontal: "center", wrapText: true };
  });
  //Datos del reporte
  var fila = 5;
  datos.forEach((e) => {
    const rowDatos = sheet.getRow(fila);
    rowDatos.font = fuenteDatos;
    rowDatos.values = [
      e.ejercicio, e.fechaInicio, e.fechaFin, e.nombre, e.aPaterno, e.aMaterno, e.razonSocial,
      e.lugarObservacion, moment(e.fechaAcreditacion).isValid() ? moment(e.fechaAcreditacion).format("DD/MM/YYYY") : " ",
      e.hipervinculo, e.areaResp, moment(e.fechaValidacion).isValid() ? moment(e.fechaValidacion).format("DD/MM/YYYY") : " ",
      e.fechaActualizacion, e.nota, e.anio, e.periodoCarga, e.notaURL
    ];

    rowDatos.getCell(10).value = {
      text: e.hipervinculo,
      hyperlink: e.hipervinculo
    };
    
    fila++;
  });
  sheet.getColumn(1).width = 41; //Ejercicio
  sheet.getColumn(2).width = 36; //Fecha inicio
  sheet.getColumn(3).width = 38; //Fecha fin
  sheet.getColumn(4).width = 29; //Nombre
  sheet.getColumn(5).width = 13; //Apellido Paterno
  sheet.getColumn(6).width = 15; //Apellido Materno
  sheet.getColumn(7).width = 22; //Razon social
  sheet.getColumn(8).width = 53; //Lugar observación
  sheet.getColumn(9).width = 33; //Fecha acreditación
  sheet.getColumn(10).width = 42; //Hipervinculo
  sheet.getColumn(11).width = 73; //Area Resp
  sheet.getColumn(12).width = 17; //Fecha de validación
  sheet.getColumn(13).width = 20; //Fecha de actualización
  sheet.getColumn(14).width = 80; //Nota
  sheet.getColumn(15).width = 9; //Año
  sheet.getColumn(16).width = 9; //Periodo de carga
  sheet.getColumn(17).width = 80; //Nota URL
  //Creación del excel
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), nombreReporte + ".xlsx");
}
