import React, {useEffect} from "react";
import { Divider, Col } from "antd";
import Back from "../../../components/User/Public/Home/Back";

const Glosario = () => {
  function toTop() {
    window.scrollTo(0, 0);
}

  useEffect(() => {
    toTop();
  }, []);
  
  return (
    <div>
      <Back />
      <br />
      <br />
      {/* Letras */}
      <div className="vinculoGlosario">
        <a href="#letra_A">A</a>
        {/* <a href="#letra_C">C</a> */}
        <a href="#letra_D">D</a>
        <a href="#letra_E">E</a>
        <a href="#letra_F">F</a>
        <a href="#letra_I">I</a>
        <a href="#letra_J">J</a>
        <a href="#letra_L">L</a>
        <a href="#letra_M">M</a>
        <a href="#letra_O">O</a>
        {/* <a href="#letra_P">P</a> */}
        {/* <a href="#letra_R">R</a> */}
        <a href="#letra_U">U</a>
      </div>
      {/* Secciones */}
      <div id="letra_A">
        <Divider orientation="left">A</Divider>
        <h2>Acreditación</h2>
        <Col span={16}>
          {/* <p>
            Certificación o aprobación que brinda el Instituto Nacional
            Electoral (INE) a los ciudadanos, en este caso, para ser
            Observadores Electorales durante la Jornada Electoral. Para lo cual,
            se requiere cumplir con ciertos requisitos establecidos y validados
            por el INE, de tal forma que los ciudadanos dispongan de las
            capacidades y facultades necesarias para desempeñarse como
            Observadoras u observadores.
          </p> */}
          <p>
            Certificación o aprobación que brinda el Instituto Nacional Electoral (INE) a los ciudadanos, en este caso, para ser Observadores de la Consulta Popular. Para lo cual, se requiere cumplir con ciertos requisitos establecidos y validados por el INE, de tal forma que los ciudadanos dispongan de las capacidades y facultades necesarias para desempeñarse como Observadoras u Observadores.
          </p>
        </Col>
      </div>
      {/* <div id="letra_C">
        <Divider orientation="left">C</Divider>
        <h2>Casilla</h2>
        <Col span={16}>
          <p>
            Son los espacios asignados para que los ciudadanos acudan a votar.
            Generalmente se localizan en edificios públicos, escuelas o casas
            particulares. En cada casilla se instala una mesa directiva de
            casilla que cuenta con la presencia de representantes de los
            Partidos Políticos, quienes vigilan la legalidad e imparcialidad del
            trabajo de los funcionarios que la componen. Se instala una casilla
            por cada 750 electores de la lista nominal de una sección electoral.
          </p>
        </Col>
      </div> */}
      <div id="letra_D">
        <Divider orientation="left">D</Divider>
        <h2>Distrito</h2>
        <Col span={16}>
          {/* <p>
            Es la división geográfica en que se organiza el territorio de un
            país con fines electorales. En él, se consideran los domicilios de
            los votantes para formar secciones electorales donde se instala
            proporcionalmente un número de casillas para depositar el voto el
            día de la Jornada Electoral. México está dividido en 300 distritos
            electorales.
          </p> */}
          <p>
            Es la división geográfica en que se organiza el territorio de un país con fines electorales. En él, se consideran los domicilios de los votantes para formar secciones electorales donde se instala proporcionalmente un número de mesas receptoras para depositar el voto. México está dividido en 300 distritos.
          </p>
        </Col>
      </div>
      <div id="letra_E">
        <Divider orientation="left">E</Divider>
        <h2>Escrito bajo protesta</h2>
        <Col span={16}>
          {/* <p>
            Documento necesario para realizar registro para ser Observador
            Electoral, donde las y los ciudadanos manifiestan que cumplen con
            los requisitos establecidos en el artículo 217, numeral 1, inciso d)
            de la LGIPE.
          </p> */}
          <p>
            Documento necesario para realizar registro para ser Observador de la Consulta Popular, donde las y los ciudadanos manifiestan que cumplen con los requisitos establecidos.
          </p>
        </Col>
      </div>
      <div id="letra_F">
        <Divider orientation="left">F</Divider>
        <h2>Funcionarios/as de las Mesas Receptoras</h2>
        <Col span={16}>
          <p>
            Son las y los ciudadanos seleccionados y capacitados por la autoridad electoral para integrar las Mesas Receptoras de la Consulta Popular y recibir, contar, clasificar y registrar las opiniones de sus vecinos y vecinas.
          </p>
        </Col>
      </div>
      <div id="letra_I">
        <Divider orientation="left">I</Divider>
        <Col span={16}>
        <h2>Informe de observadores</h2>
          {/* <p>
            Documento que deberán entregar los observadores electorales
            debidamente acreditados, en el cual indicarán lo observado en el PE
            en el que hayan participado, mismo que se entrega en formato digital
            (editable) al INE o los OPL.
          </p> */}
          <p>
            Documento que deberán entregar los Observadores de la Consulta Popular debidamente acreditados, en el cual indicarán lo observado en la Consulta Popular, mismo que se entrega en formato digital (editable) al INE.
          </p>
        </Col>
        <Col span={16}>
        <h2>Instructor/a Asistente de la Consulta Popular</h2>
          <p>
            Personal contratado de manera eventual para el auxilio de las tareas de las Juntas Distritales Ejecutivas para todo el proceso de la Jornada de la Consulta Popular.
          </p>
        </Col>
      </div>
      <div id="letra_J">
        <Divider orientation="left">J</Divider>
        <Col span={16}>
        <h2>Jornada de la Consulta Popular</h2>
          <p>
            Es el día en que la ciudadanía emite su opinión respecto de un tema de trascendencia nacional. En esta ocasión será el domingo 1º de agosto de 2021.
          </p>
        </Col>
      </div>
      <div id="letra_L">
        <Divider orientation="left">L</Divider>
        <h2>Lista Nominal</h2>
        <Col span={16}>
          <p>
            Es la relación que contiene nombre y fotografía de los ciudadanos
            registrados en el Padrón Electoral y con Credencial para Votar
            vigente.
          </p>
        </Col>
      </div>
      <div id="letra_M">
        <Divider orientation="left">M</Divider>
        <h2>Mesa receptora</h2>
        <Col span={16}>
          <p>
            Es el órgano electoral formado por ciudadanas y ciudadanos, facultados para recibir la opinión y realizar el escrutinio y cómputo en cada una de las Unidades Territoriales de la Consulta Popular en que se agrupen las secciones electorales de los 300 distritos electorales federales.
          </p>
        </Col>
      </div>
      <div id="letra_O">
        <Divider orientation="left">O</Divider>
        <h2>Observador de la Consulta Popular</h2>
        <Col span={16}>
          <p>
            Son ciudadanas y ciudadanos que solicitaron y obtuvieron su acreditación por parte del INE, o bien las personas que fueron acreditadas para el Proceso Electoral 2020-2021 y después de tomar un curso de capacitación realizan las actividades de observación de los actos de carácter público de preparación y desarrollo de la Consulta Popular, incluyendo los que se lleven a cabo durante la Jornada de la Consulta Popular y sesiones de los órganos electorales del INE. Deben traer siempre visible el gafete de observadores/as que les fue entregado previamente por el INE.
          </p>
        </Col>
      </div>
      {/* <div id="letra_P">
        <Divider orientation="left">P</Divider>
        <h2>Proceso Electoral Federal</h2>
        <Col span={16}>
          <p>
            El proceso electoral federal en México se concibe como el conjunto
            ordenado y secuencial de actos y actividades regulados por la
            Constitución y la ley electoral que realizan las autoridades, los
            partidos políticos y los ciudadanos con el propósito de renovar
            periódicamente a los integrantes de los poderes Legislativo y
            Ejecutivo de la Unión.
          </p>
        </Col>
      </div> */}
      {/* <div id="letra_R">
        <Divider orientation="left">R</Divider>
        <h2>Representante</h2>
        <Col span={16}>
          <p>
            Son ciudadanos registrados por un partido político ante el INE o
            ante el Organismo Público Local (OPL) de la entidad, para que el día
            de la elección lo representen en la casilla. Vigilan que las
            actividades de la Jornada Electoral se desarrollen de acuerdo con lo
            que establece la ley.
          </p>
        </Col>
      </div> */}
      <div id="letra_U">
        <Divider orientation="left">U</Divider>
        <h2>Unidad Territorial de la Consulta Popular</h2>
        <Col span={16}>
          <p>
            Considera, al menos, una sección electoral o un grupo de secciones electorales completas, que servirá para la determinación del número de Mesas Receptoras de la Consulta Popular, con base en el Padrón Electoral o Lista Nominal, y considerando el rango de electores que se establezca en el procedimiento de ubicación de MRCP.
          </p>
        </Col>
      </div>
    </div>
  );
};

export default Glosario;
