import React from "react";
import { message, Icon, Modal } from "antd";
import moment from "moment";
import { validaClaveMail, saveRegister, updateRegister, validaCursoIniciado, confirmaObsProcesoAnterior } from "../../api/registerAdmin";
import { MSN_GUARDAR } from "../../utils/constanst";
import "./style.scss";


//Función que le asigna formato a las fechas
export function controlRegister(values, docFoto, docFrente, docVuelta, modulo, history, setButton, original, setLoader) {
   const { confirm } = Modal;
   confirm({
     title:
       "Se realizará el registro con los datos capturados.",
     content: "¿Deseas continuar?",
     icon: null,
     okText: "Continuar",
     cancelText: "Corregir datos",
     cancelButtonProps: { className: "ant-btn-default" },
     onOk() {
       validaClaveMail(values, modulo).then((res) => {    // VALIDA QUE LA CLAVE DE ELECTOR Y CORREO ELECTRÓNICO 
        if (res.data.claveElector || res.data.mail) {
          if (res.data.claveElector) {
            message.error("Solicitud inválida. La clave de elector se encuentra registrada por otro candidato a Observador Electoral, favor de validar.");
          }
          if (res.data.mail) {
            message.error("Solicitud inválida. El correo electrónico se encuentra registrado por otro candidato a Observador Electoral, favor de validar.");
          }
          setButton(false);
        } else {
          if (modulo === 3 && original.modalidad !== values.modalidad) {
            validaCursoIniciado(original.idObservador, original.modalidad).then((res) => { 
              if (res.data.validaCurso) {
                let mensaje = "El ciudadano se encuentra inscrito a un curso presencial, se borrará su inscripción para que pueda continuar con el curso en línea.";
                if (original.modalidad === 1) {
                  mensaje = "El ciudadano tiene un progreso en la capacitación en línea, se borrará su avance para que pueda continuar con el curso de manera presencial."       
                } 
                modalCambioModalidad(values, docFoto, docFrente, docVuelta, modulo, history, setButton, original, setLoader, mensaje);
              } else {
                setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
                sendRegister(values, docFoto, docFrente, docVuelta, modulo, history, setButton, original, setLoader); //Se envian los valores 
              }       
            }); 
          } else {
            setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
            sendRegister(values, docFoto, docFrente, docVuelta, modulo, history, setButton, original, setLoader); //Se envian los valores 
          }
        }        
      });     
     },
     onCancel() {
       setButton(false);
     }
   });
}


export function modalCambioModalidad(values, docFoto, docFrente, docVuelta, modulo, history, setButton, original, setLoader, mensaje) {
  const { confirm } = Modal;
  confirm({
    title: mensaje,
    content: "¿Deseas continuar?",
    icon: null,
    okText: "Continuar",
    cancelText: "Corregir datos",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
      sendRegister(values, docFoto, docFrente, docVuelta, modulo, history, setButton, original, setLoader); //Se envian los valores 
    },
    onCancel() {
      setButton(false);
    }
  });
}


export function sendRegister(values, docFoto, docFrente, docVuelta, modulo, history, setButton, original, setLoader) {
  const formData = new FormData();
  if (docFoto !== undefined) {
    formData.append("docFoto", docFoto);
  } else {
    formData.append("docFoto", null);
  }
  if (docFrente !== undefined) {
    formData.append("docFrente", docFrente);
  } else {
    formData.append("docFrente", null);
  }
  if (docVuelta !== undefined) {
    formData.append("docVuelta", docVuelta);
  } else {
    formData.append("docVuelta", null);
  }
  formData.append(
    "solicitud",
    new Blob([JSON.stringify(values)], { type: "application/json" })
  );
  if (modulo === 3) {
    formData.append(
      "original",
      new Blob([JSON.stringify(original)], { type: "application/json" })
    );
  }
  modulo === 1
    ? save(formData, history, setButton, setLoader)
    : update(formData, history, setButton, setLoader)
}

//Guarda la solicitud
function save(register, history, setButton, setLoader) {
  saveRegister(register).then(res => {
    if (res.data.code === 200) {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.success({
        //Mensaje success
        title: "Solicitud validada",
        content: <p>{res.data.message}</p>,
        okText: "Aceptar",
        onOk() {
          history.push("/admin/solicitudes"); //Redirecciona a la pantalla principal
        }
      });
    } else {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.info({
        title: "Atención",
        content: <p>{res.data.causa}</p>
      });
      setButton(false);
    }
  });
}

//Actualiza la solicitud
function update(register, history, setButton, setLoader) {
  updateRegister(register).then(res => {
    if (res.data.code === 200) {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.success({
        title: "Solicitud validada",
        content: <p>{res.data.message}</p>,
        okText: "Aceptar",
        onOk() {
          history.push("/admin/solicitudes");
        }
      });
    } else {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.error({
        title: "Error",
        content: <p>{res.data.causa}</p>
      });
      setButton(false);
    }
  });
}

export function validaApellidos(apellido, apellido2) {
  if (apellido || apellido2) {
    return true;
  }
  return false;
}

export function generaFolio(procesoElectoral) {
  var folio = "CP";
  //if (procesoElectoral.ambitoDetalle === "L") {
  //  folio = folio + "L";
  //} else {
  //  folio = folio + "F";
  //}
  //if (procesoElectoral.tipoProceso === "E") {
  //  folio = folio.slice(0,-1) + "E";
  //} 
  //folio = folio + procesoElectoral.anio.toString().substring(2,4);
  return folio; 
}

export const Ast = () =>  {
    return <span style={{ color: "#f5222d" }}>*</span>;
}

//Función que valida el tipo de imagen y tamaño.
export function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Solo archivos en formato JPG/PNG!");
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M || isLt2M === 0) {
    message.error("¡Peso máximo de 5MB!");
    return false;
  }
  const nameFile = validateName(file.name);
  if (!nameFile) {
    message.error(
      "El nombre de archivo " +
        file.name +
        " es inválido. El nombre del documento no debe contener caracteres especiales."
    );
    return false;
  }
  return isJpgOrPng && isLt2M && nameFile;
}

function validateName(e) {
  var patron = /^[0-9a-zA-Z_-\s]+.[jpg/JPG/jpeg/JPEG/png/PNG]+$/;
  if (e.match(patron)) {
    return true;
  } else {
    return false;
  }
}

//Función que muestra la imagen en el input
export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
//Boton que muestra la animación de cargando
export const uploadButton = loading => (
  <>
    <Icon type={loading ? "loading" : "plus"} />
    <div className="ant-upload-text">Cargar</div>
  </>
);

//Función datepicker que bloquea las fechas anteriores
export function disabledDate(current) {
  return current && current < moment().endOf("day");
}

export function split(detalle) {
  let estado = detalle.split("-");
  return estado[2].charAt(0).toUpperCase() + estado[2].slice(1).toLowerCase();
}

export const calulateNewHeight = (
  originalWidth,
  originalHeight,
  targetWidth
) => {
  const ratio = originalHeight / originalWidth;
  return ratio * targetWidth;
};

export function confirmaObservadorFunction(datosCP, idProceso, history, setButton, setLoader) {
  const { confirm } = Modal;
  confirm({
    title:
      "Estás a punto de enviar tus datos de ratificación como Observador/a para la Consulta Popular.",
    content: "¿Deseas continuar?",
    icon: null,
    okText: "Enviar",
    cancelText: "Revisar datos",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
      confirmaObsProcesoAnterior(datosCP, idProceso).then((res) => {
        if(res.data.code === 200) {
          setLoader({ loading: false, message: "" }); //Se desactiva el loader
          Modal.success({
            title: "Solicitud validada",
            content: <p>{res.data.message}</p>,
            okText: "Aceptar",
            onOk() {
              history.push("/admin/solicitudes"); //Redirecciona a la pantalla principal
            }
          });
        } else {
          setLoader({ loading: false, message: "" }); //Se desactiva el loader
          Modal.error({
            title: "Atención",
            content: res.data.message,
            onOk() {
              history.push("/admin/solicitudes");
            }
          });
        }
      });
    },
    onCancel() {
      setButton(false);
    }
  });
}
