import React, { useState, useEffect } from "react";
import { Upload, Row, Col, Modal, message, Icon } from "antd";
import { Carousel, Form, Checkbox, Divider } from "antd";
import {
  beforeUpload,
  uploadButton,
  Ast,
} from "../../../utils/Request/functions";
import { getBase64 } from "../../../utils/Request/functions";
import { fotos } from "../../../utils/Request/examples";
import "./Steps.scss";
import { URL_IMAGEN } from "../../../api/config";
import Compressor from "compressorjs";
import {ID_DOC_CRED_FRENTE, ID_DOC_CRED_VUELTA, ID_DOC_FOTOGRAFIA } from "../../../utils/constanst"

export default function Documents(props) {
  const { setFiles, files, getFieldDecorator, disabledObs, observador } = props; //Se obtiene el hook para almacenar las imagenes
  const { datos, changeCheckbox, setDisabled, formVal } = props; //Se obtiene el hook para almacenar las imagenes
  const [image, setImageUrl] = useState({ loading: false }); //Hook para visualizar la imagen en el input
  const [image2, setImage2] = useState({ loading: false });
  const [image3, setImage3] = useState({ loading: false });
  const [photo, setPhoto] = useState(false); //Hook para mostrar el modal de ejemplos
  //const [protest, setProtest] = useState(false);

  useEffect(() => {
    if (disabledObs && observador !== undefined && observador !== null && observador.documentosObs !== undefined 
      && observador.documentosObs !== null && observador.documentosObs.length > 0) {
        for (let i = 0; i < observador.documentosObs.length; i++) {
        switch (
          observador.documentosObs[i].cTiposDocumento.idTipoDocumento
        ) {
          case ID_DOC_CRED_FRENTE:
            setImageUrl({
              imageUrl:
                "data:image/png;base64," +
                observador.documentosObs[i].urlImagen,
            });
          break;
          case ID_DOC_CRED_VUELTA:
            setImage2({
              imageUrl:
                "data:image/png;base64," +
                observador.documentosObs[i].urlImagen,
            });
          break;
          case ID_DOC_FOTOGRAFIA:
            setImage3({
              imageUrl:
                "data:image/png;base64," +
                observador.documentosObs[i].urlImagen,
            });
          break;
          default:
            break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledObs, observador]);


  //Para mostrar la imagen en cada input
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setImageUrl({ loading: true });
      setDisabled({ btnSave: true });
      return;
    }
    if (info.file.status === "error") {
      setDisabled({ btnSave: false });
      setImageUrl({ loading: false });
      message.error(
        "Ocurrió un error al adjuntar el archivo, favor de volver a intentar."
      );
      return;
    }
    if (info.file.status === "done") {
      console.log(info.file.originFileObj);
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl({
          imageUrl,
          loading: false,
        });
      });
      callbackFunction(info.file.originFileObj, 1);
    }
  };

  //Para mostrar la imagen en cada input
  const handleChange2 = (info) => {
    if (info.file.status === "uploading") {
      setImage2({ loading: true });
      setDisabled({ btnSave: true });
      return;
    }
    if (info.file.status === "error") {
      setDisabled({ btnSave: false });
      setImage2({ loading: false });
      message.error(
        "Ocurrió un error al adjuntar el archivo, favor de volver a intentar."
      );
      return;
    }
    if (info.file.status === "done") {
      console.log(info.file.originFileObj);
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImage2({
          imageUrl,
          loading: false,
        });
      });
      callbackFunction(info.file.originFileObj, 2);
    }
  };
  //Para mostrar la imagen en cada input
  const handleChange3 = (info) => {
    if (info.file.status === "uploading") {
      setImage3({ loading: true });
      setDisabled({ btnSave: true });
      return;
    }
    if (info.file.status === "error") {
      setDisabled({ btnSave: false });
      setImage3({ loading: false });
      message.error(
        "Ocurrió un error al adjuntar el archivo, favor de volver a intentar."
      );
      return;
    }
    if (info.file.status === "done") {
      console.log(info.file.originFileObj);
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImage3({
          imageUrl,
          loading: false,
        });
      });
      callbackFunction(info.file.originFileObj, 3);
    }
  };
  //Función que muestra el modal de ejemplo
  const modalPhoto = () => {
    return (
      <Modal
        title={null}
        visible={photo}
        onCancel={() => setPhoto(false)}
        footer={null}
        width={210}
      >
        <Carousel autoplay>
          {fotos.map((elem) => (
            <div key={elem.id}>
              <img
                src={elem.url}
                alt={elem.alt}
                style={{ height: "190px", marginTop: 20 }}
              />
            </div>
          ))}
        </Carousel>
      </Modal>
    );
  };

  //Función que muestra el modal de ejemplo
  const Protest = () => {
    return (
      // <Modal
      //   //title={""}
      //   visible={protest}
      //   onCancel={() => setProtest(false)}
      //   footer={null}
      //   width={600}
      // >
      <p style={{ marginTop: 30, textAlign: "justify" }}>
        En el ejercicio del derecho preferente que la Ley General de
        Instituciones y Procedimientos Electorales otorga a los ciudadanos y
        ciudadanas mexicanos/as, de participar como Observadores durante la consulta popular, 
        con fundamento en lo dispuesto por el artículo 217 del citado ordenamiento legal, yo{" "}
        <b>
          {upperCase(formVal.getFieldValue("paterno"))}{" "}
          {upperCase(formVal.getFieldValue("materno"))}{" "}
          {upperCase(formVal.getFieldValue("nombre"))}
        </b>
        , me permito solicitar, mi acreditación como{" "}
        <b>OBSERVADOR/A </b> para la{" "}
        <b>CONSULTA POPULAR 2021</b>, la cual se
        llevará a cabo el próximo <b>1 DE AGOSTO DE 2021</b>, para lo cual anexo
        imagen de mi credencial para votar con fotografía, con clave de elector{" "}
        <b>{upperCase(datos.elector)}.</b>
        <br />
        {/* Bajo protesta de decir verdad, manifiesto no ser ni haber sido miembro
        de la dirigencia nacional, estatal o municipal de organización, partido
        político alguno en los últimos tres años; asimismo no ser ni haber sido
        candidato a puesto de elección popular; no ser servidor público de
        confianza del gobierno federal, estatal o municipal; finalmente
        manifiesto que la documentación que presento es auténtica. */}
        Bajo protesta de decir verdad, manifiesto no tener investigaciones 
        judiciales o penales por algún delito; asimismo no ser funcionaria o funcionario 
        de Mesa Receptora de la Consulta Popular; finalmente manifiesto que la documentación 
        que presento es auténtica.
      </p>
      // </Modal>
    );
  };

  const upperCase = (text) => {
    return text && text.toUpperCase();
  };

  const Message = () => {
    return (
      <div style={{ fontSize: 12, marginBottom: 15 }}>
        <Icon type="info-circle" style={{ color: "#d5007f" }} /> Pulsa sobre una
        imagen para reemplazarla
      </div>
    );
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const callbackFunction = (fileObj, name) => {
    let anchuraNueva = 1170;
    let result = new Promise((resolve, reject) => {
      let img = new Image();
      img.src = window.URL.createObjectURL(fileObj);
      img.onload = () => {
        //calcular nueva altura sin perder la relación de aspecto
        const newHeight = calulateNewHeight(
          img.width,
          img.height,
          anchuraNueva
        );
        new Compressor(fileObj, {
          width: anchuraNueva,
          height: newHeight,
          quality: 0.68,
          success(result) {
            resolve(result);
          },
          error(err) {
            console.log(err);
            reject(err);
          },
        });
      };
    });
    result
      .then((value) => {
        //let nombreArchivo = value.name.substring(0,value.name.lastIndexOf("."));
        let extNombre = value.name.substring(value.name.lastIndexOf(".")+1,value.name.length);
        //value es la imagen comprimida en tipo Blob
        switch (name) {
          case 1:
            setFiles({ ...files, credAnverso: new File([value], `frente.${extNombre}`) });
            setDisabled({ btnSave: false });
            break;
          case 2:
            setFiles({ ...files, credReverso: new File([value], `vuelta.${extNombre}`) });
            setDisabled({ btnSave: false });
            break;
          case 3:
            setFiles({ ...files, foto: new File([value], `foto.${extNombre}`) });
            setDisabled({ btnSave: false });
            break;
          default:
            break;
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("Ocurrió un error al adjuntar el archivo, favor de volver a intentar.");
      });
  };

  //obtiene los datos desde componente PicturesWall
  const calulateNewHeight = (originalWidth, originalHeight, targetWidth) => {
    const ratio = originalHeight / originalWidth;
    return ratio * targetWidth;
  };

  return (
    <>
      <h3>Documentación personal</h3>
      {modalPhoto()}
      {/* {modalProtest()} */}
      <h4>Credencial de elector</h4>
      <p>
        Frente y vuelta, archivos en formato jpeg o png que no excedan los 5MB
        por archivo.
      </p>
      <Row gutter={24}>
        <Col span={8}>
          <Ast /> Frente (anverso)
          <Upload
            name="frente"
            listType="picture-card"
            className="avatar-uploader"
            action={URL_IMAGEN}
            onChange={handleChange}
            showUploadList={false}
            accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
            beforeUpload={beforeUpload}
            customRequest={dummyRequest}
            disabled={disabledObs}
          >
            {image.imageUrl ? (
              <img
                src={image.imageUrl}
                alt="frente"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton(image.loading)
            )}
          </Upload>
          <Message />
        </Col>
        <Col span={8}>
          <Ast /> Vuelta (reverso)
          <Upload
            name="vuelta"
            listType="picture-card"
            className="avatar-uploader"
            action={URL_IMAGEN}
            onChange={handleChange2}
            showUploadList={false}
            accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
            beforeUpload={beforeUpload}
            disabled={disabledObs}
          >
            {image2.imageUrl ? (
              <img
                src={image2.imageUrl}
                alt="vuelta"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton(image2.loading)
            )}
          </Upload>
        </Col>
      </Row>
      <h4>
        <Ast /> Fotografía
      </h4>
      <p>
        Fotografía actual con fondo claro, sin accesorios o lentes, mirada al
        frente y oídos descubiertos.
        <br />
        Formato jpeg, png. Tamaño mínimo 450x600 pixeles y límite de 5MB.
      </p>
      <button className="btnExample" onClick={() => setPhoto(true)}>
        Ver ejemplos
      </button>
      <Upload
        name="foto"
        listType="picture-card"
        className="avatar-uploader"
        action={URL_IMAGEN}
        onChange={handleChange3}
        showUploadList={false}
        accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
        beforeUpload={beforeUpload}
        disabled={disabledObs}
      >
        {image3.imageUrl ? (
          <img src={image3.imageUrl} alt="foto" style={{ width: "100%" }} />
        ) : (
          uploadButton(image3.loading)
        )}
      </Upload>
      <Message />
      <Divider />
      <Protest />

      {!disabledObs && (
      <div>
      <Form.Item>
        {getFieldDecorator("compromiso", {
          valuePropName: "checked",
          initialValue: datos.compromiso,
          rules: [
            {
              required: true,
              transform: (value) => value || undefined,
              type: "boolean",
              message: "Por favor acepte terminos y condiciones.",
            },
          ],
        })(
          <>
            <Checkbox onChange={changeCheckbox("compromiso")}>
              Acepto que se genere el escrito de protesta con mi información.
              {/* <button className="btnExample" onClick={() => setProtest(true)}>
                escrito de protesta
              </button> */}
              {/* con mi información. */}
            </Checkbox>
            <br />
            <Checkbox
              onChange={changeCheckbox("comCorreo")}
              style={{ marginLeft: 0 }}
            >
              Acepto recibir información sobre mi proceso vía correo
              electrónico, portal público, teléfono o acudir presencialmente a
              una de las Juntas.
            </Checkbox>
          </>
        )}
      </Form.Item>
      </div>
      )}
      {disabledObs && (
      <div>
      <Form.Item>
            <Checkbox defaultChecked={true} disabled={disabledObs}>
              Acepto que se genere el escrito de protesta con mi información.
            </Checkbox>
            <br />
            <Checkbox defaultChecked={true} style={{ marginLeft: 0 }} disabled={disabledObs}>
              Acepto recibir información sobre mi proceso vía correo
              electrónico, portal público, teléfono o acudir presencialmente a
              una de las Juntas.
            </Checkbox>
      </Form.Item>
      </div>
      )}
    </>
  );
}
