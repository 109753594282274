import React, { useState, useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form, Row, Col, Input, Icon, TimePicker, Alert, Modal } from "antd";
import { DatePicker, Button, Checkbox } from "antd";
import {
  formatDate,
  placeholderDate,
  USUARIO_CAU,
} from "../../../utils/constanst";
import {
  disabledDate,
  ModalConfirm,
} from "../../../utils/Convocation/functions";
import {
  Ast,
  getTypeUser,
  minusculas,
} from "../../../utils/Convocation/functions";
import { endTime, startTime } from "../../../utils/Convocation/functions";
import {
  //inputNumber,
  required,
  observaciones,
} from "../../../utils/rules";
import { soloDigitos, domicilio, inputTel } from "../../../utils/rules";
import useAuth from "../../../hooks/useAuth";
import moment from "moment";
import {
  getByIdConvocation,
  getByAddressJunta,
} from "../../../api/convocation";
import "./Form.scss";
import { rol_captura, rol_modifica } from "../../../utils/Convocation/roles";

const FormConvocation = (props) => {
  const { getFieldDecorator } = props.form; //Método para validar los inputs
  const {
    history,
    setLoader,
    proceso: { proceso, estado, distrito },
  } = props; //Se obtienen datos del popover
  const { con, status } = props.location; //Se reciben los valores que vienen de la tabla convocatoria
  //Datos del usuario
  const {
    user: { versionUsuario, user, rolUsuario },
  } = useAuth();
  const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible
  const [data, setData] = useState({ estatus: false }); //Hook-Almacenar los datos del formulario
  const [screen, setScreen] = useState({}); //Hook-Para habilitar/Deshabilar elementos de la pantallla (botones, titulos, Ast)
  const [disable, setDisable] = useState(false); //Hook- Validar/Bloquear los inputs si la convocatoria esta publicada y se desea modificar
  const [rolModulo, setRolModulo] = useState({}); //Hook-Se almacenan los permisos del usuario
  const maxChar = 600;
  const [character, setCharacter] = useState(600);
  const [justificacion, setjustificacion] = useState(false);
  const [process, setProcess] = useState({});
  //useEffect que valida que el componente este seleccionado de lo contrario cancela cualquier subcripción
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  //UesEffect que obtiene el estado y proceso en la vista de registro
  useEffect(() => {
    let idEstado, idDistrito;
    if (history.location.pathname === "/admin/convocatoria/registro") {
      idEstado = estado ? estado.idEstado : 0;
      idDistrito = distrito ? distrito.idDistrito : 0;
      setProcess({ idEstado, idDistrito });
      versionUsuario !== "OC" && getAddressJunta(idEstado, idDistrito);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado, distrito, history, versionUsuario]);

  useEffect(() => {
    //Valida los permisos que tienen el usuario
    let captura = false;
    let modifica = false;
    captura = rol_captura.includes(rolUsuario);
    modifica = rol_modifica.includes(rolUsuario);
    if (history.location.pathname === "/admin/convocatoria/registro") {
      //Si no cuenta con los permisos de captura o modifica
      if (!captura && !modifica) {
        history.push("/admin/convocatoria");
      }
    } else {
      if (history.location.pathname === "/admin/convocatoria/modificar") {
        if (con === undefined) {
          history.push("/admin/convocatoria");
        } else {
          setRolModulo(() => ({ modifica }));
          setjustificacion(true);
          getConvocation();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //Función que obtiene la dirección de las juntas y la muestra en el input correspondiente
  const getAddressJunta = (idEstado, idDistrito) => {
    getByAddressJunta(idEstado, idDistrito)
      .then((res) => {
        const { status, domicilios } = res.data;
        if (isMounted.current) {
          status === "Éxito" &&
            setData({
              ...data,
              direccion: minusculas(domicilios[0].direccion),
            });
        }
      })
      .catch((err) => {
        console.log("Error al obtener dirección");
      });
  };

  //Llena el formulario si recibe el Id por parte de la tabla
  const getConvocation = () => {
    if (status !== undefined) {
      //Se bloquean los inputs y cambia los titulos
      status
        ? setScreen({
            input: true,
            titulo: true,
            buttonUpd: true,
            title: true,
          })
        : setScreen({ title: true });
      getByIdConvocation(con.tipo, con.idConvocatoria)
        .then((res) => {
          //Indica si es una convocatoria tipo INE
          if (con.tipo === 1) {
            const { convocatoriaOC } = res.data;
            setData({
              idConvocatoria: convocatoriaOC.idConvocatoria,
              numAcuerdo: convocatoriaOC.numAcuerdoIne.toString(),
              numAcuerdoCg: convocatoriaOC.numAcuerdoCg.toString(),
              fechaLimite: moment(convocatoriaOC.fechaLimite),
              estatus: convocatoriaOC.estatus,
              inputJust: convocatoriaOC.estatus && true,
              justificacion: convocatoriaOC.justificacion
                ? convocatoriaOC.justificacion
                : null,
            });
            setDisable(convocatoriaOC.estatus && true);

            if (!status) {
              setjustificacion(true);
              setCharacter(
                convocatoriaOC.justificacion
                  ? maxChar - convocatoriaOC.justificacion.length
                  : maxChar
              );
            } else {
              setjustificacion(convocatoriaOC.justificacion && true);
              //Se resta el numero de caracteres
              setCharacter(
                convocatoriaOC.justificacion
                  ? maxChar - convocatoriaOC.justificacion.length
                  : maxChar
              );
            }
          } else {
            //Convocatoria tipo Juntas
            const { juntas } = res.data;
            setData({
              idConvocatoria: juntas.idJunta,
              direccion: juntas.direccion,
              telefono: juntas.telefono && juntas.telefono.toString(),
              horaInicio2: juntas.horaInicio && moment(juntas.horaInicio),
              horaFin2: juntas.horaFin && moment(juntas.horaFin),
            });
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  //Función que parsea el dato de acuerdo al tipo input
  const handleChange = (input) => (event) => {
    switch (input) {
      case "fechaLimite":
        event != null
          ? setData({ ...data, [input]: event.format("YYYY-MM-DD") })
          : setData({ ...data, [input]: null });
        break;
      case "estatus":
        setData({ ...data, [input]: event.target.checked });
        break;
      case "horaInicio2":
        event != null
          ? setData({ ...data, [input]: event })
          : setData({ ...data, [input]: null });
        break;
      case "horaFin2":
        event != null
          ? setData({ ...data, [input]: event })
          : setData({ ...data, [input]: null });
        break;
      case "justificacion":
        const charCount = event.target.value.length;
        const charLength = maxChar - charCount;
        setCharacter(charLength);
        setData({ ...data, [input]: event.target.value });
        break;
      default:
        setData({ ...data, [input]: event.target.value });
        break;
    }
  };

  //Se obtienen los datos del formulario
  const addConvocation = (e) => {
    e.preventDefault();
    if (proceso !== undefined && proceso !== null) {
      props.form.validateFields((err) => {
        if (!err) {
          getDefaultData();
          setScreen({ ...screen, button: true });
          //Se crea una copia de las horas para poder hacer el parseo correspondiente
          if (data.horaInicio2 != null) {
            data.horaInicio = data.horaInicio2.format("YYYY-MM-DD HH:mm");
          }
          if (data.horaFin2 != null) {
            data.horaFin = data.horaFin2.format("YYYY-MM-DD HH:mm");
          }
          ModalConfirm(data, history, setScreen, setLoader);
        }
      });
    } else {
      Modal.error({
        content: "Selecciona la consulta popular.",
      });
    }
  };
  //Obtiene los datos del usuario y del proceso electoral seleccionado
  const getDefaultData = () => {
    data.idProcesoElectoral = proceso.idProcesoElectoral;
    data.procesoElectoral = proceso.nombreProceso;
    data.idDetalleProceso = proceso.idDetalleProceso;
    data.detalleProceso = proceso.descripcion;
    data.tipoUsuario = getTypeUser(versionUsuario);
    data.usuario = user.sub;
    data.idEstado = estado && estado.idEstado;
    data.estado = estado && estado.nombreEstado;
    data.idDistrito = distrito && distrito.idDistrito;
  };

  //Funcion que valida que sea mayor de edad
  const numberIne = (rule, value, callback) => {
    if (
      value === "0" ||
      value === "00" ||
      value === "000" ||
      value === "0000"
    ) {
      callback("Dato inválido.");
    } else {
      callback();
    }
  };

  const numberCg = (rule, value, callback) => {
    if (value === "0000") {
      callback("Año inválido.");
    } else {
      callback();
    }
  };

  return (
    <div style={{ marginLeft: "20px" }}>
      <Link to={"/admin/convocatoria"} className="back">
        <Icon type="play-circle" theme="filled" rotate="180" /> Volver a
        "Publicación"
      </Link>
      <div style={{ marginTop: "30px" }}>
        {screen.titulo ? (
          <h1>
            {versionUsuario !== "OC"
              ? "Ver convocatoria con datos de contacto"
              : "Ver convocatoria"}
          </h1>
        ) : (
          <>
            {screen.title ? (
              <h1>
                {versionUsuario !== "OC"
                  ? "Editar convocatoria con datos de contacto"
                  : "Editar convocatoria"}
              </h1>
            ) : (
              <h1>
                {versionUsuario !== "OC"
                  ? "Nueva convocatoria con datos de contacto"
                  : "Nueva convocatoria"}
              </h1>
            )}
            <p>
              Los datos con ( <Ast /> ) son requeridos
            </p>
          </>
        )}
        <Alert
          message="La convocatoria está publicada en el portal de observadoras y observadores electorales."
          type="info"
          showIcon
          style={data.inputJust && !screen.input ? {} : { display: "none" }}
        />
      </div>
      <Form onSubmit={addConvocation}>
        {getFieldDecorator("idConvocatoria", {
          initialValue: data.idConvocatoria,
        })(<Input disabled hidden />)}
        {versionUsuario !== "OC" && (
          <Alert
            message="En Procesos Federales las convocatorias a nivel Juntas Ejecutivas no son publicadas en el portal de observadoras y observadores electorales."
            type="info"
            showIcon
            style={screen.input ? { display: "none" } : {}}
          />
        )}
        <br />
        <Form.Item>{proceso && <h5>Consulta Popular 2021</h5>}</Form.Item>

        {versionUsuario === "OC" ? (
          <>
            <h5>Acuerdo para publicación de la convocatoria</h5>
            Número de acuerdo
            <Row gutter={10}>
              <Col span={2} style={{ paddingRight: "60px", marginTop: "10px" }}>
                {!screen.input && <Ast />}
                INE/CG
              </Col>
              <Col span={2}>
                <Form.Item>
                  {getFieldDecorator("numAcuerdo", {
                    initialValue: data.numAcuerdo,
                    rules: [
                      { required: true, message: "Dato requerido." },
                      { validator: numberIne },
                    ],
                    getValueFromEvent: (event, previo) => {
                      return soloDigitos.test(event.target.value)
                        ? event.target.value
                        : previo;
                    },
                  })(
                    <Input
                      placeholder="0000"
                      //disabled={disable ? disable : screen.input}
                      disabled={screen.input}
                      maxLength={4}
                      onChange={handleChange("numAcuerdo")}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={1} style={{ paddingLeft: "15px", marginTop: "10px" }}>
                /
              </Col>
              <Col span={2}>
                <Form.Item>
                  {getFieldDecorator("numAcuerdoCg", {
                    initialValue: data.numAcuerdoCg,
                    rules: [
                      { required: true, message: "Dato requerido." },
                      { min: 4, message: "Año inválido." },
                      { validator: numberCg },
                    ],
                    getValueFromEvent: (event, previo) => {
                      return soloDigitos.test(event.target.value)
                        ? event.target.value
                        : previo;
                    },
                  })(
                    <Input
                      placeholder="0000"
                      //disabled={disable ? disable : screen.input}
                      disabled={screen.input}
                      minLength={4}
                      maxLength={4}
                      onChange={handleChange("numAcuerdoCg")}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <h5>Plazo de la convocatoria</h5>
            {!screen.input && <Ast />} Fecha límite para recepción de
            solicitudes
            <Form.Item>
              {getFieldDecorator("fechaLimite", {
                rules: required,
                initialValue: data.fechaLimite,
              })(
                <DatePicker
                  disabledDate={disabledDate}
                  //locale={locale}
                  placeholder={placeholderDate}
                  format={formatDate}
                  disabled={screen.input}
                  onChange={handleChange("fechaLimite")}
                />
              )}
            </Form.Item>
            {data.inputJust && (
              <>
                {justificacion && (
                  <>
                    {!screen.input && <Ast />} Justificación para la
                    modificación de la convocatoria
                    <Form.Item>
                      {getFieldDecorator("justificacion", {
                        rules: observaciones,
                        initialValue: data.justificacion,
                      })(
                        <Input.TextArea
                          maxLength={600}
                          style={{ width: 350 }}
                          onChange={handleChange("justificacion")}
                          disabled={screen.input}
                        />
                      )}
                    </Form.Item>
                    <span>Restan {character} caracteres</span>
                  </>
                )}
              </>
            )}
            <Form.Item>
              {getFieldDecorator("estatus", {
                valuePropName: "checked",
                initialValue: data.estatus,
              })(
                <Checkbox
                  onChange={handleChange("estatus")}
                  disabled={disable ? disable : screen.input}
                >
                  Publicar convocatoria inmediatamente
                </Checkbox>
              )}
            </Form.Item>
          </>
        ) : (
          <>
            <h2>Datos de contacto de la Junta</h2>
            <Form.Item label="Dirección" hasFeedback>
              {getFieldDecorator("direccion", {
                rules: domicilio,
                initialValue: data.direccion,
              })(
                <Input
                  //style={{ width: 500 }}
                  placeholder="Calle, número, colonia, ciudad, código postal"
                  disabled={screen.input}
                  maxLength={200}
                  onChange={handleChange("direccion")}
                />
              )}
            </Form.Item>
            <Form.Item label="Teléfono" hasFeedback>
              {getFieldDecorator("telefono", {
                rules: inputTel,
                initialValue: data.telefono,
                getValueFromEvent: (event, previo) => {
                  return soloDigitos.test(event.target.value)
                    ? event.target.value
                    : previo;
                },
              })(
                <Input
                  style={{ width: 120 }}
                  maxLength={10}
                  placeholder="10 dígitos"
                  disabled={screen.input}
                  onChange={handleChange("telefono")}
                />
              )}
            </Form.Item>
            Horario de atención
            <Row gutter={4}>
              <Col span={3}>
                {getFieldDecorator("horaInicio2", {
                  initialValue: data.horaInicio2,
                })(
                  <TimePicker
                    //defaultValue={moment('12:08', format)}
                    placeholder="HH:mm"
                    format="HH:mm"
                    hideDisabledOptions={true}
                    showNow={false}
                    onChange={handleChange("horaInicio2")}
                    disabledHours={() => endTime}
                    //minuteStep={15}
                    defaultOpenValue={moment("00:00", "HH:mm")}
                    disabled={screen.input}
                  />
                )}
              </Col>
              <Col span={3}>
                {getFieldDecorator("horaFin2", {
                  initialValue: data.horaFin2,
                })(
                  <TimePicker
                    //defaultValue={moment('12:08', format)}
                    placeholder="HH:mm"
                    format="HH:mm"
                    hideDisabledOptions={true}
                    showNow={false}
                    onChange={handleChange("horaFin2")}
                    disabledHours={() => startTime}
                    //minuteStep={15}
                    defaultOpenValue={moment("13:00", "HH:mm")}
                    disabled={screen.input}
                  />
                )}
              </Col>
            </Row>
          </>
        )}

        <Form.Item style={{ textAlign: "center" }}>
          {screen.buttonUpd ? (
            rolModulo.modifica && (
              <Button
                type="primary"
                onClick={() => {
                  setScreen({
                    input: false,
                    title: true,
                  });
                  setjustificacion(true);
                }}
                disabled={versionUsuario === "JL" && process.idDistrito !== 0}
              >
                Modificar
              </Button>
            )
          ) : (
            <>
              {rolUsuario === USUARIO_CAU ? (
                <>
                  <Button type="default">
                    <Link to={"/admin/convocatoria"}>Cancelar</Link>
                  </Button>
                </>
              ) : (
                <>
                  <Button type="default" disabled={screen.button}>
                    <Link to={"/admin/convocatoria"}>Cancelar</Link>
                  </Button>{" "}
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={screen.button}
                  >
                    {props.form.getFieldValue("estatus")
                      ? "Guardar y publicar"
                      : "Guardar"}
                  </Button>
                </>
              )}
            </>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
const ConvocationForm = Form.create()(FormConvocation);
export default withRouter(ConvocationForm);
