import React from "react";
import { Icon } from "antd";
import './Requirements.scss';
//Imagenes 
import Requisito0 from '../../../../assets/img/Requirements/requisitosIMG_00.svg';
import Requisito1 from '../../../../assets/img/Requirements/requisitosIMG_01.svg';
import Requisito2 from '../../../../assets/img/Requirements/requisitosIMG_02.svg';
import Requisito3 from '../../../../assets/img/Requirements/requisitosIMG_03.svg';
//import Requisito4 from '../../../../assets/img/Requirements/requisitosIMG_04.svg';
//import Requisito5 from '../../../../assets/img/Requirements/requisitosIMG_05.svg';
import Requisito6 from '../../../../assets/img/Requirements/requisitosIMG_06.svg';
//import Requisito7 from '../../../../assets/img/Requirements/requisitosIMG_07.svg';

const Requirements = () => {
  return (
    <div style={{padding:"10px"}}>
      <p>Si deseas participar como Observadora u Observador de la Consulta Popular debes cumplir con lo siguiente:</p>
      <ol>
        <div className="elementoLista">
          <div>
            <li><strong>Ser ciudadana o ciudadano mexicano</strong></li>
            <p>Teniendo tu credencial de elector vigente.</p>
          </div>
          <div><img src={Requisito0} alt="requisito0" className="imagenLista"/></div>
        </div>
      {/* <Row className="elementoLista">
        <Col span={9}>
          <li><h5>Ser ciudadana o ciudadano mexicano</h5></li>
          <p>Teniendo tu credencial de elector vigente</p>
        </Col>
        <Col span={15}><img src={Requisito0} alt="requisito0" className="imagenLista"/></Col>
      </Row> */}
        <div className="elementoLista">
          <div>
            <li><strong>Registrarte para realizar tu solicitud de acreditación</strong></li>
            <p>El registro se lleva a cabo en línea, debes crear una cuenta y después llenar tu solicitud.</p>
          </div>
          <div><img src={Requisito2} alt="requisito2" className="imagenLista" /></div>
        </div>
        <div className="elementoLista">
          <div>
            <li><strong>Registrarte para realizar tu solicitud de ratificación</strong></li>
            <p>Con la cuenta que creada con la cual te registraste para participar en el Proceso Electoral 2021, podrás registrar la solicitud de ratificación.</p>
          </div>
          <div><img src={Requisito2} alt="requisito2" className="imagenLista" /></div>
        </div>
        <div className="elementoLista">
          <div>
            <li><strong>Tomar el curso de capacitación.</strong></li>
            {/* <p>Puede ser impartido en línea por el INE; o de manera presencial por el INE, Institutos Electorales de una entidad u organizaciones.</p> */}
            <p>Para la Consulta Popular podrás tomar el curso de manera presencial o de manera virtual a través de alguna herramienta o plataforma (Zoom, Google Meet, Microsoft Teams o algún otro).</p>
          </div>
          <div><img src={Requisito1} alt="requisito1" className="imagenLista"/></div>
        </div>
        <div className="elementoLista">
          <h4><Icon type="exclamation-circle" /> No deberás estar en los siguientes casos:</h4>
        </div>
      </ol>
      <ol>
      <div className="elementoLista">
        <div>
          <li><strong>Tener investigaciones judiciales o penales por algún delito.</strong></li>
        </div>
        <div><img src={Requisito3} alt="requisito3" /></div>
      </div>
      {/* <div className="elementoLista">
        <div>
          <li><strong>Ser o haber sido miembro de Organizaciones o Partidos Políticos.*</strong></li>
        </div>
        <div><img src={Requisito4} alt="requisito4" /></div>
      </div> */}
      {/* <div className="elementoLista">
        <div>
          <li><strong>Tener o haber tenido una candidatura a puesto de Elección Popular.*</strong></li>
        </div>
        <div><img src={Requisito5} alt="requisito5" /></div>
      </div> */}
      <div className="elementoLista">
        <div>
          <li><strong>Ser designado como funcionaria o funcionario de Mesa Receptora de la Consulta Popular.</strong></li>
        </div>
        <div><img src={Requisito6} alt="requisito6" /></div>
      </div>
      {/* <div className="elementoLista">
        <div>
          <li><strong>Ser representante de Partido Político o Candidatura Independiente.</strong></li>
        </div>
        <div><img src={Requisito7} alt="requisito7" /></div>
      </div> */}
      {/* <div className="elementoLista">
        <p>* Se consideran 3 años anteriores a la elección.</p>
      </div> */}

      </ol>
    </div>
  );
};

export default Requirements;
