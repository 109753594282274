import React, { useState, useEffect, createContext } from "react";
import jwtDecode from "jwt-decode";
import {
  getAccessTokenApi,
  getTokenCiudadano,
  logoutCiudadano,
} from "../api/auth";
import { DETALLE_PROCESO_CI } from "../utils/constanst";
//Se crea un contexto global que obtendra la información del usuario y se la pasará a todos los componentes hijos
//Sin necesidad de poner manualmente la información del usuario en cada componente
export const AuthContext = createContext();

export default function AuthProvider(props) {
  const { children } = props; //Todos loc componentes que esten dentro de AutjProvider
  const [user, setUser] = useState({
    //Hook para almacenar la información del usuario
    user: null,
    isLoading: true,
    proceso: null,
    estado: null,
    distrito: null,
    infoMenu: null,
    idSistema: null,
    idEstado: null,
    idDistrito: null,
    idMunicipio: null,
    ambito: null,
    rolUsuario: null,
    versionUsuario: null,
    nombre : null,
  });
  //Hook para almacenar la información ciudadano
  const [ciudadano, setCiudadano] = useState({
    ciudadano: null,
    isLoading: true,
  });

  useEffect(() => {
    checkUserLogin(setUser); //Verificar la información de usuario interno
    checkUserCiudadano(setCiudadano); //Verifica la información del ciudadano
  }, []);
  //Se le manda la información del usuario(Interno ó ciudadano) a todos los componentes hijos
  return (
    <AuthContext.Provider value={{ user: user, setUser, ciudadano: ciudadano }}>
      {children}
    </AuthContext.Provider>
  );
}

function checkUserLogin(setUser) {
  const accessToken = getAccessTokenApi(); //Se obtiene el token que esta almacenado en localStorage
  if (!accessToken) {
    setUser({
      user: null,
      isLoading: false,
    });
  } else {
    //Si existe el token lo almacena en el hook
    setUser({
      user: jwtDecode(accessToken),
      isLoading: false,
      estado: localStorage.getItem("ESTADO"),
      distrito: localStorage.getItem("DISTRITO"),
      infoMenu: JSON.parse(localStorage.getItem("INFO_MENU")),
      idSistema: localStorage.getItem("ID_SISTEMA"),
      idEstado: localStorage.getItem("ID_ESTADO"),
      idDistrito: localStorage.getItem("ID_DISTRITO"),
      idMunicipio: localStorage.getItem("ID_MUNICIPIO"),
      ambito: localStorage.getItem("AMBITO"),
      rolUsuario: localStorage.getItem("ROL_USUARIO"),
      versionUsuario: localStorage.getItem("VERSION_USUARIO"),
      nombre: localStorage.getItem("NOMBRE"),
    });
  }
}
//////////////////////////////////////////////////////// Ciudadano //////////////////////////////////////////////
function checkUserCiudadano(setCiudadano) {
  const accessToken = getTokenCiudadano(); //Se obtiene el token que esta almacenado en localStorage
  if (!accessToken) {
    logoutCiudadano();
    setCiudadano({
      ciudadano: null,
      isLoading: false,
    });
  } else {
    //Si existe el token lo almacena en el hook
    setCiudadano({
      ciudadano: jwtDecode(accessToken), //Se decodifica el token
      isLoading: false,
      detalle: JSON.parse(localStorage.getItem(DETALLE_PROCESO_CI)), //se obtiene los detalles del proceso
    });
  }
}
