import { Button, Checkbox, Col, Icon, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { confirmaDeclinacion } from "../../../../utils/Public/functions";
import "./Decline.scss"

function Decline(props) {
    const { history } = props;
    const {
        ciudadano: { ciudadano },
    } = useAuth();
    const [declina, setDeclina] = useState(false);
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

    useEffect(() => {
        toTop();
    }, []);

    function toTop() {
        window.scrollTo(0, 0);
    }

    function checkDeclina(event) {
        if(event.target.checked) {
            setDeclina(true);
        } else {
            setDeclina(false);
        }
    }

    const declinaSolicitud = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);
        confirmaDeclinacion(ciudadano.sub, history, setBotonDeshabilitado);
    };

    return (
        <div>
            <Row>
                <Link to={"/ciudadano/inicio"} className="back">
                    <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
                </Link>
            </Row>
            <Row>
                <Col span={20} offset={2}>
                    <h1>Declina tu solicitud de acreditación</h1>
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                <Col span={20} offset={2} className="declinaAlerta">
                    <p>
                        <Icon type="exclamation-circle" theme="filled" />&nbsp;
                        Al declinar tu solicitud no podrás continuar posteriormente con tu proceso
                    </p>
                </Col>
            </Row>
            <Row className="declinaRow">
                <Col span={20} offset={2}>
                    <p className="declina24px">Lee con atención</p>
                </Col>
            </Row>
            <Row>
                <Col span={20} offset={2}>
                    <p>
                        Mediante la declinación de tu solicitud se expresa tu voluntad de no continuar
                        con tu proceso de acreditación como Observadora u Observador de la Consulta Popular.
                    </p>
                    <p>
                        Antes de declinar tu solicitud toma en cuenta lo siguiente:
                    </p>
                </Col>
            </Row>
            <Row>
                <Col span={20} offset={2}>
                    <ul className="listaPortal">
                        <li>No podrás continuar con tu proceso de acreditación, si ya cuentas con
                            tu acreditación, ésta será cancelada y perderá su validez.*</li>
                        <li>Tu dirección de correo electrónico asociada a tu cuenta no podrá ser
                            utlizada para el registro de otra solicitud de acreditación.*</li>
                        {/* <li>Serás dado de baja de los cursos de capacitación presenciales en los
                            que hayas sido inscrito.*</li> */}
                        <li>El proceso de declinación no es reversible, una vez concluido no se
                            podrá modificar tu decisión.</li>
                    </ul>
                    <p>* Aplica para la Consulta Popular donde se haya realizado el registro</p>
                </Col>
            </Row>
            <Row>
                <Col span={20} offset={2}>
                    <h1>Declinación</h1>
                </Col>
            </Row>
            <Row>
                <Col span={20} offset={2}>
                    <p>Para declinar tu solicitud debes aceptar las siguientes condiciones:</p>
                </Col>
            </Row>
            <Row>
                <Col span={20} offset={2}>
                    <Checkbox onChange={checkDeclina}>
                        Acepto las consecuencias y expreso mi voluntad de realizar la declinación de mi
                        solicitud de acreditación como Observadora u Observador para la Consulta Popular 2021.
                    </Checkbox>
                </Col>
            </Row>
            <Row className="declinaRowBotones">
                <Button type="default">
                    <Link to={"/ciudadano/inicio"}>Regresar</Link>
                </Button>
                <Button type="primary" htmlType="submit" disabled={!declina || botonDeshabilitado}
                        onClick={declinaSolicitud}>
                    Declinar solicitud
                </Button>
            </Row>
        </div>
    );

}

export default withRouter(Decline);
