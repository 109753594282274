import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'
import moment from "moment";

//Estilos generales
const fuenteTitulo = {
  name: "Arial",
  size: 10,
  bold: true
};

const fuenteDatos = {
  name:"Arial",
  size: 10
}

const estiloGris = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "CCC2C2C2" },
  bgColor: { argb: "CCC2C2C2" }
};

const centradoH = {
  horizontal: "center"
}


export async function saveExcelOrg(sheetName,nombreReporte, datos) {
   //Creación del workbook
  const wb = new ExcelJS.Workbook()
  wb.creator = 'Sistema de Observadores Electorales';
  wb.lastModifiedBy = 'Sistema de Observadores Electorales';
  wb.created = new Date();

  // Creando nueva hoja con orientacion landscape
  const ws = wb.addWorksheet(sheetName, {
    pageSetup: {
      orientation: 'landscape',
      horizontalCentered: true
    }
  });

  //Encabezado
  ws.mergeCells("A1:K1");
  ws.getCell("A1").value = "Consulta Popular 2021";
  ws.getCell("A1").font = fuenteTitulo;
  ws.getCell("A1").alignment = centradoH;
  ws.mergeCells("A2:K2");
  ws.getCell("A2").value = nombreReporte;
  ws.getCell("A2").font = fuenteTitulo;
  ws.getCell("A2").alignment = centradoH;
  const rowFechaGeneracion = ws.getRow(3);
  rowFechaGeneracion.values = [
    "Fecha y hora de generación:", moment(new Date()).format("DD/MM/YYYY HH:mm:ss")//Formato a la fecha
  ];
  ws.getCell("A3").font = fuenteTitulo;
  ws.getCell("A3").fill = estiloGris;
  ws.getCell("B3").font = fuenteDatos;

  
  //Titulos de los datos del reporte 
  const row = ws.getRow (4);
  row.values = [
    "Código", "Tipo", "Nombre de la Organización", "Entidad", "Siglas", "RFC", "¿Solicita apoyo económico?",
    "Representante legal", "Teléfono", "Correo", "Fecha de alta"
  ];
  row.font = fuenteTitulo;
  row.eachCell(function (cell){
    cell.fill = estiloGris;
    cell.alignment = {horizontal: "center"};
  });

  //Datos del reporte
  var fila = 5;
  datos.forEach((e) => {
    const rowDatos = ws.getRow(fila);
    rowDatos.font = fuenteDatos;
    rowDatos.values = [
      e.codigo, e.tipoOrganizacion, e.organizacion, e.nombreEstado, e.siglas, e.rfc, e.apoyoEconomico,
      e.nombreRepre, e.telefono, e.correo, moment(e.fechaAlta).format("DD/MM/YYYY HH:mm:ss")
    ];
    fila++
  });
  // Ajuste de las columnas a nivel documento
  ws.properties.defaultColWidth= 26;

  //Creación del excel
  const buf = await wb.xlsx.writeBuffer()
  saveAs(new Blob([buf]), nombreReporte + '.xlsx')
  }

export async function saveExcelSolicitudes(sheetName, nombreReporte, datos) {
  //Creación del workbook
  const wb = new ExcelJS.Workbook()
  wb.creator = 'Sistema de Observadores Electorales';
  wb.lastModifiedBy = 'Sistema de Observadores Electorales';
  wb.created = new Date();
  //Creación de nueva hoja
  const sheet = wb.addWorksheet(sheetName);
  //Datos generales del reporte

  const cabeceras = ["Total", "Hombres", "Mujeres","Nacionales", "Extranjeros",
  "Recibidas en Portal Público", "Recibidas en JL", "Recibidas en JD",
  "Recibidas por OPL", "Solicitudes individuales", "Solicitudes de miembros de organización",
  "Modalidad de curso en línea", "Modalidad de curso presencial", "Curso a distancia",
  "Con curso","Sin Curso","Solicitudes sin revisión","Solicitudes con observaciones",
   "Solicitudes Validadas", "Solicitudes pendientes", "Solicitudes aprobadas", "Solicitudes denegadas",
  "Solicitudes canceladas", "Solicitudes declinadas","Solicitudes registradas en otro sistema",
  "Solicitudes que no completaron los requisitos"]

  sheet.mergeCells("A1:Z1");
  sheet.getCell("A1").value = "Consulta Popular 2021";
  sheet.getCell("A1").font = fuenteTitulo;
  sheet.getCell("A1").alignment = { horizontal: "center"};
  sheet.mergeCells("A2:Z2");
  sheet.getCell("A2").value = nombreReporte;
  sheet.getCell("A2").font = fuenteTitulo;
  sheet.getCell("A2").alignment = { horizontal: "center"};
  var fila;
  if(datos[0].idDistritoFed !== null && datos[0].idDistritoFed !== undefined) { //Reporte a nivel JD
    const rowEstado = sheet.getRow(3);
    rowEstado.values = [
      "Distrito:", datos[0].cabeceraDistrital
    ]
    sheet.getCell("A3").font = fuenteTitulo;
    sheet.getCell("A3").fill = estiloGris;
    sheet.getCell("B3").font = fuenteDatos;
    const rowFechaGeneracion = sheet.getRow(4);
    rowFechaGeneracion.values = [
      "Fecha y horario de generación:", moment(new Date()).format("DD/MM/YYYY HH:mm:ss")//Formato a la fecha
    ]
    sheet.getCell("A4").font = fuenteTitulo;
    sheet.getCell("A4").fill = estiloGris;
    sheet.getCell("B4").font = fuenteDatos;
    //Titulo de los datos del reporte
    const rowTituloDatos = sheet.getRow(5);
    rowTituloDatos.height = 42;
    rowTituloDatos.values = [
      "Junta Ejecutiva", ...cabeceras
    ];
    rowTituloDatos.font = fuenteTitulo;
    rowTituloDatos.eachCell(function(cell) {
      cell.fill = estiloGris;
      cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
    });
    //Datos del reporte
    fila = 6;
    datos.forEach((e) => {
      const rowDatos = sheet.getRow(fila);
      rowDatos.font = fuenteDatos;
      rowDatos.values = [
        e.nombreDistrito, e.total, e.hombres, e.mujeres, e.nacionales, e.extranjeros, e.recPP, e.recJL, 
        e.recJD, e.recOple, e.solIndiv, e.solOrg, e.enLinea, e.presencial, e.distancia, e.conCurso, 
        e.sinCurso, e.sinRevision, e.conObservaciones, e.estVal, e.acrePen, e.acreApr, e.acreDen, 
        e.acreCan, e.acreDec, e.acreObs, e.acreRec,        
      ];
      fila++;
    });
    //Tamaños de las columnas nivel JL
    sheet.getColumn(1).width = 20;
    sheet.getColumn(3).width = 10;
    sheet.getColumn(4).width = 10;
    sheet.getColumn(5).width = 10;
    sheet.getColumn(6).width = 10;
    sheet.getColumn(7).width = 12;
    sheet.getColumn(8).width = 13;
    sheet.getColumn(11).width = 12;
    sheet.getColumn(12).width = 12;
    sheet.getColumn(13).width = 14;
    sheet.getColumn(14).width = 14;
    sheet.getColumn(15).width = 14;
    sheet.getColumn(16).width = 14;
    sheet.getColumn(17).width = 14;
    sheet.getColumn(18).width = 14;
    sheet.getColumn(19).width = 15;
    sheet.getColumn(20).width = 15;
    sheet.getColumn(21).width = 14;
    sheet.getColumn(22).width = 14;
    sheet.getColumn(23).width = 14;
    sheet.getColumn(24).width = 14;
    sheet.getColumn(25).width = 14;
  } else if(datos[0].idDistritoFederal !== null) { //Reporte a nivel JL
    const rowEstado = sheet.getRow(3);
    rowEstado.values = [
      "Estado:", datos[0].nombreEstado
    ]
    sheet.getCell("A3").font = fuenteTitulo;
    sheet.getCell("A3").fill = estiloGris;
    sheet.getCell("B3").font = fuenteDatos;
    const rowFechaGeneracion = sheet.getRow(4);
    rowFechaGeneracion.values = [
      "Fecha y horario de generación:", moment(new Date()).format("DD/MM/YYYY HH:mm:ss")//Formato a la fecha
    ]
    sheet.getCell("A4").font = fuenteTitulo;
    sheet.getCell("A4").fill = estiloGris;
    sheet.getCell("B4").font = fuenteDatos;
    //Titulo de los datos del reporte
    const rowTituloDatos = sheet.getRow(5);
    rowTituloDatos.height = 42;
    rowTituloDatos.values = [
      "Junta Ejecutiva", ...cabeceras
    ];
    rowTituloDatos.font = fuenteTitulo;
    rowTituloDatos.eachCell(function(cell) {
      cell.fill = estiloGris;
      cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
    });
    //Datos del reporte
    fila = 6;
    datos.forEach((e) => {
      const rowDatos = sheet.getRow(fila);
      rowDatos.font = fuenteDatos;
      rowDatos.values = [
        e.nombreDistrito, e.total, e.hombres, e.mujeres, e.nacionales, e.extranjeros,
        e.recPP, e.recJL, e.recJD, e.recOple, e.solIndiv, e.solOrg, e.enLinea, e.presencial,
        e.distancia, e.conCurso, e.sinCurso, e.sinRevision, e.conObservaciones, e.estVal,
        e.acrePen, e.acreApr, e.acreDen, e.acreCan, e.acreDec, e.acreObs, e.acreRec
      ];
      fila++;
    });
    //Tamaños de las columnas nivel JL
    sheet.getColumn(1).width = 20;
    sheet.getColumn(3).width = 10;
    sheet.getColumn(4).width = 10;
    sheet.getColumn(5).width = 10;
    sheet.getColumn(6).width = 10;
    sheet.getColumn(7).width = 12;
    sheet.getColumn(8).width = 13;
    sheet.getColumn(11).width = 12;
    sheet.getColumn(12).width = 12;
    sheet.getColumn(13).width = 14;
    sheet.getColumn(14).width = 14;
    sheet.getColumn(15).width = 14;
    sheet.getColumn(16).width = 14;
    sheet.getColumn(17).width = 14;
    sheet.getColumn(18).width = 14;
    sheet.getColumn(19).width = 15;
    sheet.getColumn(20).width = 15;
    sheet.getColumn(21).width = 14;
    sheet.getColumn(22).width = 14;
    sheet.getColumn(23).width = 14;
    sheet.getColumn(24).width = 14;
    sheet.getColumn(25).width = 14;
  } else { //Reporte a nivel OC
    const rowFechaGeneracion = sheet.getRow(3);
    rowFechaGeneracion.values = [
      "Fecha y horario de generación:", moment(new Date()).format("DD/MM/YYYY HH:mm:ss")//Formato a la fecha
    ]
    sheet.getCell("A3").font = fuenteTitulo;
    sheet.getCell("A3").fill = estiloGris;
    sheet.getCell("B3").font = fuenteDatos;
    //Titulo de los datos del reporte
    const rowTituloDatos = sheet.getRow(4);
    rowTituloDatos.height = 42;
    rowTituloDatos.values = [
      "Estado", ...cabeceras
    ];
    rowTituloDatos.font = fuenteTitulo;
    rowTituloDatos.eachCell(function(cell) {
      cell.fill = estiloGris;
      cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
    });
    //Datos del reporte
    fila = 5;
    datos.forEach((e) => {
      const rowDatos = sheet.getRow(fila);
      rowDatos.font = fuenteDatos;
      rowDatos.values = [
        e.nombreEstado, e.total, e.hombres, e.mujeres, e.nacionales, e.extranjeros,
        e.recPP, e.recJL, e.recJD, e.recOple, e.solIndiv, e.solOrg, e.enLinea, e.presencial,          
        e.distancia, e.conCurso, e.sinCurso, e.sinRevision, e.conObservaciones, e.estVal,
        e.acrePen, e.acreApr, e.acreDen, e.acreCan, e.acreDec, e.acreObs, e.acreRec
      ];
      fila++;
    });
    //Tamaños de las columnas nivel OC
    sheet.getColumn(1).width = 20;
    sheet.getColumn(3).width = 10;
    sheet.getColumn(4).width = 10;
    sheet.getColumn(5).width = 10;
    sheet.getColumn(6).width = 10;
    sheet.getColumn(7).width = 12;
    sheet.getColumn(8).width = 13;
    sheet.getColumn(11).width = 12;
    sheet.getColumn(12).width = 12;
    sheet.getColumn(13).width = 14;
    sheet.getColumn(14).width = 14;
    sheet.getColumn(15).width = 14;
    sheet.getColumn(16).width = 14;
    sheet.getColumn(17).width = 14;
    sheet.getColumn(18).width = 14;
    sheet.getColumn(19).width = 15;
    sheet.getColumn(20).width = 15;
    sheet.getColumn(21).width = 14;
    sheet.getColumn(22).width = 14;
    sheet.getColumn(23).width = 14;
    sheet.getColumn(24).width = 14;
    sheet.getColumn(25).width = 14;
  }

  //Creación del excel
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), nombreReporte + ".xlsx");
}


export async function saveExcelActions(sheetName,nombreReporte, datos) {
  //Creación del workbook
 const wb = new ExcelJS.Workbook()
 wb.creator = 'Sistema de Observadores Electorales';
 wb.lastModifiedBy = 'Sistema de Observadores Electorales';
 wb.created = new Date();

 // Creando nueva hoja con orientacion landscape
 const ws = wb.addWorksheet(sheetName, {
   pageSetup: {
     orientation: 'landscape',
     horizontalCentered: true
   }
 });

 //Encabezado
 ws.mergeCells("A1:AQ1");
 ws.getCell("A1").value = "Consulta Popular 2021";
 ws.getCell("A1").font = fuenteTitulo;
 ws.getCell("A1").alignment = centradoH;
 ws.mergeCells("A2:AQ2");
 ws.getCell("A2").value = nombreReporte;
 ws.getCell("A2").font = fuenteTitulo;
 ws.getCell("A2").alignment = centradoH;
 //Titulos del reporte
 var fila; 
 //Reporte a nivel JD
 if(datos[0].idMedio !== null && datos[0].idMedio !== undefined){
   ws.unMergeCells("A1");
   ws.mergeCells("A1:E1");
   ws.getCell("A1").value = "Consulta Popular 2021";
   ws.unMergeCells("A2");
   ws.mergeCells("A2:E2");
   ws.getCell("A2").value = nombreReporte;

   const rowEstado = ws.getRow(3);
   rowEstado.values = [
     "Estado:", datos[0].nombreEstado
   ]
   const rowDistrito = ws.getRow(4);
   rowDistrito.values = [
     "Distrito Federal:", datos[0].idDistrito !== 0 ? datos[0].idDistrito+". "+datos[0].nombreDistrito : 
     "JUNTA LOCAL"
   ]
   ws.getCell("A3").font = fuenteTitulo;
   ws.getCell("A3").fill = estiloGris;
   ws.getCell("B3").font = fuenteDatos;
   ws.getCell("A4").font = fuenteTitulo;
   ws.getCell("A4").fill = estiloGris;
   ws.getCell("B4").font = fuenteDatos;
   const rowFechaGeneracion = ws.getRow(5);
   rowFechaGeneracion.values = [
     "Fecha y horario de generación:", moment(new Date()).format("DD/MM/YYYY HH:mm:ss")//Formato a la fecha
   ]
   ws.getCell("A5").font = fuenteTitulo;
   ws.getCell("A5").fill = estiloGris;
   ws.getCell("B5").font = fuenteDatos;

   //Titulo datos del reporte
   const rowTituloDatos = ws.getRow(6);
   rowTituloDatos.values = [
     "Medio de Comunicación", "Fecha de realización","Realizada por", "Grupo al que va dirigido la acción", "Descripción"
   ];
   rowTituloDatos.font = fuenteTitulo;
   rowTituloDatos.eachCell(function(cell) {
     cell.fill = estiloGris;
     cell.alignment = { horizontal: "center"};
   });

   //Datos del reporte
   fila = 7;
   datos.forEach((e) =>{
     const rowDatos = ws.getRow(fila);
     rowDatos.font = fuenteDatos;
     rowDatos.values = [
       (e.medio), moment(e.fechaRealizacion).format("DD/MM/YYYY"), e.rol, e.dgrupo, e.descripcion
     ];
     fila++;
   });

 }else if(datos[0].idDistritoopl !== null){
   //Reporte a nivel JL
   const rowEstado = ws.getRow(3);
   rowEstado.values = [
     "Estado: ", datos[0].nombreEstadoopl
   ]
   ws.getCell("A3").font = fuenteTitulo;
   ws.getCell("A3").fill = estiloGris;
   ws.getCell("B3").font = fuenteDatos;
   const rowFechaGeneracion = ws.getRow(4);
   rowFechaGeneracion.values = [
    "Fecha y horario de generación:", moment(new Date()).format("DD/MM/YYYY HH:mm:ss")//Formato a la fecha
  ]
  ws.getCell("A4").font = fuenteTitulo;
  ws.getCell("A4").fill = estiloGris;
  ws.getCell("B4").font = fuenteDatos;

   //Titulos del reporte agrupación
   ws.getCell("A5").value = "Junta Ejecutiva";
   ws.getCell("A5").font = fuenteTitulo;
   ws.getCell("A5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("A5").fill = estiloGris;

   ws.getCell("B5").value = "Total INE";
   ws.getCell("B5").font = fuenteTitulo;
   ws.getCell("B5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("B5").fill = estiloGris;

   ws.getCell("C5").value = "Total OPL";
   ws.getCell("C5").font = fuenteTitulo;
   ws.getCell("C5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("C5").fill = estiloGris;

   //Medios 
   ws.mergeCells("D5:E5");
   ws.getCell("D5").value = "Boletín de prensa";
   ws.getCell("D5").font = fuenteTitulo;
   ws.getCell("D5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("D5").fill = estiloGris;

   ws.mergeCells("F5:G5");
   ws.getCell("F5").value = "Cartel";
   ws.getCell("F5").font = fuenteTitulo;
   ws.getCell("F5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("F5").fill = estiloGris;

   ws.mergeCells("H5:I5");
   ws.getCell("H5").value = "Colocación de mantas";
   ws.getCell("H5").font = fuenteTitulo;
   ws.getCell("H5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("H5").fill = estiloGris;

   ws.mergeCells("J5:K5");
   ws.getCell("J5").value = "Colocación de stand";
   ws.getCell("J5").font = fuenteTitulo;
   ws.getCell("J5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("J5").fill = estiloGris;

   ws.mergeCells("L5:M5");
   ws.getCell("L5").value = "Conferencias";
   ws.getCell("L5").font = fuenteTitulo;
   ws.getCell("L5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("L5").fill = estiloGris;

   ws.mergeCells("N5:O5");
   ws.getCell("N5").value = "Contacto personal";
   ws.getCell("N5").font = fuenteTitulo;
   ws.getCell("N5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("N5").fill = estiloGris;

   ws.mergeCells("P5:Q5");
   ws.getCell("P5").value = "Correo electrónico";
   ws.getCell("P5").font = fuenteTitulo;
   ws.getCell("P5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("P5").fill = estiloGris;

   ws.mergeCells("R5:S5");
   ws.getCell("R5").value = "Internet";
   ws.getCell("R5").font = fuenteTitulo;
   ws.getCell("R5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("R5").fill = estiloGris;


   ws.mergeCells("T5:U5");
   ws.getCell("T5").value = "Invitación telefónica";
   ws.getCell("T5").font = fuenteTitulo;
   ws.getCell("T5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("T5").fill = estiloGris;

   ws.mergeCells("V5:W5");
   ws.getCell("V5").value = "Oficion de invitación";
   ws.getCell("V5").font = fuenteTitulo;
   ws.getCell("V5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("V5").fill = estiloGris;

   ws.mergeCells("X5:Y5");
   ws.getCell("X5").value = "Otro";
   ws.getCell("X5").font = fuenteTitulo;
   ws.getCell("X5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("X5").fill = estiloGris;

   ws.mergeCells("Z5:AA5");
   ws.getCell("Z5").value = "Página del INE";
   ws.getCell("Z5").font = fuenteTitulo;
   ws.getCell("Z5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("Z5").fill = estiloGris;

   ws.mergeCells("AB5:AC5");
   ws.getCell("AB5").value = "Perifoneo";
   ws.getCell("AB5").font = fuenteTitulo;
   ws.getCell("AB5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AB5").fill = estiloGris;

   ws.mergeCells("AD5:AE5");
   ws.getCell("AD5").value = "Pláticas de sensibilización";
   ws.getCell("AD5").font = fuenteTitulo;
   ws.getCell("AD5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AD5").fill = estiloGris;

   ws.mergeCells("AF5:AG5");
   ws.getCell("AF5").value = "Publicación de convocatoria";
   ws.getCell("AF5").font = fuenteTitulo;
   ws.getCell("AF5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AF5").fill = estiloGris;

   ws.mergeCells("AH5:AI5");
   ws.getCell("AH5").value = "Radio Local";
   ws.getCell("AH5").font = fuenteTitulo;
   ws.getCell("AH5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AH5").fill = estiloGris;

   ws.mergeCells("AJ5:AK5");
   ws.getCell("AJ5").value = "Redes sociales";
   ws.getCell("AJ5").font = fuenteTitulo;
   ws.getCell("AJ5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AJ5").fill = estiloGris;


   ws.mergeCells("AL5:AM5");
   ws.getCell("AL5").value = "Telegrama ciudadano";
   ws.getCell("AL5").font = fuenteTitulo;
   ws.getCell("AL5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AL5").fill = estiloGris;

   ws.mergeCells("AN5:AO5");
   ws.getCell("AN5").value = "Televisión";
   ws.getCell("AN5").font = fuenteTitulo;
   ws.getCell("AN5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AN5").fill = estiloGris;

   ws.mergeCells("AP5:AQ5");
   ws.getCell("AP5").value = "Volanteo";
   ws.getCell("AP5").font = fuenteTitulo;
   ws.getCell("AP5").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AP5").fill = estiloGris;

   //Titulos del reporte específico
   const rowTituloDatos = ws.getRow(6);
   rowTituloDatos.height = 42;
   rowTituloDatos.values = [
     "", "", "", "INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL",
     "INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL"
   ];
   rowTituloDatos.font = fuenteTitulo;
   rowTituloDatos.eachCell(function(cell){
     cell.fill = estiloGris;
     cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   });

    //Datos del reporte
    fila = 7;
    datos.forEach((e) => {
      const rowDatos = ws.getRow(fila);
      rowDatos.font= fuenteDatos;
      rowDatos.values = [
        e.idDistritoopl + ". " + e.nombreDistritoopl , e.totaline, e.totalopl, e.bPrensaine, e.bPrensaopl, e.carteline, e.cartelopl,e.cMantaine,e.cMantaopl,e.cStandine,e.cStandopl,e.conferenciasine,e.conferenciasopl,e.cPersonaline,e.cPersonalopl,e.correoine,e.correoopl,
        e.internetine,e.internetopl,e.invTelefonicaine,e.invTelefonicaopl,e.ofiInvitacionine,e.ofiInvitacionopl,e.otroine,e.otroopl,e.pIneine,e.pIneopl,e.perifoneoine,e.perifoneoopl,e.pSensibilizacionine, e.pSensibilizacionopl,e.pConvocatoriaine,e.pConvocatoriaopl,
        e.rLocaline,e.rLocalopl,e.redSocialine,e.redSocialopl,e.tCiudadanoine,e.tCiudadanoopl,e.televisionine,e.televisionopl,e.volanteoine,e.volanteoopl
      ];
      fila++;
    });

 }else{
   //Reporte a nivel OC
   const rowFechaGeneracion = ws.getRow(3);
   rowFechaGeneracion.values = [
    "Fecha y horario de generación:", moment(new Date()).format("DD/MM/YYYY HH:mm:ss")//Formato a la fecha
   ];
   ws.getCell("A3").font = fuenteTitulo;
   ws.getCell("A3").fill = estiloGris;
   ws.getCell("B3").font = fuenteDatos;

   //Titulos del reporte agrupación
   ws.getCell("A4").value = "Entidad";
   ws.getCell("A4").font = fuenteTitulo;
   ws.getCell("A4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("A4").fill = estiloGris;

   ws.getCell("B4").value = "Total INE";
   ws.getCell("B4").font = fuenteTitulo;
   ws.getCell("B4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("B4").fill = estiloGris;

   ws.getCell("C4").value = "Total OPL";
   ws.getCell("C4").font = fuenteTitulo;
   ws.getCell("C4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("C4").fill = estiloGris;

   //Medios 
   ws.mergeCells("D4:E4");
   ws.getCell("D4").value = "Boletín de prensa";
   ws.getCell("D4").font = fuenteTitulo;
   ws.getCell("D4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("D4").fill = estiloGris;

   ws.mergeCells("F4:G4");
   ws.getCell("F4").value = "Cartel";
   ws.getCell("F4").font = fuenteTitulo;
   ws.getCell("F4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("F4").fill = estiloGris;

   ws.mergeCells("H4:I4");
   ws.getCell("H4").value = "Colocación de mantas";
   ws.getCell("H4").font = fuenteTitulo;
   ws.getCell("H4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("H4").fill = estiloGris;

   ws.mergeCells("J4:K4");
   ws.getCell("J4").value = "Colocación de stand";
   ws.getCell("J4").font = fuenteTitulo;
   ws.getCell("J4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("J4").fill = estiloGris;

   ws.mergeCells("L4:M4");
   ws.getCell("L4").value = "Conferencias";
   ws.getCell("L4").font = fuenteTitulo;
   ws.getCell("L4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("L4").fill = estiloGris;

   ws.mergeCells("N4:O4");
   ws.getCell("N4").value = "Contacto personal";
   ws.getCell("N4").font = fuenteTitulo;
   ws.getCell("N4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("N4").fill = estiloGris;

   ws.mergeCells("P4:Q4");
   ws.getCell("P4").value = "Correo electrónico";
   ws.getCell("P4").font = fuenteTitulo;
   ws.getCell("P4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("P4").fill = estiloGris;

   ws.mergeCells("R4:S4");
   ws.getCell("R4").value = "Internet";
   ws.getCell("R4").font = fuenteTitulo;
   ws.getCell("R4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("R4").fill = estiloGris;


   ws.mergeCells("T4:U4");
   ws.getCell("T4").value = "Invitación telefónica";
   ws.getCell("T4").font = fuenteTitulo;
   ws.getCell("T4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("T4").fill = estiloGris;

   ws.mergeCells("V4:W4");
   ws.getCell("V4").value = "Oficion de invitación";
   ws.getCell("V4").font = fuenteTitulo;
   ws.getCell("V4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("V4").fill = estiloGris;

   ws.mergeCells("X4:Y4");
   ws.getCell("X4").value = "Otro";
   ws.getCell("X4").font = fuenteTitulo;
   ws.getCell("X4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("X4").fill = estiloGris;

   ws.mergeCells("Z4:AA4");
   ws.getCell("Z4").value = "Página del INE";
   ws.getCell("Z4").font = fuenteTitulo;
   ws.getCell("Z4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("Z4").fill = estiloGris;

   ws.mergeCells("AB4:AC4");
   ws.getCell("AB4").value = "Perifoneo";
   ws.getCell("AB4").font = fuenteTitulo;
   ws.getCell("AB4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AB4").fill = estiloGris;

   ws.mergeCells("AD4:AE4");
   ws.getCell("AD4").value = "Pláticas de sensibilización";
   ws.getCell("AD4").font = fuenteTitulo;
   ws.getCell("AD4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AD4").fill = estiloGris;

   ws.mergeCells("AF4:AG4");
   ws.getCell("AF4").value = "Publicación de convocatoria";
   ws.getCell("AF4").font = fuenteTitulo;
   ws.getCell("AF4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AF4").fill = estiloGris;

   ws.mergeCells("AH4:AI4");
   ws.getCell("AH4").value = "Radio Local";
   ws.getCell("AH4").font = fuenteTitulo;
   ws.getCell("AH4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AH4").fill = estiloGris;

   ws.mergeCells("AJ4:AK4");
   ws.getCell("AJ4").value = "Redes sociales";
   ws.getCell("AJ4").font = fuenteTitulo;
   ws.getCell("AJ4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AJ4").fill = estiloGris;


   ws.mergeCells("AL4:AM4");
   ws.getCell("AL4").value = "Telegrama ciudadano";
   ws.getCell("AL4").font = fuenteTitulo;
   ws.getCell("AL4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AL4").fill = estiloGris;

   ws.mergeCells("AN4:AO4");
   ws.getCell("AN4").value = "Televisión";
   ws.getCell("AN4").font = fuenteTitulo;
   ws.getCell("AN4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AN4").fill = estiloGris;

   ws.mergeCells("AP4:AQ4");
   ws.getCell("AP4").value = "Volanteo";
   ws.getCell("AP4").font = fuenteTitulo;
   ws.getCell("AP4").alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   ws.getCell("AP4").fill = estiloGris;

   //Titulos del reporte específico
   const rowTituloDatos = ws.getRow(5);
   rowTituloDatos.height = 42;
   rowTituloDatos.values = [
     "", "", "", "INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL",
     "INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL","INE", "OPL"
   ];
   rowTituloDatos.font = fuenteTitulo;
   rowTituloDatos.eachCell(function(cell){
     cell.fill = estiloGris;
     cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
   });
   //Datos del reporte
   fila = 6;
   datos.forEach((e) => {
     const rowDatos = ws.getRow(fila);
     rowDatos.font= fuenteDatos;
     rowDatos.values = [
       e.nombreEstadoopl , e.totaline, e.totalopl, e.bPrensaine, e.bPrensaopl, e.carteline, e.cartelopl,e.cMantaine,e.cMantaopl,e.cStandine,e.cStandopl,e.conferenciasine,e.conferenciasopl,e.cPersonaline,e.cPersonalopl,e.correoine,e.correoopl,
       e.internetine,e.internetopl,e.invTelefonicaine,e.invTelefonicaopl,e.ofiInvitacionine,e.ofiInvitacionopl,e.otroine,e.otroopl,e.pIneine,e.pIneopl,e.perifoneoine,e.perifoneoopl,e.pSensibilizacionine, e.pSensibilizacionopl,e.pConvocatoriaine,e.pConvocatoriaopl,
       e.rLocaline,e.rLocalopl,e.redSocialine,e.redSocialopl,e.tCiudadanoine,e.tCiudadanoopl,e.televisionine,e.televisionopl,e.volanteoine,e.volanteoopl
     ];
     fila++;
   });

 }

   // Ajuste de las columnas a nivel documento
   if(datos[0].idMedio !== null && datos[0].idMedio !== undefined){
    ws.getColumn(1).width = 23;
    ws.getColumn(2).width = 19;
    ws.getColumn(3).width = 50;
    ws.getColumn(4).width = 32;
	  ws.getColumn(5).width = 40;
   } else {
    ws.properties.defaultColWidth= 20;
   }

 //Creación del excel
 const buf = await wb.xlsx.writeBuffer()
 saveAs(new Blob([buf]), nombreReporte + '.xlsx')
 }

 export async function saveExcelDetalleSolicitudes(sheetName, nombreReporte, datos) {
  //Creación del workbook
  const wb = new ExcelJS.Workbook()
  wb.creator = 'Sistema de Observadores Electorales ';
  wb.lastModifiedBy = 'Sistema de Observadores Electorales';
  wb.created = new Date();
  //Creación de nueva hoja
  const sheet = wb.addWorksheet(sheetName);

  //Datos generales del reporte
  sheet.mergeCells("A1:U1");
  sheet.getCell("A1").value = "Consulta Popular 2021";
  sheet.getCell("A1").font = fuenteTitulo;
  sheet.getCell("A1").alignment = { horizontal: "center"};
  sheet.mergeCells("A2:U2");
  sheet.getCell("A2").value = nombreReporte;
  sheet.getCell("A2").font = fuenteTitulo;
  sheet.getCell("A2").alignment = { horizontal: "center"};
  var fila;

 // if(idEstado !==undefined && idEstado === 0){
    const rowFechaGeneracion = sheet.getRow(3);
    rowFechaGeneracion.values = [
      "Fecha y horario de generación:", moment(new Date()).format("DD/MM/YYYY HH:mm:ss")//Formato a la fecha
    ]
    sheet.getCell("A3").font = fuenteTitulo;
    sheet.getCell("A3").fill = estiloGris;
    sheet.getCell("B3").font = fuenteDatos;
    //Titulo de los datos del reporte
    const rowTituloDatos = sheet.getRow(5);
    rowTituloDatos.height = 42;
    rowTituloDatos.values = [
      "Estado", "Distrito", "Nombre del solicitante", "Clave de elector", "Folio",
      "Tipo de Solicitud", "Agrupación", "Estatus general de la solicitud", "Estado de la revisión", "Edad",
      "Sexo","Procedencia de la solicitud","Entidad del domicilio", "Distrito del domicilio", "Fecha de la solicitud",
      "Origen de la solicitud", "Modalidad del Curso", "Curso a distancia", "Fecha en que completó el curso", 
      "Fecha de aprobación de la acreditación", "Número de sesión", "Fecha de la sesión", "Número de acuerdo", "Teléfono", "Celular", "Correo electrónico",
      "Ratificación"
    ];
    rowTituloDatos.font = fuenteTitulo;
    rowTituloDatos.eachCell(function(cell) {
      cell.fill = estiloGris;
      cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
    });
    //Datos del reporte
    fila = 6;
    datos.forEach((e) => {
      const rowDatos = sheet.getRow(fila);
      rowDatos.font = fuenteDatos;
      rowDatos.values = [
        e.nombreEstado, e.distrito, e.nombreSol, e.claveElec, e.folio, e.tipoSol, e.agrupacion, e.estatusGeneral, e.estatusSol, e.edad,
        e.sexo, e.nacionalidad, e.entidadDomicilio, e.distritoDomicilio, e.fechaSol, e.origenSol, e.tipoCurso, e.distancia, 
        e.fechaFinCurso, e.fechaHoraAcreditacion, e.numSesion, e.fechaSesion, e.numAcuerdo, e.telefono, e.celular, e.correo,
        e.ratificado
      ];
      fila++;
    });
    //Tamaños de las columnas nivel OC
    sheet.getColumn(1).width = 20;
    sheet.getColumn(3).width = 10;
    sheet.getColumn(4).width = 10;
    sheet.getColumn(5).width = 10;
    sheet.getColumn(6).width = 10;
    sheet.getColumn(7).width = 12;
    sheet.getColumn(8).width = 13;
    sheet.getColumn(11).width = 12;
    sheet.getColumn(12).width = 12;
    sheet.getColumn(13).width = 14;
    sheet.getColumn(14).width = 14;
    sheet.getColumn(15).width = 14;
    sheet.getColumn(16).width = 14;
    sheet.getColumn(17).width = 14;
    sheet.getColumn(18).width = 14;
    sheet.getColumn(19).width = 14;
    sheet.getColumn(20).width = 14;
    sheet.getColumn(21).width = 14;
    sheet.getColumn(22).width = 15;
    sheet.getColumn(23).width = 15;
    sheet.getColumn(24).width = 15;
    sheet.getColumn(25).width = 15;
    sheet.getColumn(26).width = 35;
    sheet.getColumn(27).width = 12;

  //Creación del excel
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), nombreReporte + ".xlsx");
}