import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, Row, Icon } from "antd";
import { Back } from "../../../../components/User/CourseOnline/Generals";
import "../CourseOnline/Courses.scss";
// import {aspectosGralesPPT} from "../../../../../public/materiales_cp/L1.pptx"

/**
 * Leccion 2
 * path: /ciudadano/curso-leccion1
 */
function Lesson1(props) {
  const row = 18;

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
  }, []);

  function download() {
    let a = document.createElement("a"); //Create <a>
    a.href = `data:application/vnd.ms-powerpoint, "./materiales_cp/L1.pptx"`; //Image Base64 Goes here
    a.download = `1. Aspectos generales de la Consulta Popular.pptx`; //File name Here
    a.click(); //Downloaded file
  }

  return (
    <>
      <Back path="/ciudadano/curso-consulta-popular" />
      <Row gutter={24} className="material">
        <Col span={row} xxl={row} xl={row} lg={row} md={row} sm={24} xs={24}>
            <br />
            <h1>Lección 1. Aspectos generales de la Consulta Popular</h1>
            <hr></hr>
            <div className="texto_parrafo">
                <p>
                En esta primera lección identificarás qué es la Consulta Popular, quiénes y cómo pueden solicitarla, los procedimientos para convocar por cada una de las figuras, así como las características específicas de la Consulta Popular 2021.
                </p>
                <p>
                Además, te proporcionaremos información sobre tus derechos y obligaciones y la importancia que representa tu participación y testimonio como observadora u observador en este mecanismo de participación ciudadana.
                </p>
                <p>
                    Comencemos con tu capacitación, para lo cual te invitamos a revisar el siguiente material:
                </p>
                <div className="matTitle">Material de apoyo</div>
                <dl className="matApoyo">Presentación: 1. Aspectos Generales de la Consulta Popular</dl>
                <button
                    className="btnDescargar material"
                    style={{ marginLeft: "0px" }}
                    onClick={(e) => download()}
                >
                    <Icon type="download" />
                    Descargar material
                </button>
            </div>
            <Link to={{ pathname: "/ciudadano/curso-leccion2" }}>
                <Button
                type="primary"
                style={{ marginBottom: 30 }}
                >
                Ir a la lección 2
                </Button>
            </Link>
        </Col>
      </Row>
    </>
  );
}

export default withRouter(Lesson1);
