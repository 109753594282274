import React, { useState, useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, Form, Select, Row, Col } from "antd";
import { Input, Radio, Alert, Modal, message } from "antd";
import { Upload, Button } from "antd";
import { required, soloTexto, RFC, tel } from "../../../utils/rules";
import { apellidos, soloDigitos } from "../../../utils/rules";
import { correo, nombres } from "../../../utils/rules";
import {
  ModalConfirm,
  beforeUpload,
  valueUpperCase,
} from "../../../utils/Organitation/function";
import { validaRepre, Ast, getUrl } from "../../../utils/Organitation/function";
import useAuth from "../../../hooks/useAuth";
import {
  getTypeOrganitation,
  getEntidad,
  getDownload,
} from "../../../api/organitation";
import { rol_captura, rol_modifica } from "../../../utils/Organitation/roles";
import { txtNombre, txtOrg } from "../../../utils/regex";
import "./style.scss";
import { saveAs } from "file-saver";
import { URL_ORGANIZACION } from "../../../api/config";
import { USUARIO_CAU, MSN_GUARDAR } from "../../../utils/constanst";

const { Option } = Select;

function FormOrganitation(props) {
  const {
    user: { user, rolUsuario },
  } = useAuth();
  const {
    history,
    setLoader,
    proceso: { proceso },
  } = props; //Para redirreccionar a otra ruta con withRouter (history)
  const { getFieldDecorator } = props.form; //Método para validar los inputs
  const [document, setDocument] = useState([
    { file: [], nameFile: "", url: "" },
  ]); //Se almacena el archivo
  const [select, setSelect] = useState([]);
  const [selectEstado, setSelectEstado] = useState([]);
  const { data, status } = props.location;
  const [disabled, setDisabled] = useState({
    input: false,
    titulo: false,
    buttonUpd: false,
    title: false,
  }); //Para habilidar los inputs
  const [rolModulo, setRolModulo] = useState({
    modifica: false,
  });
  const [btnGuardar, setBtnGuardar] = useState(false);
  const isMounted = useRef(true);
  const [btnFile, setBtnFile] = useState(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  //Se llena el combo de tipos de organizaciones y el combo de Estados
  useEffect(() => {
    //Valida los permisos que tienen el usuario
    let captura = false;
    let modifica = false;
    captura = rol_captura.includes(rolUsuario);
    modifica = rol_modifica.includes(rolUsuario);

    if (history.location.pathname === "/admin/organizacion/registro") {
      if (!captura) {
        history.push("/admin/organizacion");
      }
    } else {
      if (history.location.pathname === "/admin/organizacion/modificar") {
        if (data === undefined) {
          history.push("/admin/organizacion");
        } else {
          setRolModulo(() => ({ modifica }));
          getOrganitation(); //Verifica si es nueva organización o es actualizar
        }
      }
    }
    getTypeOrganitation()
      .then((res) => {
        if (isMounted.current) {
          setSelect(res.data);
        }
      })
      .finally(() => {
        getEntidad().then((response) => {
          if (isMounted.current) {
            setSelectEstado(response.data);
          }
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Llena el formulario si recibe una organización
  const getOrganitation = () => {
    if (status !== undefined) {
      status
        ? setDisabled({
            input: true,
            titulo: true,
            buttonUpd: true,
            title: true,
          })
        : setDisabled({ title: true });
      if (!data.urlSolicitud) {
        setDocument([{ nameFile: "" }]);
        setBtnFile(true);
      } else {
        let nameFile = getUrl(data.urlSolicitud, URL_ORGANIZACION);
        setDocument([{ nameFile: nameFile, url: data.urlSolicitud }]);
        setBtnFile(false);
      }
      props.form.setFieldsValue({
        //Se llenan los input del formulario
        idOrganizacion: data.idOrganizacion,
        tipo: data.cTiposOrganizacion.idTipoOrganizacion,
        idEstado: data.idEstado,
        organizacion: data.organizacion,
        siglas: data.siglasOrg,
        reprePaterno: data.apellidoPaternoRepre,
        repreMaterno: data.apellidoMaternoRepre,
        repreNombre: data.nombreRepre,
        rfc: data.rfcOrg,
        apoyo: data.banderaApoyo,
        telefono: data.telefono != null ? data.telefono.toString() : "",
        correo: data.correoElectronico,
      });
    }
  };
  //Validaciones para cargar el archivo
  const file = {
    name: "file",
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: (file) => {
      setDocument((state) => {
        return {
          file: [],
        };
      });
    },

    beforeUpload: (file) => {
      if (beforeUpload(file)) {
        setDocument((state) => ({
          file: [file],
        }));
      }
      return false;
    },
    document,
  };
  //Función que guarda la organización
  const addOrganitation = (event) => {
    event.preventDefault();
    if (proceso !== undefined && proceso !== null) {
      //Se valida el proceso
      props.form.validateFields((err, values) => {
        let repre = validaRepre(values.reprePaterno, values.repreMaterno);
        if (!err) {
          if (repre) {
            values.idProcesoElectoral = proceso.idProcesoElectoral;
            values.idDetalleProceso = proceso.idDetalleProceso;
            values.usuario = user.sub;
            setBtnGuardar(true);
            // if (!btnFile) {
            //   console.log(" NO env file");
            //   ModalConfirm(
            //     values,
            //     null,
            //     history,
            //     setBtnGuardar,
            //     setLoader,
            //     false
            //   );
            // } else {
            //console.log("env file");
            ModalConfirm(
              values,
              document,
              history,
              setBtnGuardar,
              setLoader,
              true
            );
            // }
          } else {
            Modal.warning({
              title: "Atención",
              content: "Al menos uno de los dos apellidos es obligatorio.",
            });
          }
        }
      });
    } else {
      Modal.warning({
        title: "Atención",
        content: "Selecciona la consulta popular",
      });
    }
  };

  const AlertLastName = () => {
    return (
      <Alert
        message="Al menos uno de los dos apellidos es obligatorio."
        type="info"
        showIcon
        style={disabled.input ? { display: "none" } : {}}
      />
    );
  };

  //Función que descarga un documento
  const download = (url, name) => {
    setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
    const json = {
      url: url,
      nombre: name,
    };
    getDownload(json)
      .then((res) => {
        if (res.data) {
          saveAs(new Blob([res.data]), name);
        }
        setLoader({ loading: false }); //Se activa el loader
      })
      .catch((error) => {
        setLoader({ loading: false }); //Se activa el loader
        message.error("El archivo no se encuentra disponible");
      });
  };

  return (
    <div style={{ marginLeft: "20px" }}>
      <Link to={"/admin/organizacion"} className="back">
        <Icon type="play-circle" theme="filled" rotate="180" /> Volver a
        "Registro de organizaciones"
      </Link>
      <div style={{ marginTop: "30px" }}>
        {disabled.titulo ? (
          <h1>Ver organización</h1>
        ) : (
          <>
            <h1>
              {disabled.title ? "Editar organización" : "Nueva organización"}
            </h1>
            <p>
              Los datos con ( <Ast /> ) son requeridos
            </p>
          </>
        )}
      </div>
      <h4>Datos de la organización</h4>
      <Form>
        <Form.Item>
          {getFieldDecorator("idOrganizacion")(<Input disabled hidden />)}
        </Form.Item>
        <Form.Item>
          <div>{!disabled.input && <Ast />} Tipo de organización</div>
          {getFieldDecorator("tipo", { rules: required })(
            <Select
              placeholder="Selecciona"
              style={{ width: 315 }}
              disabled={disabled.input}
            >
              {select.map((item) => (
                <Option
                  key={item.idTipoOrganizacion}
                  value={item.idTipoOrganizacion}
                >
                  {item.tipoOrganizacion}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item>
              <div>{!disabled.input && <Ast />} Nombre de la organización</div>
              {getFieldDecorator("organizacion", {
                rules: soloTexto,
                getValueFromEvent: (event) => {
                  return txtOrg.test(event.target.value)
                    ? event.target.value.replace("-", "")
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  placeholder="Nombre completo sin abreviaturas"
                  maxLength={100}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Siglas">
              {getFieldDecorator("siglas", { rules: apellidos })(
                <Input
                  placeholder="Siglas"
                  maxLength={50}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <div>RFC de la organización</div>
          {getFieldDecorator("rfc", {
            rules: RFC,
            getValueFromEvent: valueUpperCase,
          })(
            <Input
              placeholder="Registro Federal de Contribuyentes"
              style={{ width: 315 }}
              maxLength={13}
              disabled={disabled.input}
            />
          )}
        </Form.Item>
        <Form.Item>
          <div>{!disabled.input && <Ast />} Estado de la Organización </div>
          {getFieldDecorator("idEstado", { rules: required })(
            <Select
              placeholder="Selecciona"
              style={{ width: 315 }}
              disabled={disabled.input}
            >
              {selectEstado.map((item) => (
                <Option key={item.id.idEstado} value={item.id.idEstado}>
                  {item.nombreEstado}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <p>{!disabled.input && <Ast />} Representante legal</p>
        <AlertLastName />
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item>
              <div>Apellido paterno</div>
              {getFieldDecorator("reprePaterno", {
                rules: apellidos,
                getValueFromEvent: (event) => {
                  return txtNombre.test(event.target.value)
                    ? event.target.value.replace("-", "")
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  placeholder="Apellido paterno"
                  maxLength={40}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <div>Apellido materno</div>
              {getFieldDecorator("repreMaterno", {
                rules: apellidos,
                getValueFromEvent: (event) => {
                  return txtNombre.test(event.target.value)
                    ? event.target.value.replace("-", "")
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  placeholder="Apellido materno"
                  maxLength={40}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <div>{!disabled.input && <Ast />} Nombre(s)</div>
              {getFieldDecorator("repreNombre", {
                rules: nombres,
                getValueFromEvent: (event, previo) => {
                  return txtNombre.test(event.target.value)
                    ? event.target.value.replace("-", "")
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  placeholder="Nombre(s)"
                  maxLength={50}
                  type="text"
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div>
            {!disabled.input} ¿La organización solicita apoyo
            económico?
          </div>
          {getFieldDecorator("apoyo",)(
            <Radio.Group disabled={disabled.input}>
              <Radio value={1}>Sí</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <p>{!disabled.input} Soporte documental que sustente la solicitud</p>
        <Alert
          message="Algún documento que avale la legitimidad del trámite o de la organización que solicita el registro. Formato pdf que no exceda los 6MB."
          type="info"
          showIcon
          style={disabled.input ? { display: "none" } : {}}
        />

        {btnFile ? (
          <Form.Item style={{ marginTop: 20 }}>
            <Upload
              {...file}
              accept=".pdf,.PDF"
              fileList={document.file}
              disabled={disabled.input}
            >
              <Button type="default" disabled={disabled.input}>
                <Icon type="upload" />
                Cargar
              </Button>
            </Upload>
          </Form.Item>
        ) : (
          <div style={{ marginTop: 20 }}>
            <button
              className="btnDownload"
              onClick={(e) =>
                download(document[0].url, document[0].nameFile, e)
              }
            >
              <Icon type="paper-clip" />
              {document[0].nameFile}
            </button>
          </div>
        )}

        <h5 style={{ marginTop: 20 }}>Datos de contacto</h5>
        <Row gutter={20}>
          <Col span={4}>
            <Form.Item>
              <div>{!disabled.input} Teléfono</div>
              {getFieldDecorator("telefono", {
                rules: tel,
                getValueFromEvent: (event, previo) => {
                  return soloDigitos.test(event.target.value)
                    ? event.target.value
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  placeholder="10 dígitos"
                  maxLength={10}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <div>{!disabled.input} Correo electrónico</div>
              {getFieldDecorator("correo", { rules: correo })(
                <Input
                  placeholder="correo@dominio.com"
                  maxLength={100}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "center" }}>
          {disabled.buttonUpd ? (
            rolModulo.modifica && (
              <Button
                type="primary"
                onClick={() => {
                  setDisabled({
                    input: false,
                    title: true,
                  });
                }}
              >
                Modificar
              </Button>
            )
          ) : (
            <>
              {rolUsuario === USUARIO_CAU ? (
                <>
                  <Button type="default">
                    <Link to={"/admin/organizacion"}>Cancelar</Link>
                  </Button>
                </>
              ) : (
                <>
                  <Button type="default" disabled={btnGuardar}>
                    <Link to={"/admin/organizacion"}>Cancelar</Link>
                  </Button>{" "}
                  <Button
                    type="primary"
                    onClick={(e) => {
                      addOrganitation(e);
                    }}
                    disabled={btnGuardar}
                  >
                    Guardar
                  </Button>
                </>
              )}
            </>
          )}
        </Form.Item>
      </Form>
    </div>
  );
}
export default withRouter(Form.create()(FormOrganitation));
