import React from "react";
import { Layout } from "antd";
import "./Header.scss";
import { Link } from "react-router-dom";

const HeaderPublic = (props) => {
  const { Logo, ciudadano } = props;
  const { Header } = Layout;

  return (
    <Header className="headerPublic">
      <div id="logoINE_public">
        <Link
          to={ciudadano ? "/ciudadano/inicio" : "/"}
          className="back"
          title="Inicio"
        >
          <Logo />
        </Link>
      </div>
      <div>
        <h1>
          Portal de Observadoras y Observadores de la Consulta Popular 
        </h1>
      </div>
    </Header>
  );
};

export default HeaderPublic;
