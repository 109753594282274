import React, { useEffect } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { Col, Row, Icon } from "antd";
// import { Back } from "../../../../components/User/CourseOnline/Generals";
import {saveAs} from  "file-saver"
// import "../CourseOnline/Courses.scss";

/**
 * Pantalla 2
 * Componentes que muestra la pantalla de introducción al inicar el curso
 * path: /ciudadano/introduccion
 */
function MaterialesCP(props) {
  const row = 18;

  const descargaGuia = () => {
    saveAs(process.env.PUBLIC_URL+"/materiales_cp/GOCP2021.pdf", "Guía observadores de la Consulta Popular");
  }

  const descargaConstitucion = () => {
    saveAs(process.env.PUBLIC_URL+"/materiales_cp/Constitucion.pdf", "Constitución Política de los Estados Unidos Mexicanos");
  }

  const descargaLGIPE = () => {
    saveAs(process.env.PUBLIC_URL+"/materiales_cp/LGIPE.pdf", "Ley General de Instituciones y Procedimientos Electorales");
  }

  const descargaLeyConsulta = () => {
    saveAs(process.env.PUBLIC_URL+"/materiales_cp/LeyConsulta.pdf", "Ley Federal de Consulta Popular");
  }

  const descargaAdenda = () => {
    saveAs(process.env.PUBLIC_URL+"/materiales_cp/adenda.pdf", "Adenda a los Lineamientos INE Consulta Popular");
  }

  const descargaPPT1 = () => {
    saveAs(process.env.PUBLIC_URL+"/materiales_cp/aspectosGrales.pptx", "Aspectos Generales de la Consulta Popular");
  }

  const descargaPPT2 = () => {
    saveAs(process.env.PUBLIC_URL+"/materiales_cp/desarolloConsulta.pptx", "Desarrollo de la Consulta Popular");
  }

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
  }, []);

  return (
    <>
      {/* <Back onClick={history.goBack} /> */}
    <Link onClick={history.goBack} className="back">
        <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
    </Link>
      <Row gutter={24} className="material">
        <Col span={row} xxl={row} xl={row} lg={row} md={row} sm={24} xs={24}>
          <div className="texto_parrafo">
            <br />
            <h1>Materiales de capacitación</h1>
            <hr></hr>

            <h2>Materiales de consulta</h2>
            
            <ul>
                <li>Guía temática para capacitar a las y los observadores de la Consulta Popular</li>
                <button
                    className="btnDescargar material"
                    style={{ marginLeft: "0px" }}
                    onClick={(e) => descargaGuia()}
                >
                    <Icon type="download" />
                    Descargar material
                </button>

                <li>Constitución Política de los Estados Unidos Mexicanos</li>
                <button
                    className="btnDescargar material"
                    style={{ marginLeft: "0px" }}
                    onClick={(e) => descargaConstitucion()}
                >
                    <Icon type="download" />
                    Descargar material
                </button>

                <li>Ley General de Instituciones y Procedimientos Electorales</li>
                <button
                    className="btnDescargar material"
                    style={{ marginLeft: "0px" }}
                    onClick={(e) => descargaLGIPE()}
                >
                    <Icon type="download" />
                    Descargar material
                </button>

                <li>Ley Federal de Consulta Popular</li>
                <button
                    className="btnDescargar material"
                    style={{ marginLeft: "0px" }}
                    onClick={(e) => descargaLeyConsulta()}
                >
                    <Icon type="download" />
                    Descargar material
                </button>

                <li>Adenda a los Lineamientos del Instituto Nacional Electoral para la organización de la Consulta Popular del 1° de agosto de 2021</li>
                <button
                    className="btnDescargar material"
                    style={{ marginLeft: "0px" }}
                    onClick={(e) => descargaAdenda()}
                >
                    <Icon type="download" />
                    Descargar material
                </button>
            </ul>

            <h2>Materiales de las lecciones</h2>

            <ul>
                <li>Presentación: 1. Aspectos Generales de la Consulta Popular</li>
                <button
                    className="btnDescargar material"
                    style={{ marginLeft: "0px" }}
                    onClick={(e) => descargaPPT1()}
                >
                    <Icon type="download" />
                    Descargar material
                </button>

                <li>Presentación: 2. Desarrollo de la Consulta Popular</li>
                <button
                    className="btnDescargar material"
                    style={{ marginLeft: "0px" }}
                    onClick={(e) => descargaPPT2()}
                >
                    <Icon type="download" />
                    Descargar material
                </button>

                <li>Vídeo: Conoce en que consiste la Consulta Popular del próximo 1 de agosto</li>
                <dl className="matApoyo"><a rel="noopener noreferrer" style={{textDecorationLine: "underline"}} target="_blank" href={"https://youtu.be/V1TuNfO12c0"}>Vídeo: La Consulta Popular 2021</a></dl>
            </ul>
            
          </div>
        </Col>
      </Row>
    </>
  );
}

export default withRouter(MaterialesCP);